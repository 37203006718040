(function (){
    "use strict";

    /**
     * Toolbar Controller
     */
    angular.module('archie.core').controller('ToolbarController', ToolbarController);
    /**
     * ToolbarController - Toolbar Controller
     */
    /* @ngInject */
    function ToolbarController($scope, $rootScope, $log, $location, AuthService, UserService, FeedbackService){
        $rootScope.title = appConfig.title;
        $scope.subTitle = appConfig.subTitle;
        $scope.feedback = FeedbackService.sendFeedback();
        $scope.reportBug = FeedbackService.reportBug();
        $scope.progressIndicator = true;
        $scope.creds = UserService.profile;
        $scope.showCreds = !AuthService.isDisabled();

        // listen for progress events
        $rootScope.$on('progressIndicator', function (event, data){
            $scope.progressIndicator = data;
        });

        $scope.actions = [{
            title: 'Clips',
            destination: 'video-dialogue',
            fontIcon: 'video_library'
        },{
            title: 'Library',
            destination: 'library',
            fontIcon: 'library_books'
        }];

        // NOTE: don't delete, for use when there are more nav items
        // angular.forEach(actions, function (action){
        //     if ($route.current.$$route.originalPath.indexOf(action.destination) === 0) {
        //         $scope.selectedIndex = action.index;
        //     }
        // });

        $scope.changeView = function (action){
            var path = action.destination;
            if(!action) return;
            $log.debug('Change Tab');
            //$scope.selectedIndex = action.index;
            window.location = '#/' + path;
        };

        $scope.isHomePage = function () {
            return ($location.path() === '/');
        };

        $scope.logout = function () {
            AuthService.logout();
        };
    }
})();