(function () {
    "use strict";

    angular.module('archie.core')
        .directive('collapseableContainer', function () {
            return {
                'restrict': 'E',
                'transclude': true,
                'replace': true,
                'templateUrl': 'library/collapseable-container/collapseable-container.html',
                'scope': {
                    'label': '=',
                    'loading': '=',
                    'properties': '=',
                    'expandFn': '&',
                    'expanded': '='
                },
                'controller': ['$scope', function($scope){
                    $scope.isExpanded = $scope.expanded;
                    $scope.toggleExpand = function($event){
                        $event.preventDefault();
                        $event.stopPropagation();
                        $scope.isExpanded = !$scope.isExpanded;
                        if($scope.isExpanded) {
                            $scope.expandFn();
                        }
                    }
                }]
            }
        })
})();