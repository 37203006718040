

(function (){
    "use strict";

    // const src = 'https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/1080/Big_Buck_Bunny_1080_10s_5MB.mp4'

    // Asset Service
    angular.module('archie.core').factory('AssetService', AssetService);

    /**
     * AssetService - Asset Service
     *
     * @param  {Logger} $log                Logger
     * @param  {HttpWrapperService} HttpWrapperService HTTP Wrapper
     */
    /* @ngInject */
    function AssetService($log, HttpWrapperService, $q, SearchUtil, $filter){
        return {
            getAsset: getAsset,
            getAssetsByIds: getAssetsByIds,
            getAssetByTypeAndId: getAssetByTypeAndId,
            getAssetStreamByX: getAssetStreamByX,
            getRelatedAssets: getRelatedAssets
        };

        /**
         * getAsset - Get metadata on an individual asset
         *
         * @param  {string}     assetId asset id
         * @param  {boolean}    dialogue url param
         * @param  {boolean}    includePlaybackDetails url param
         * @return {Array}      asset metadata
         */
        function getAsset(assetId, dialogue, includePlaybackDetails){
            $log.debug('Get asset: ' + assetId);

            var url = '/detail/' + encodeURIComponent(assetId);
            var searchText = SearchUtil.getSearchText();
            function getAssetComplete(response){
                // response.data.videos = { drmOptions: [{ src, type: 'video/mp4' }] }
                return response.data;
            }

            if (dialogue === true && searchText) {
                url += '?dialogueText=' + encodeURIComponent(searchText);
            }

            if (includePlaybackDetails === true) {
                url += $filter('paramDelimiter')(url);
                url += 'includePlaybackDetails=true';
            }

            return HttpWrapperService.get(url).then(getAssetComplete);

        }

        // TODO? 'getAssetsByIds' method is never called by ui. Should we remove it?
        /**
         * getAssetsByIds - Get one or more assets by ids
         *
         * @param  {Array.number} assetIds list of asset ids
         * @return {Array}          list of asset metadata
         */
        function getAssetsByIds(assetIds){
            function getAssetsComplete(response){
                //response.data.videos = { drmOptions: [{ src, type: 'video/mp4' }] } return response.data;
            }
            $log.debug('Get assets by Ids: ' + assetIds);
            return HttpWrapperService.post('/subjects/summaries', assetIds).then(getAssetsComplete);
        }

        /**
         * getAssetStreamByX - Get assets by filter and query parameters
         *
         * @param  {string} collectionPath collection of assets to filter against
         * @param  {Array} queryParts     parameters of the query
         * @return {Array}                set of assets
         */
        function getAssetStreamByX(params){
            var url = '/search?' + params;
            function getAssetStreamComplete(response){
                $log.debug('Get asset stream by filter type completed');
                //response.data.videos = { drmOptions: [{ src, type: 'video/mp4' }] }
                return response.data;
            }
            $log.debug('Get asset stream by faceting and optionally by full text query: ' + url);
            return HttpWrapperService.get(url).then(getAssetStreamComplete);
        }

        /**
         *
         * @param {string} assetType  - series, episode, etc.
         * @param {string} id - id of asset
         * @returns {Object} creative work details
         */
        function getAssetByTypeAndId(assetType, id, params, endpoint){
            var idParam;
            // id  may or may not be base64 encoded
            // need to decode before encoding uri
            // try/catch will handle this either way
            try {
                id = atob(id);
                idParam = encodeURIComponent(id);
            } catch (e) {
                idParam = encodeURIComponent(id);
            }
            var url;
            if(endpoint) {
                url = '/' + endpoint + '/' + assetType + '/' + idParam;
            } else {
                url = '/' + assetType + '/' + idParam;
            }

            if(angular.isArray(params) && params.length > 0) {
                params = SearchUtil.serializeParams(params);
                url += $filter('paramDelimiter')(url);
                url += params;
            }
            function getAssetComplete(response) {
                $log.debug('Get asset by type & id completed');
                //response.data.videos = { drmOptions: [{ src, type: 'video/mp4' }] }
                return response.data;
            }
            return HttpWrapperService.get(url).then(getAssetComplete);
        }

        /**
         *
         * @param {string} relatedContentKey - series
         * @param {string} id - value for relatedContentKey
         * @param {string} relatedContentKey - seriesId
         * @returns {Array} list of related assets
         */
        function getRelatedAssets(relatedContentKey, id, relatedContentType) {
            var url = '/' + relatedContentKey + '/' + encodeURIComponent(id) + '/' + relatedContentType;
            function getAssetsComplete(response) {
                $log.debug('Get related assets completed');
                return response.data;
            }
            return HttpWrapperService.get(url).then(getAssetsComplete);
        }

    }
})();
