(function () {
    "use strict";

    angular.module('archie.core')
        .directive('genSearchMenu', GenSearchMenuDirective);

    function GenSearchMenuDirective($timeout) {
        return {
            'restrict': 'E',
            'replace': true,
            'templateUrl': 'layout/gen-search/gen-search-menu.html',
            'scope': {
                'searchText': '=',
                'menu': '=',
                'panelRef': '=',
                'container': '='
            },
            'controller': 'GenSearchMenuController',
            'link': function (scope, element) {
                $timeout(function () {
                    scope.init();
                });
            }
        };
    }


})();