(function () {
    "use strict";

    /** Base 64 encode a String
     * @param {String} input -> The value to encode
     * @returns Base 64 encoded String
     */
    angular.module('archie.core')
        .filter('b64encode', function() {
            return function(input) {
                if(input) {
                    return btoa(input); //Base64 encoding
                }
                return '';
            }
        });
})();