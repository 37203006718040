(function () {
    "use strict";

    /**
     * @param {Array} arr -> ['joe', 'john', 'jim']
     * @returns {string} -> 'joe, john, jim'
     */
    angular.module('archie.core')
        .filter('arrayToList', function(){
            return function(arr){
                if(!angular.isArray(arr)) return arr;
                return arr.join(', ');
            };
        });
})();