(function(){
    "use strict";

    angular.module('archie.core')
        .directive('cardSetLandingText', ['icons', 'FeedbackService', function(icons, FeedbackService){
            return {
                'restrict': 'E',
                'templateUrl': 'layout/card-set/card-set-landing-text.html',
                'controller': [
                    '$scope',
                    function($scope){
                        $scope.icons = icons;
                        $scope.title = appConfig.title;
                        $scope.subTitle = appConfig.subTitle;
                        $scope.feedback = FeedbackService.sendFeedback();
                    }
                ]
            }
        }]);
})();