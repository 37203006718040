(function () {
    'use strict';

    /**
     * Dialogue Search Controller
     */
    angular.module('archie.core').controller('DialogueSearchController', DialogueSearchController);
    /**
     * DialogueSearchController - Dialogue Search Controller
     */
    /* @ngInject */
    function DialogueSearchController(
        $scope,
        $log,
        $location,
        $rootScope,
        SearchUtil,
        OmnitureService,
        snowplowUtilsService
    ) {
        $scope.searchText = {
            value: SearchUtil.getSearchText(),
        };

        $scope.$watch(
            function () {
                return SearchUtil.getSearchText();
            },
            function (newText) {
                $scope.searchText.value = newText;
            }
        );

        $scope.keyup = function ($event) {
            // 13 = enter key
            if ($event.keyCode === 13) $scope.search();
        };

        $scope.search = function () {
            const btnName = 'Dialogue Search';
            snowplowUtilsService.trackButtonClick(btnName, btnName);
            if ($scope.searchText.value === SearchUtil.getSearchText()) return;
            var path = $location.path().split('/')[1];
            var searchParams = $location.search();
            if (!$scope.searchText.value) {
                $scope.searchText.value = '';
            } else {
                //record entered search text in Omniture
                OmnitureService.dialogueSearch($scope.searchText.value);
            }
            $log.debug('Execute Search: ' + $scope.searchText.value);
            $rootScope.offset = 0;
            SearchUtil.setSearchText($scope.searchText.value);
            SearchUtil.setOffset(0);
            searchParams['offset'] = 0;
            // Encode the search text before setting it on the URI
            var newPath = '/' + path + '/' + encodeURIComponent($scope.searchText.value);
            // if clicking search again, reload route
            $location.path(newPath).search(searchParams);
        };

        $scope.clearSearch = function () {
            $scope.searchText.value = '';
            SearchUtil.clearDialogueSearch();
            const btnName = 'Clear Dialogue Search';
            snowplowUtilsService.trackButtonClick(btnName, btnName);
        };
    }
})();
