(function () {
    "use strict";

    /**
     * Converts timestamp to readable timestring
     * 2113.167 -> 00:35:13:167
     */
    angular.module('archie.core')
        .filter('timestampToString',  function () {

            function numToPaddedString(num, count){
                var pad = '';
                count = count || 2;
                for(var i = 0; i<count; i++){
                    pad += '0';
                }
                return (pad+num.toString()).slice(-count);
            }

            return function (time, includeMilliseconds) {
                var hours = Math.floor(time/3600);
                var minutesRemaining = time % 3600;
                var secondsRemaining = minutesRemaining % 60;
                var milliseconds = Math.round((secondsRemaining - Math.floor(secondsRemaining)) * 1000);
                var timeString = numToPaddedString(hours)
                    + ':' + numToPaddedString(Math.floor(minutesRemaining/60))
                    + ':' + numToPaddedString(Math.floor(secondsRemaining));
                if(includeMilliseconds !== false){
                    timeString += ':' + numToPaddedString(milliseconds, 3);
                }
                return timeString;
            };
        });
})();