(function () {
    "use strict";

    /** Sort alphanumeric values.
     * @param {Array} arr -> Array of data to be sorted.
     * @param {boolean} isNumeric -> true if the values being sorted are numeric
     * @param {String} sortProp -> Used when sorting objects based on a certain property.
     *  If sorting primitive values instead of objects, sortProp is null.
     * @returns Sorted array
     */
    angular.module('archie.core')
        .filter('alphanumericSort', ['$filter', function($filter){
            return function(arr, isNumeric, sortProp){
                if(!angular.isArray(arr)) return;
                return arr.sort(function (a, b) {
                    if(sortProp) {
                        a = a[sortProp];
                        b = b[sortProp];
                    }
                    a = (isNumeric) ? parseInt(a) : $filter('cleanString')(a.toLowerCase());
                    b = (isNumeric) ? parseInt(b) : $filter('cleanString')(b.toLowerCase());
                    if (a < b)
                        return -1;
                    if (a > b)
                        return 1;
                    return 0;
                });
            }
        }]);
})();