(function () {
    'use strict';

    /**
     * Component Dependencies:
     *
     * copyUrl
     * shareButton
     */

    /**
     * A plugin that places the Share Button and Copy URL component on the control bar.
     * @param options
     */
    function shareLinkPlugin(options) {
        // don't display if url is not provided to directive.
        if (!options.url) return;
    }

    // Tells videojs about our custom "share link" plugin.
    videojs.registerPlugin('shareLink', shareLinkPlugin);
})();
