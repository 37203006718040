(function () {
    'use strict';

    angular.module('archie.core').factory('VideoDialog', VideoDialog);
    function VideoDialog(
        $rootScope,
        $route,
        $location,
        $mdDialog,
        DrmPlayerService,
        snowplowUtilsService,
        appContainerId
    ) {
        var paramKey = 'openAsset';
        var routeUpdateListener;
        var routeChangeListener;
        var player;
        var errorListener;
        var dialog = {
            element: null,
            isShowing: false,
        };

        return {
            viewVideo: viewVideo,
            closeDialog: closeDialog,
            removeOpenAssetParam: removeOpenAssetParam,
            getOpenAssetUrl: getOpenAssetUrl,
        };

        function viewVideo(asset, assetType, title, thumbnail, permalink, showThumbNav) {
            if (dialog.isShowing) return;

            var dialogConfig = {
                parent: angular.element('#' + appContainerId),
                templateUrl: 'video-dialog/video-dialog.html',
                clickOutsideToClose: true,
                controller: 'VideoDialogController',
                resolve: {},
                locals: {},
            };
            var onRemoving;
            var onComplete;

            if (assetType === 'searchResult') {
                dialogConfig.resolve = {
                    asset: [
                        'PreferenceUtilService',
                        'AssetService',
                        function (PreferenceUtilService, AssetService) {
                            var promise = AssetService.getAsset(
                                asset.id,
                                PreferenceUtilService.getDialogueSearch(),
                                true
                            );
                            promise.catch(function () {
                                dialog = {
                                    element: null,
                                    isShowing: false,
                                };
                            });
                            return promise;
                        },
                    ],
                };

                var assetId = asset.featureId ? asset.featureId : asset.episodeId;

                dialogConfig.locals = {
                    startTime: asset.startTime,
                    endTime: asset.endTime,
                    highlights: asset.highlights,
                    id: assetId,
                    title: title,
                    searchResult: angular.copy(asset),
                    poster: thumbnail,
                    permalink: !!permalink,
                    showThumbNav: !!showThumbNav,
                };
                onRemoving = function () {
                    setOpenAssetParam(true);
                };
                onComplete = function (scope, element) {
                    if (!$location.search()[paramKey]) {
                        setOpenAssetParam(false, asset);
                    }
                };
            } else if (assetType === 'episodes') {
                var params = [
                    {
                        key: 'includePlaybackDetails',
                        value: true,
                    },
                ];
                dialogConfig.resolve = {
                    asset: [
                        'AssetService',
                        function (AssetService) {
                            var promise = AssetService.getAssetByTypeAndId(
                                assetType,
                                asset.episodeId,
                                params
                            );
                            promise.catch(function () {
                                dialog = {
                                    element: null,
                                    isShowing: false,
                                };
                            });
                            return promise;
                        },
                    ],
                };
                dialogConfig.locals = {
                    startTime: null,
                    endTime: null,
                    highlights: null,
                    id: asset.episodeId,
                    title: title,
                    searchResult: null,
                    poster: thumbnail,
                    permalink: !!permalink,
                    showThumbNav: !!showThumbNav,
                };
            } else if (assetType === 'features') {
                var params = [
                    {
                        key: 'includePlaybackDetails',
                        value: true,
                    },
                ];
                dialogConfig.resolve = {
                    asset: [
                        'AssetService',
                        function (AssetService) {
                            var promise = AssetService.getAssetByTypeAndId(
                                assetType,
                                asset.featureId,
                                params
                            );
                            promise.catch(function () {
                                dialog = {
                                    element: null,
                                    isShowing: false,
                                };
                            });
                            return promise;
                        },
                    ],
                };
                dialogConfig.locals = {
                    startTime: null,
                    endTime: null,
                    highlights: null,
                    id: asset.featureId,
                    title: title,
                    searchResult: null,
                    poster: thumbnail,
                    permalink: !!permalink,
                    showThumbNav: !!showThumbNav,
                };
            } else {
                dialog = {
                    element: null,
                    isShowing: false,
                };
                return;
            }

            dialogConfig.onRemoving = function () {
                if (angular.isFunction(onRemoving)) {
                    onRemoving();
                }
                removeMouseEvents();
                dialog = {
                    element: null,
                    isShowing: false,
                };
                // remove player error $watch
                // NOTE: must do this prior to calling dispose()!
                errorListener();
                DrmPlayerService.dispose();
                player = null;
            };

            dialogConfig.onComplete = function (scope, element) {
                if (angular.isFunction(onComplete)) {
                    onComplete();
                }
                // save instance of the dialog
                dialog = {
                    element: element,
                    isShowing: true,
                };
                errorListener = $rootScope.$watch(
                    function () {
                        player = DrmPlayerService.getPlayer();
                        return player && player.error();
                    },
                    function (error) {
                        if (!!error) {
                            closeDialog();
                        }
                    },
                    true
                );
                addMouseEvents();
            };

            dialogConfig.resolve.hasSegments = [
                'AssetService',
                function (AssetService) {
                    var episodeId = asset.episodeId;
                    var featureId = asset.featureId;
                    if (!episodeId && !featureId) return false;

                    var params = [
                        {
                            key: 'offset',
                            value: 0,
                        },
                        {
                            key: 'limit',
                            value: 0,
                        },
                    ];

                    if (episodeId) {
                        // ping discovery service to see if episode has segments, to determine if we can enable the "scenes view" button
                        return AssetService.getAssetByTypeAndId(
                            'episode',
                            episodeId,
                            params,
                            'asset-metadata'
                        ).then(function (asset) {
                            // if segment count is greater >= 250 (shouldn't ever be the case),
                            // segment bar will be useless so return false in those instances.
                            return asset.total > 0 && asset.total < 250;
                        });
                    } else if (featureId) {
                        return AssetService.getAssetByTypeAndId(
                            'features',
                            featureId,
                            params,
                            'asset-metadata'
                        ).then(function (asset) {
                            return asset.total > 0 && asset.total < 250;
                        });
                    }
                },
            ];

            // launch the video dialog with above config settings
            $mdDialog.show(dialogConfig);

            // close the dialog if no openAsset param present on route change
            routeChangeListener = $rootScope.$on('$routeChangeStart', function (event, next) {
                routeChangeListener();
                if (dialog.isShowing) {
                    closeDialog();
                }
            });
        }

        // Closes video panel
        function closeDialog() {
            $mdDialog.hide();
        }

        /**
         * allow changes to query string params without causing view reload
         */
        function suspendReloadOnSearch() {
            // prevents a route reload when $location.search() is updated
            $route.current.$$route.reloadOnSearch = false;
            routeUpdateListener = $rootScope.$on('$routeUpdate', function (_e, next) {
                snowplowUtilsService.firePageView(next.$$route.originalPath);
                // after $location.search() is updated, reset route reload
                $route.current.$$route.reloadOnSearch = true;
                // and remove the $on $routeUpdate event listener
                routeUpdateListener();
            });
        }

        function getOpenAssetParam(asset) {
            var id = asset.id;
            var start = asset.startTime;
            var end = asset.endTime;
            if (asset.startTime === null) {
                if (asset.featureId && asset.featureId !== null) {
                    id = asset.featureId;
                } else {
                    id = asset.episodeId;
                }
            }

            var openAsset = {
                id: id,
                startTime: start,
                endTime: end,
            };
            return btoa(JSON.stringify(openAsset));
        }

        /**
         * for adding/removing video asset id as query string param
         * @param isRemoving bool
         * @param asset object - video clip asset
         */
        function setOpenAssetParam(isRemoving, asset) {
            var search = $location.search();

            if (isRemoving && !search[paramKey]) return;

            suspendReloadOnSearch();

            if (isRemoving) {
                delete search[paramKey];
            } else {
                search[paramKey] = getOpenAssetParam(asset);
            }

            $location.search(search).replace(); //replace so not to push to history.
        }

        function removeOpenAssetParam() {
            var search = $location.search();
            if (!search[paramKey]) return;
            suspendReloadOnSearch();
            delete search[paramKey];
            $location.search(search).replace(); //replace so not to push to history.
        }

        /**
         *
         * @returns {string} url for openAsset
         */
        function getOpenAssetUrl(asset) {
            //only want root url path with 'openAsset' param;
            //remove query string params
            var url = $location.$$absUrl.split('?')[0];
            //remove path params if from search resuslts page
            var rootPath = $route.current.loadedTemplateUrl.split('/')[0];
            if (rootPath !== 'library') {
                angular.forEach($route.current.pathParams, function (value) {
                    url = url.replace('/' + encodeURIComponent(value), '');
                });
            }
            //add 'openAsset' query string param
            url += '?' + paramKey + '=' + getOpenAssetParam(asset);
            return url;
        }

        function setUserActive() {
            // shows the player's control bar
            // player.userActive(true);
        }

        function addMouseEvents() {
            dialog.element.on('mousemove', setUserActive);
            dialog.element.on('mousedown', setUserActive);
        }

        function removeMouseEvents() {
            dialog.element.off('mousemove', setUserActive);
            dialog.element.off('mousedown', setUserActive);
        }
    }
})();
