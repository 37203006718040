(function() {
    "use strict";
    angular.module('archie.core')
        .controller('ErrorTextController', function($scope, FeedbackService) {
            $scope.reportBug = FeedbackService.reportBug();
        })
        .directive('errorText', function () {
           return {
                restrict: 'E',
                replace: false,
                templateUrl: 'utils/error-text.html',
                controller: 'ErrorTextController'
            }
        });
})();
