(function () {
    "use strict";

    angular.module('archie.core')
        .directive('facetSearch', FacetSearchDirective);

    function FacetSearchDirective($timeout) {
        return {
            'restrict': 'E',
            'replace': true,
            'templateUrl': 'layout/card-filters/facet-search/facet-search.html',
            'scope': {
                'data': '=',
                'updatedData': '=',
                'sortedBy': '=',
                'isAutoFocus': '=',
                'focusOnProp': '=',
                'facetId': '=',
                'facetLimit': '=',
                'searchText': '=',
                'updateOnChange': '=',
                'getItemsFn': '&',
                'itemShowProp': '@',
                'itemModelProp': '@',
                'itemLabelProp': '@',
                'itemSizeProp': '@',
                'altSortProp': '@',
                'altSortLabel': '@'
            },
            'controller': 'FacetSearchController',
            'link': function (scope) {
                $timeout(function () {
                    scope.init();
                });
            }
        }
    }


})();