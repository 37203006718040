(function () {
    "use strict";

    angular.module('archie.core').controller('VideoDialogController', VideoDialogController);
    function VideoDialogController($scope, asset, hasSegments, startTime, endTime,
                                   id, highlights, title, searchResult, poster, permalink, showThumbNav,
                                   VideoDialog, ErrorService, DrmPlayerService,
                                   MetadataMappingService, AssetService,
                                   AuthService, $timeout){

        // Override some asset properties with data from search result
        asset.startTime = startTime;
        asset.endTime = endTime;
        asset.highlights = highlights;
        // End override properties

        var count = 0;
        var total = 0;
        var offset = 0;
        var currentMetadata = {};
        var mappedMetadata = MetadataMappingService.getMetadataMap();
        delete mappedMetadata.dialogue;

        // FROM video dialog
        $scope.asset = asset;
        $scope.assetId = asset.id;
        $scope.poster = poster;
        $scope.showThumbNav = showThumbNav;
        $scope.start = startTime;
        $scope.end = endTime;
        $scope.autoplay = true;
        $scope.assetUrl = (permalink) ? VideoDialog.getOpenAssetUrl(asset) : null;
        if(!$scope.asset.videos || !$scope.asset.videos.drmOptions) {
            ErrorService.videoError(null, null, 'missing video ' + $scope.assetInfo);
        }
        $scope.video = asset.videos.drmOptions;
        $scope.searchResult = searchResult;

        // FROM Library creative work dialog
        $scope.player = null;
        $scope.title = title;
        $scope.metadata = {
            'total': [],
            'selected': []
        };
        $scope.segments = {
            'total': [],
            'selected': []
        };
        $scope.mappedMetadata = [];
        $scope.tooltip = null;
        $scope.index = {
            'top': 0,
            'selected': null
        };
        $scope.selectedTab = 0;
        $scope.searchSegment = false;

        $scope.metadataView = {
            // NOTE: do not delete in case want to persist settings to local storage in future.
            // 'opened': (PreferenceUtilService.get('metadataVideoView') === 'true' && hasSegments),
            'opened': false,
            'showMsg': 'Show Scenes View',
            'hideMsg': 'Hide Scenes View',
            'disableMsg': 'Scenes View Unavailable for this Video',
            'ready': false
        };

        $scope.hasTemporalMetadata = false;

        $scope.closeDialog = function () {
            VideoDialog.closeDialog();
        };

        $scope.setTime = function (metadata) {
            $scope.player.currentTime(parseFloat(metadata.startTime).toFixed(3));
        };

        $scope.clickThumbnail = function (metadata) {
            $scope.player.off('timeupdate', selectMetadata);
            $scope.index.selected = $scope.metadata.total.indexOf(metadata);
            $scope.mappedMetadata = MetadataMappingService.mapMetadata(metadata);
            $scope.player.currentTime(toFixedNumber(metadata.startTime));
            $scope.player.on('timeupdate', selectMetadata);
        };

        $scope.showTooltip = function (index, startTime, endTime, pos) {
            if(!$scope.metadata.total[index]) return;
            $scope.tooltip = {
                'index': index,
                'startTime': startTime,
                'endTime': endTime,
                'pos': pos,
                'metadata': MetadataMappingService.mapMetadata($scope.metadata.total[index]),
                'thumbnail': $scope.metadata.total[index].assetThumbnail.url
            };
        };

        $scope.hideTooltip = function () {
            $scope.tooltip = null;
        };

        $scope.deselectSegments = function () {
            $scope.segments.selected = [];
        };

        $scope.$watch(function () {
            return DrmPlayerService.getPlayer();
        }, function (newPlayer) {
            if(newPlayer) {
                $scope.player = newPlayer;
                initPlayerEvents();
            } else {
                $scope.player = null;
            }
        });

        $scope.$watch(function () {
            return $scope.segments.selected;
        }, function (selectedSegments) {
            $scope.player.trigger('highlightSegments', selectedSegments);
        }, true);

        $scope.$watch(function () {
            return $scope.metadataView.opened;
        }, function (opened) {
            if(opened) {
                $timeout(function () {
                    $scope.metadataView.ready = true;
                    $scope.player.on('timeupdate', selectMetadata);
                }, 500);
            } else {
                $scope.metadataView.ready = false;
                $scope.player.off('timeupdate', selectMetadata);
            }
        });

        function toFixedNumber(num) {
            return Number(parseFloat(num).toFixed(3));
        }

        function selectMetadata() {
            var mappedMetadata;
            var currentTime = toFixedNumber($scope.player.currentTime());
            var newMetadata = $scope.metadata.total.filter(function (metadata) {
                var start = toFixedNumber(metadata.startTime);
                var end = toFixedNumber(metadata.endTime);
                return (currentTime >= start && currentTime < end);
            })[0];

            if(!angular.equals(newMetadata, currentMetadata)) {
                currentMetadata = newMetadata;
            } else {
                return;
            }

            // NOTE: save in case decide to show search result metadata when enhanced view is toggled
            /*
            if(currentTime >= $scope.start && currentTime < $scope.end) {
                $scope.searchSegment = true;
                mappedMetadata = MetadataMappingService.mapMetadata($scope.searchResult);
            } else {
                $scope.searchSegment = false;
                mappedMetadata = MetadataMappingService.mapMetadata(newMetadata);
            }
           */

            $scope.searchSegment = false;
            mappedMetadata = MetadataMappingService.mapMetadata(newMetadata);

            if(!angular.equals($scope.mappedMetadata, mappedMetadata)) {
                $scope.mappedMetadata = mappedMetadata;
                $scope.index.selected = $scope.metadata.total.indexOf(newMetadata);
                $scope.index.top = $scope.index.selected;
                $scope.$apply();
            }

        }

        function initPlayerEvents() {
            $scope.player.on('ready', function () {
                if(!hasSegments) {
                    $scope.player.trigger('disableMetadataViewToggle');
                }
            });
            $scope.player.on('seeked', function () {
                $scope.$apply();
            });
            $scope.player.on('segmentMouseenter', function (event, data) {
                $scope.showTooltip(data.dataset.index, data.dataset.startTime, data.dataset.endTime, data.pos);
                $scope.$apply();
            });
            $scope.player.on('segmentMouseleave', function () {
                $scope.hideTooltip();
                $scope.$apply();
            });
            $scope.player.one('loadedmetadata', function () {
                if($scope.metadataView.opened) {
                    getSegments();
                }
            });
            $scope.player.on('toggleMetadataView', function (event, data) {
                $scope.metadataView.opened = data.opened;
                $scope.$apply();
                // NOTE: do not delete in case want to persist settings to local storage in future.
                // PreferenceUtilService.set('metadataVideoView', $scope.metadataView.opened);
                if($scope.metadataView.opened) {
                    getSegments();
                }
            });
            $scope.player.on('userinactive', function () {
                $scope.toggleCopyUrl = false;
                $scope.hideTooltip();
                $scope.$apply();
            });
            $scope.player.on('shareurl', function () {
                $scope.attachCopyUrlTo = ($scope.player.isFullscreen()) ? '.video-js' : null;
                $scope.toggleCopyUrl = true;
                $scope.$apply();
            });
        }

        function addSegments(assets) {
            var addedSegments = [];
            angular.forEach(assets, function (asset) {
                var segment = {
                    'startTime': asset.startTime,
                    'endTime': asset.endTime
                };
                addedSegments.push(segment);
                $scope.segments.total.push(segment);
            });
            return addedSegments;
        }

        function getSegments() {
            $timeout(function () {
                selectMetadata();
            }, 500);
            var params = [{
                'key': 'offset',
                'value': offset
            }, {
                'key': 'limit',
                'value': 25
            }];
            var assetType = $scope.asset.featureId ? 'features' : 'episode';

            return AssetService.getAssetByTypeAndId(assetType, id, params, 'asset-metadata').then(function (asset) {
                count = asset.count;
                total = asset.total;
                offset += asset.count;
                angular.forEach(asset.assets, function (data) {
                    data.assetThumbnail.url = AuthService.appendUrl(data.assetThumbnail.url);
                    // check if asset has temporal metadata, if it does set scope prop to true
                    if(!$scope.hasTemporalMetadata) {
                        angular.forEach(data, function (value, key) {
                           if(mappedMetadata[key]) {
                               $scope.hasTemporalMetadata = true;
                           }
                        });
                    }
                });
                $scope.metadata.total = $scope.metadata.total.concat(asset.assets);
                $scope.player.trigger('addTimelineSegments', addSegments(asset.assets));
                if(offset < total) {
                    getSegments();
                } else {
                    $scope.metadataLoaded = true;
                    $scope.player.trigger('addTimelineSegmentsComplete');
                }
                return asset;
            });
        }

    }

})();
