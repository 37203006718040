// This is the base class for all Video.js controls, called "components".
const VideojsComponent = videojs.getComponent('Component');

/**
 * A component which displays a warning a short time before this video will reload.
 */
class ReloadWarningComponent extends VideojsComponent {
    constructor(player, options) {
        super(player, options);
    }

    /**
     * Returns whether the banner is shown or not.
     * @returns {boolean}
     */
    isShown() {
        return this.el().style.display !== 'none';
    }

    /**
     * Displays the banner.
     */
    show() {
        this.el().style.display = 'block';
    }

    /**
     * Hides the banner.
     */
    hide() {
        this.el().style.display = 'none';
    }

    /**
     * Sets the number of seconds left before the video reloads in this banner. The element will be hidden unless
     * timeLeft is less than or equal to 15 seconds.
     * @param timeLeft - How much time is left before the video reloads.
     */
    setTimeLeft(timeLeft) {
        videojs.dom.emptyEl(this.el());
        videojs.dom.appendContent(this.el(), 'Video will reload in: ' + timeLeft);
        // This is hardcoded, but could be easily added to the config if we want in the future.
        if (timeLeft <= 15) {
            if (!this.isShown()) {
                this.show();
            }
        } else {
            if (this.isShown()) {
                this.hide();
            }
        }
    }

    /**
     * Creates the banner that will contain the warning. To set the text of the warning, use setTimeLeft().
     * @returns {HTMLDivElement}
     */
    createEl() {
        const div = videojs.dom.createEl('div', {}, { class: 'countdown-timer' });
        if (this.options_.show) {
            div.style.display = 'block';
        } else {
            div.style.display = 'none';
        }
        return div;
    }
}

// Tells videojs about our custom "reload warning" component.
videojs.registerComponent('ReloadWarning', ReloadWarningComponent);
