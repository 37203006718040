(function () {
    "use strict";

    angular.module('archie.core').controller('MyidoptionsController', MyidoptionsController);
    function MyidoptionsController($scope, $rootScope, AuthService, loginURL){
        $rootScope.$broadcast('progressIndicator', false);

	$scope.loginOIDC = function() {
		sessionStorage.setItem('authSystem', 'OIDC');
		loginURL.base = appConfig.authUriOIDC;
		AuthService.login();	
	}

	$scope.loginSAML = function() {
		sessionStorage.setItem('authSystem', 'SAML');
		loginURL.base = appConfig.authUriSAML; 
		AuthService.login();
	}


    }

})();
