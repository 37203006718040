(function () {
    "use strict";

    angular.module('archie.core')
        .directive('tableData', function(){
            return {
                'restrict': 'E',
                'replace': true,
                'templateUrl': 'library/table-data/table-data.html',
                'scope': {
                    'headers': '=',
                    'rows': '=',
                    'assetId': '=?',
                    'metadataType': '='
                },
                'controller': function($scope){
                    $scope.isValidStoryRolesTable = function (rows) {
                        const retorno = rows.every((row) => {
                            const storyRoleId = row.storyRoleId;
                            return (
                                (angular.isArray(storyRoleId) &&
                                    storyRoleId.length > 0 &&
                                    storyRoleId[0] !== '') ||
                                (typeof storyRoleId === 'string' && storyRoleId !== '')
                            );
                        });
                        return retorno;
                    };
                }
            };
        });
})();