(function () {
    "use strict";

    angular.module('archie.core')
        .directive('metadataList', MetadataListDirective);

    function MetadataListDirective() {
        return {
            'restrict': 'E',
            'replace': true,
            'templateUrl': 'video-dialog/metadata-list/metadata-list.html',
            'scope': {
                'metadata': '=',
                'highlights': '=',
                'loopSegment': '='
            }
        }
    }


})();