// This is the base class for all Video.js controls, called "components".
const VideojsComponent = videojs.getComponent('Component');

// For segment start and end time display
class SegmentTimeComponent extends VideojsComponent {
    constructor(player, options) {
        super(player, options);
    }

    createEl() {
        const start = convertSeconds(this.options_.start);
        const end = convertSeconds(this.options_.end);

        const div = videojs.dom.createEl(
            'div',
            { textContent: start + ' - ' + end },
            { class: 'vjs-time-control vjs-control segment-time' }
        );

        return div;
    }
}

/**
 * Returns a string formatted in HH:MM:SS
 * @param {string} seconds
 * @returns {string}
 */
function convertSeconds(seconds) {
    return new Date(seconds * 1000).toISOString().substr(11, 8);
}

// Tells videojs about our custom "segment time" component.
videojs.registerComponent('SegmentTime', SegmentTimeComponent);
