(function () {
    "use strict";

    angular.module('archie.core')
        .directive('cardMetadata', CardMetadataDirective);

    function CardMetadataDirective(){
        return {
            'restrict': 'E',
            'templateUrl': 'layout/cards/card-metadata.html',
            'scope': {
                'assetId': '=',
                'viewFullMetadata': '=',
                'highlights': '=?',
                'dialogue': '=?',
                'hideMore': '='
            },
            'controller': ['PreferenceUtilService', 'AssetService', 'MetadataMappingService', '$scope', '$sce', function(PreferenceUtilService, AssetService, MetadataMappingService, $scope, $sce){
                $scope.init = function(){
                    $scope.loading = true;
                    AssetService.getAsset($scope.assetId, PreferenceUtilService.getDialogueSearch(), true).then(function(asset){
                        $scope.asset = asset;
                        $scope.highlights = $scope.highlights || {};
                        //retain shorter version of dialogue from /search/ response for display in card:
                        $scope.asset.dialogue = $scope.dialogue;
                        $scope.metadata = MetadataMappingService.mapMetadata($scope.asset);
                        $scope.loading = false;
                    });
                };

                $scope.tooltip = function (value) {
                    var tooltip = angular.copy(value);
                    if(angular.isArray(tooltip)) {
                        tooltip = tooltip.join(', ');
                    }
                    return tooltip;
                };

            }],
            'link': function(scope){
                scope.init();
            }
        }
    }
})();