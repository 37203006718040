(function () {
    "use strict";

    /**
     * Stringify JSON object
     */
    angular.module('archie.core')
        .filter('stringify', function(){
            return function(obj, offset){
                var stringified = '';
                try{
                    stringified = JSON.stringify(obj, null, +offset || 0);
                } catch(e){}
                return stringified;
            }
        });
})();