(function (){
    "use strict";

    /**
     * Image Error Directive
     */
    angular.module('archie.core').directive('archieImageErrorSrc', ImageErrorDirective);

    /**
     * ImageErrorDirective - Directive for handling image errors with replacement image
     */
    function ImageErrorDirective(){
        return {
            link: function (scope, element, attrs){

                scope.$watch(function (){
                    return attrs['ngSrc'];
                }, function (value){
                    if (!value) {
                        element.attr('src', attrs.archieImageErrorSrc);
                        element.addClass('error');
                    }
                });

                element.bind('error', function (){
                    element.attr('src', attrs.archieImageErrorSrc);
                    element.addClass('error');
                });
            }
        }
    }

})();