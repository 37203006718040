(function () {
    "use strict";

    angular.module('archie.core')
        .directive('relatedContent', function(){
            return {
                'restrict': 'E',
                'replace': false,
                'templateUrl': 'library/related-content/related-content.html',
                'scope': {
                    'type': '=',
                    'key': '=',
                    'relatedId': '=',
                    'id': '=',
                    'properties': '='
                },
                'controller': 'RelatedContentController'
            };
        });
})();