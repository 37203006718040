(function () {
    "use strict";

    angular.module('archie.core')
        .service('PageTitleService', [function(){
            function updateTitle(subTitle){
                var newTitle = appConfig.title;
                if(subTitle){
                    newTitle += ' - ' + subTitle;
                }
                document.title = newTitle;
            }

            return {
                updateTitle: updateTitle
            };
        }]);
})();