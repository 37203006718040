(function () {
    "use strict";

    angular.module('archie.core')
        .directive('nestedData', function(){
            return {
                'restrict': 'E',
                'replace': true,
                'templateUrl': 'library/nested-data/nested-data.html',
                'scope': {
                    'data': '='
                },
                'controller': function($scope){
                    $scope.toggleExpand = function($event){
                        $event.preventDefault();
                        $event.stopPropagation();
                        angular.element($event.delegateTarget).parent().toggleClass('expanded');
                    }
                }
            };
        });
})();