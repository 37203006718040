(function () {
    "use strict";

    angular.module('archie.core')
        .directive('genSearch', GenSearchDirective);

    function GenSearchDirective($timeout) {
        return {
            'restrict': 'E',
            'replace': true,
            'templateUrl': 'layout/gen-search/gen-search.html',
            'scope': {},
            'controller': 'GenSearchController',
            'link': function (scope, element) {
                $timeout(function () {
                    scope.element = element;
                });
            }
        }
    }


})();