import search from './search/search.html';
import creativeWork from './library/creative-work.html';
import myIdOptions from './myidoptions/myidoptions.html';
import cardFilters from './layout/card-filters/card-filters.html';
import cardFiltersFacet from './layout/card-filters/facet/card-filters-facet.html';
import facetOption from './layout/card-filters/facet/facet-option.html';
import facetSearchDialog from './layout/card-filters/facet-search/facet-search-dialog.html';
import facetSearch from './layout/card-filters/facet-search/facet-search.html';
import cardSetLandingText from './layout/card-set/card-set-landing-text.html';
import cardSetNoResults from './layout/card-set/card-set-no-results.html';
import cardSet from './layout/card-set/card-set.html';
import cardMetadata from './layout/cards/card-metadata.html';
import metadataPanel from './layout/cards/metadata-panel.html';
import gridCard from './layout/cards/grid/grid-card.html';
import listCard from './layout/cards/list/list-card.html';
import simpleGridCard from './layout/cards/simple-grid/simple-grid-card.html';
import dialogueSearch from './layout/dialogue-search/dialogue-search.html';
import getSearchMenu from './layout/gen-search/gen-search-menu.html';
import genSearch from './layout/gen-search/gen-search.html';
import searchInput from './layout/search-input/search-input.html';
import copyUrlPanel from './layout/share-url/copy-url.panel.html';
import shareUrl from './layout/share-url/share-url.html';
import toolbar from './layout/toolbar/toolbar.html';
import dialogueSearchSelector from './layout/toolbar/dialogue-search-selector/dialog-search-selector.html';
import collapsibleContainer from './library/collapseable-container/collapseable-container.html';
import listFieldItem from './library/fields/list-field/list-field-item.html';
import listField from './library/fields/list-field/list-field.html';
import nestedData from './library/nested-data/nested-data.html';
import pagination from './library/pagination/pagination.html';
import relatedContent from './library/related-content/related-content.html';
import tabAdditionalInfo from './library/tab-additional-info/tab-additional-info.html';
import tabContents from './library/tab-contents/tab-contents.html';
import tabMetadata from './library/tab-metadata/tab-metadata.html';
import tabRelatedContent from './library/tab-related-content/tab-related-content.html';
import tableData from './library/table-data/table-data.html';
import message from './message_console/message.html';
import releaseDialog from './release-dialog/release-dialog.html';
import errorText from './utils/error-text.html';
import videoDialog from './video-dialog/video-dialog.html';
import metadataList from './video-dialog/metadata-list/metadata-list.html';
import metadataSelect from './video-dialog/metadata-select/metadata-select.html';
import segmentTooltip from './video-dialog/segment-tooltip/segment-tooltip.html';
import drmPlayer from './videojs/drm-player.html';

angular.module('archie').run(
    /* @ngInject */ ($templateCache) => {
        $templateCache.put('search/search.html', search);
        $templateCache.put('library/creative-work.html', creativeWork);
        $templateCache.put('myidoptions/myidoptions.html', myIdOptions);

        $templateCache.put('layout/card-filters/card-filters.html', cardFilters);
        $templateCache.put('layout/card-filters/facet/card-filters-facet.html', cardFiltersFacet);
        $templateCache.put('layout/card-filters/facet/facet-option.html', facetOption);
        $templateCache.put(
            'layout/card-filters/facet-search/facet-search-dialog.html',
            facetSearchDialog
        );
        $templateCache.put('layout/card-filters/facet-search/facet-search.html', facetSearch);
        $templateCache.put('layout/card-set/card-set-landing-text.html', cardSetLandingText);
        $templateCache.put('layout/card-set/card-set-no-results.html', cardSetNoResults);
        $templateCache.put('layout/card-set/card-set.html', cardSet);
        $templateCache.put('layout/cards/card-metadata.html', cardMetadata);
        $templateCache.put('layout/cards/metadata-panel.html', metadataPanel);
        $templateCache.put('layout/cards/grid/grid-card.html', gridCard);
        $templateCache.put('layout/cards/list/list-card.html', listCard);
        $templateCache.put('layout/cards/simple-grid/simple-grid-card.html', simpleGridCard);
        $templateCache.put('layout/dialogue-search/dialogue-search.html', dialogueSearch);
        $templateCache.put('layout/gen-search/gen-search-menu.html', getSearchMenu);
        $templateCache.put('layout/gen-search/gen-search.html', genSearch);
        $templateCache.put('layout/search-input/search-input.html', searchInput);
        $templateCache.put('layout/share-url/copy-url.panel.html', copyUrlPanel);
        $templateCache.put('layout/share-url/share-url.html', shareUrl);
        $templateCache.put('layout/toolbar/toolbar.html', toolbar);
        $templateCache.put(
            'layout/toolbar/dialogue-search-selector/dialog-search-selector.html',
            dialogueSearchSelector
        );
        $templateCache.put(
            'library/collapseable-container/collapseable-container.html',
            collapsibleContainer
        );
        $templateCache.put('library/fields/list-field/list-field-item.html', listFieldItem);
        $templateCache.put('library/fields/list-field/list-field.html', listField);
        $templateCache.put('library/nested-data/nested-data.html', nestedData);
        $templateCache.put('library/pagination/pagination.html', pagination);
        $templateCache.put('library/related-content/related-content.html', relatedContent);
        $templateCache.put(
            'library/tab-additional-info/tab-additional-info.html',
            tabAdditionalInfo
        );
        $templateCache.put('library/tab-contents/tab-contents.html', tabContents);
        $templateCache.put('library/tab-metadata/tab-metadata.html', tabMetadata);
        $templateCache.put(
            'library/tab-related-content/tab-related-content.html',
            tabRelatedContent
        );
        $templateCache.put('library/table-data/table-data.html', tableData);
        $templateCache.put('message_console/message.html', message);
        $templateCache.put('release-dialog/release-dialog.html', releaseDialog);
        $templateCache.put('utils/error-text.html', errorText);
        $templateCache.put('video-dialog/video-dialog.html', videoDialog);
        $templateCache.put('video-dialog/metadata-list/metadata-list.html', metadataList);
        $templateCache.put('video-dialog/metadata-select/metadata-select.html', metadataSelect);
        $templateCache.put('video-dialog/segment-tooltip/segment-tooltip.html', segmentTooltip);
        $templateCache.put('videojs/drm-player.html', drmPlayer);
    }
);
