(function () {
    "use strict";

    function ListFieldController($scope) {
        $scope.getColumns = function () {
            return new Array($scope.columns);
        };

        $scope.$watch('items', function (newVal, oldVal) {
            if (newVal && !angular.equals(newVal, oldVal)) {
                init(angular.copy(newVal));
            }
        });

        init(angular.copy($scope.items));

        function init(items) {
            // determine how many items should be displayed in each column
            $scope.chunkSize = Math.ceil(items.length / $scope.columns);
            $scope.chunks = [];
            for (var i = 0; i < $scope.columns; i++) {
                // splice items array into x number of chunks
                // insert each chunk into chunks array
                $scope.chunks.push(items.splice(0, $scope.chunkSize));
            }
        }
    }

    angular.module('archie.core')
        .controller('listFieldController', ListFieldController)
        .directive('listField', function () {
            return {
                'restrict': 'E',
                'replace': true,
                'templateUrl': 'library/fields/list-field/list-field.html',
                'scope': {
                    'items': '=',
                    'columns': '=',
                    'columnFlow': '=',
                    'divider': '='
                },
                'controller': 'listFieldController'
            };
        });

})();