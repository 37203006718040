(function () {
    "use strict";

    angular.module('archie.core').directive('facetOption', FacetOptionDirective);
    function FacetOptionDirective($timeout) {

        return {
            'restrict': 'E',
            'replace': true,
            'templateUrl': 'layout/card-filters/facet/facet-option.html',
            'scope': {
                'data': '=',
                'last': '=',
                'showOptionCount': '=?',
                'disabled': '=',
                'modelProp': '@',
                'labelProp': '@',
                'sizeProp': '@',
                'onLastFn': '&',
                'changeFn': '&'
            },
            'link': function (scope) {
                $timeout(function () {
                    if(scope.last && angular.isFunction(scope.onLastFn)) {
                        scope.onLastFn();
                    }
                });
            }
        }
    }

})();