(function(){
    "use strict";
    var PREFERENCE_KEY = 'releaseDialogVersion';

    angular.module('archie.core')
        .directive('releaseDialog', function(){
            return {
                'restrict': 'E',
                'template': '<span ng-click="showDialog()">Release: {{buildVersion}}</span>',
                'controller': [
                    'PreferenceUtilService',
                    'ReleaseDialog',
                    '$scope',
                    'env',
                    'buildVersion',
                    'releaseVersion',
                    function(PreferenceUtilService, ReleaseDialog, $scope, env, buildVersion, releaseVersion){
                        var lastRelease = +PreferenceUtilService.get(PREFERENCE_KEY);
                        $scope.buildVersion = buildVersion;

                        $scope.showDialog = function(){
                            //NOTE: don't delete, hiding temporarily
                            //ReleaseDialog.showDialog();
                        };

                        if(env !== 'production' && (!lastRelease || releaseVersion > lastRelease)){
                            //NOTE: don't delete, hiding temporarily
                            //ReleaseDialog.showDialog();
                        }

                    }
                ]
            }
        });
})();