(function (){
    "use strict";
    angular.module('archie.core')
        .factory('MessageConsole', ['$mdToast', '$sce', MessageConsole]);

    function MessageConsole($mdToast, $sce){
        var messageTypes = {
            'e': ['error_outline', 'rgb(170,0,0)'],
            's': ['', ''],
            'w': ['warning', 'rgb(255, 189, 0)'],
            'n': ['', '', 'bottom center']
        };

        function displayMessage(data){
            console.log(data);
            var msg = data.msg;
            var action = data.action;
            var cb = data.cb;
            var delay = data.delay || 0;
            var message = {
                'hideDelay': delay,
                'position': messageTypes[data.type][2] || 'top right',
                'controller': ['$scope', function($scope){
                    if (data.trustHtml) {
                        $scope.msg = $sce.trustAsHtml(msg)
                    } else {
                        $scope.msg = msg;
                    }
                    $scope.action = action;
                    $scope.icon = messageTypes[data.type][0];
                    $scope.iconColor = messageTypes[data.type][1];

                    $scope.hideMessage = function(){
                        if (typeof data.onHide === 'function') {
                            data.onHide()
                        }
                        $mdToast.hide();
                    };

                    $scope.doAction = function(ev, action){
                        if(cb && cb() !== false){
                            $scope.hideMessage();
                        } else {
                            $scope.hideMessage();
                        }
                    };
                }],
                'templateUrl': 'message_console/message.html'
            };
            $mdToast.show(message).then(function(response){
                if(cb){
                    cb();
                }
                message = null;
            });
        }

        function hideMessage(){
            $mdToast.hide();
        }
        
        return {
            'displayMessage': displayMessage,
            'hideMessage': hideMessage
        }
    }
})();
