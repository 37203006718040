// This is the base class for all Video.js controls, called "components".
const VideojsComponent = videojs.getComponent('Component');

const minSegmentHoverWidth = 25;

class SegmentContainerComponent extends VideojsComponent {
    constructor(player, options) {
        super(player, options);
        this.player = player;
        this.wrapper = videojs.dom.createEl('div', {}, { class: 'segment-wrapper' });
        this.segments = [];
        this.mouseenterTimer = null;
        this.clientX = null;
        this.disableEvents = false;
        // min % of video length required to fill in gap with empty segment
        this.minGapLength = 0.5;
    }

    initSegmentContainer() {
        const self = this;
        // remove any segment elements from container
        videojs.dom.emptyEl(self.el());
        self.segments = [];
        self.dragSegmentWrapper();
        self.el().appendChild(self.wrapper);
    }

    initSegment(segmentEl) {
        // add new segment element to container
        const self = this;
        self.addSegmentEventHandlers(segmentEl);
        self.segments.push(segmentEl);
        self.updateSegment(segmentEl);
        self.wrapper.appendChild(segmentEl);
    }

    emptySegment() {
        // create empty segment propterties
        return {
            class: 'segment segment-empty',
            'data-index': null,
            'data-start-time': 0,
            'data-end-time': 0,
            'data-empty': true,
        };
    }

    addSegments(segments) {
        // add a group of segments to container
        // fill in gaps between segments will an empty segment element
        if (!segments) return;
        const self = this;
        segments = Array.isArray(segments) ? segments : [segments];
        segments.forEach(function (segment, index) {
            // don't count empty segments when calculating index
            const dataIndex = self.segments.filter(function (segmentEl) {
                return !segmentEl.dataset.empty;
            }).length;

            const segmentEl = videojs.dom.createEl(
                'div',
                {},
                {
                    class: 'segment',
                    'data-index': dataIndex,
                    'data-start-time': segment.startTime,
                    'data-end-time': segment.endTime,
                }
            );

            const emptySegment = self.emptySegment();

            let emptySegmentEl = null;

            const segmentsLength = self.segments.length;

            if (index === 0) {
                // handle gap between start of video and very first segment
                if (segmentsLength === 0 && segment.startTime >= self.minGapLength) {
                    emptySegment['data-end-time'] = segment.startTime;
                    emptySegmentEl = videojs.dom.createEl('div', {}, emptySegment);
                    self.initSegment(emptySegmentEl);
                }
                // handle gap between last segment in previous request and first segment in new request
                if (
                    segmentsLength > 1 &&
                    parseFloat(
                        segment.startTime - self.segments[segmentsLength - 1].dataset.endTime
                    ) >= self.minGapLength
                ) {
                    emptySegment['data-start-time'] =
                        self.segments[segmentsLength - 1].dataset.endTime;
                    emptySegment['data-end-time'] = segment.startTime;
                    emptySegmentEl = videojs.dom.createEl('div', {}, emptySegment);
                    self.initSegment(emptySegmentEl);
                }
            }

            self.initSegment(segmentEl);

            // handle gaps between segments
            if (
                segments[index + 1] &&
                parseFloat(segments[index + 1].startTime - segment.endTime) >= self.minGapLength
            ) {
                emptySegment['data-start-time'] = segment.endTime;
                emptySegment['data-end-time'] = segments[index + 1].startTime;
                emptySegmentEl = videojs.dom.createEl('div', {}, emptySegment);
                self.initSegment(emptySegmentEl);
            }
        });
    }

    addSegmentsComplete() {
        // after finished adding all segments, see if there is a gap between last segment
        // and end of video, if so fill with empty segment
        const self = this;
        const lastSegmentEl = self.segments[self.segments.length - 1];
        const emptySegment = self.emptySegment();
        // handle gap between very last segment and video length
        if (
            lastSegmentEl &&
            parseFloat(self.player.duration() - lastSegmentEl.dataset.endTime) >= self.minGapLength
        ) {
            emptySegment['data-start-time'] = lastSegmentEl.dataset.endTime;
            emptySegment['data-end-time'] = self.player.duration();
            self.initSegment(videojs.dom.createEl('div', {}, emptySegment));
        }
    }

    addSegmentEventHandlers(segmentEl) {
        const self = this;
        // click segment sets player time to segment start
        segmentEl.addEventListener('mouseup', function () {
            if (self.disableEvents) return;
            self.player_.currentTime(this.dataset.startTime);
            self.player_.trigger('segmentMouseleave');
            self.zoomOut();
        });
        // 'zoom' in on segments on mouseenter
        segmentEl.addEventListener('mouseenter', function (event) {
            if (self.disableEvents) return;
            self.zoomIn(event.target, event);
        });
        // 'zoom' out on mouseleave
        segmentEl.addEventListener('mouseleave', function () {
            if (self.disableEvents) return;
            clearTimeout(self.mouseenterTimer);
            self.player_.trigger('segmentMouseleave');
        });
    }

    updateSegment(segment, startTime, endTime) {
        const self = this;
        if (!segment) return;

        if (startTime) segment.dataset.startTime = startTime;
        if (endTime) segment.dataset.endTime = endTime;

        // The start percentage can't be greater than 100%
        const startPercent = Math.min(segment.dataset.startTime / self.player_.duration(), 1);
        // The end percentage can't be less than the start percentage, and can't be greater than 100%
        const endPercent = Math.max(
            Math.min(segment.dataset.endTime / self.player_.duration(), 1),
            startPercent
        );
        segment.style.width = ((endPercent - startPercent) * 100).toFixed(2) + '%';
    }

    highlightSegments(highlightSegments) {
        // adds a css class to a group of segments
        // used in metadata-select component to change color
        const self = this;
        // remove any existing segment highlihgts
        self.segments.forEach(function (segmentEl) {
            segmentEl.classList.remove('segment-highlight');
        });
        highlightSegments.forEach(function (highlightSegment) {
            self.segments.forEach(function (segmentEl) {
                if (segmentEl.dataset.startTime === highlightSegment.startTime) {
                    // add class to segment with matching start time
                    segmentEl.classList.add('segment-highlight');
                }
            });
        });
    }

    zoomIn(segment, event) {
        // increases size of all segment elements to make mouseovers easier
        // particularly in the case of short segments
        const self = this;
        let translateX;
        const activeSegment = segment;
        const prevSegment = activeSegment.previousElementSibling;
        const nextSegment = activeSegment.nextElementSibling;

        self.zoomOut();

        self.segments.forEach(function (segment) {
            // increase width, must be at least minSegmentHoverWidth pixels wide
            segment.style.width =
                Math.max(
                    Math.round(segment.getBoundingClientRect().width * 1.5),
                    minSegmentHoverWidth
                ) + 'px';
            if (segment === activeSegment || segment === prevSegment || segment === nextSegment) {
                // add additional css to segment that is hovered on + adjacent segments
                segment.classList.add('zoom');
            }
            if (segment === activeSegment) {
                // add one more additional css to segment hovered on
                segment.classList.add('center');
            }
        });

        // move entire segment container left or right depending on mouse position
        if (!self.clientX) {
            //center
            translateX =
                event.clientX -
                (activeSegment.getBoundingClientRect().left +
                    activeSegment.getBoundingClientRect().width / 2);
        } else if (event.clientX - self.clientX >= 0) {
            //left side
            translateX = event.clientX - activeSegment.getBoundingClientRect().left - 1;
        } else if (event.clientX - self.clientX < 0) {
            //right side
            translateX = event.clientX - activeSegment.getBoundingClientRect().right + 1;
        }

        // expand and move segment container wrapper
        self.wrapper.style.width =
            self.wrapper.getBoundingClientRect().width + Math.abs(translateX) + 'px';
        self.wrapper.style.transform = 'translateX(' + translateX + 'px)';
        self.wrapper.classList.add('zoom');
        self.clientX = event.clientX;

        // send notification of mouseenter for tooltip display
        self.mouseenterTimer = setTimeout(function () {
            self.player_.trigger('segmentMouseenter', {
                pos: activeSegment.getBoundingClientRect(),
                dataset: activeSegment.dataset,
            });
        }, 151);
    }

    zoomOut() {
        // remove all the stuff done in 'zoomIn'
        const self = this;
        self.wrapper.style.removeProperty('transform');
        self.wrapper.style.removeProperty('width');
        self.wrapper.style.removeProperty('transition-duration');
        self.wrapper.classList.remove('zoom');
        self.segments.forEach(function (segment) {
            segment.classList.remove('zoom');
            segment.classList.remove('center');
            self.updateSegment(segment);
        });
    }

    dragSegmentWrapper() {
        // allow user to drag segment wrapper left or right
        // so can access hidden segments due to zoomIn width increases
        const self = this;
        let mousedownTimer = null;

        self.wrapper.addEventListener('mousedown', function (event) {
            self.clientX = event.clientX;
            mousedownTimer = setTimeout(function () {
                document.addEventListener('mousemove', mouseMoveFunc);
                self.disableEvents = true;
                //note: cursor here
            }, 151);
        });

        self.wrapper.addEventListener('mouseup', cancelMouseMoveFunc);

        self.wrapper.addEventListener('mouseleave', function () {
            cancelMouseMoveFunc();
            self.clientX = null;
            self.zoomOut();
        });

        function mouseMoveFunc(event) {
            // get the transform style value of the wrapper element
            const transform = +self.wrapper.style.transform.replace(/[^-0-9.]/g, '');
            const diff = event.clientX - self.clientX;
            const translateX = transform + diff;
            const rightEdge =
                self.segments[self.segments.length - 1].getBoundingClientRect().right <=
                self.el().getBoundingClientRect().right;
            const moveLeft = event.clientX <= self.clientX;
            if (translateX < 0 && !(rightEdge && moveLeft)) {
                self.wrapper.style.transform = 'translateX(' + translateX + 'px)';
            }
            self.clientX = event.clientX;
            self.player_.trigger('segmentMouseleave');
        }

        function cancelMouseMoveFunc() {
            clearTimeout(mousedownTimer);
            document.removeEventListener('mousemove', mouseMoveFunc);
            //note: cursor here
            self.disableEvents = false;
        }
    }

    createEl() {
        const div = videojs.dom.createEl(
            'div',
            {},
            {
                class: 'segment-container',
            }
        );
        return div;
    }
}

videojs.registerComponent('segmentContainer', SegmentContainerComponent);
