/**
 * Component for adding title to upper left corner of player
 */

// This is the base class for all Video.js controls, called "components".
const VideojsComponent = videojs.getComponent('Component');

class VideoTitleComponent extends VideojsComponent {
    constructor(player, options) {
        super(player, options);
    }

    createEl() {
        const div = videojs.dom.createEl(
            'div',
            { textContent: this.options_.text },
            {
                class: 'video-title',
                title: this.options_.text,
            }
        );
        return div;
    }
}

videojs.registerComponent('VideoTitle', VideoTitleComponent);
