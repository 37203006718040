import { trackCheckboxSelect } from '../../utils/snowplow-utils';

(function () {
    'use strict';

    angular.module('archie.core').controller('MetadataSelectController', MetadataSelectController);
    function MetadataSelectController($scope, MetadataMappingService) {
        var mappedMetadata = [];
        var totalOptions = [];
        var selectedStartTimes = [];

        $scope.metadataList = [];

        $scope.init = function () {
            getMetadataOptions();
            createMetadataList();
        };

        $scope.optionChange = function (option, data) {
            if (option.isSelected) {
                addOption(option);
            } else {
                removeOption(option);
            }
            setAvailableOptions();
            const cmpName = `Select Metadata Checkbox: ${data.label}`;
            const label = option.label;
            trackCheckboxSelect(cmpName, label);
        };

        $scope.deselectAllOptions = function () {
            $scope.metadata.selected = [];
            $scope.segments.selected = [];
            selectedStartTimes = [];
            angular.forEach(totalOptions, function (option) {
                option.available = true;
                option.isSelected = false;
            });
        };

        $scope.$watch(
            function () {
                return $scope.segments.selected;
            },
            function (newVal, oldVal) {
                if (!angular.equals(newVal, oldVal) && newVal.length < 1) {
                    $scope.deselectAllOptions();
                }
            }
        );

        $scope.$on('$destroy', function () {
            $scope.deselectAllOptions();
        });

        function addOption(option) {
            $scope.metadata.selected.push(option);
            removeDuplicateStartTimes(option);
        }

        function removeOption(option) {
            $scope.metadata.selected.splice($scope.metadata.selected.indexOf(option), 1);
            selectedStartTimes = [];
            angular.forEach($scope.metadata.selected, function (selectedOption) {
                removeDuplicateStartTimes(selectedOption);
            });
        }

        function removeDuplicateStartTimes(option) {
            if (selectedStartTimes.length < 1) {
                selectedStartTimes = selectedStartTimes.concat(option.startTimes);
            } else {
                selectedStartTimes = selectedStartTimes.filter(function (starTime) {
                    return option.startTimes.indexOf(starTime) > -1;
                });
            }
        }

        function setAvailableOptions() {
            if ($scope.metadata.selected.length < 1) {
                $scope.deselectAllOptions();
                return;
            }
            angular.forEach(totalOptions, function (option) {
                option.available = false;
                angular.forEach(selectedStartTimes, function (startTime) {
                    if (option.startTimes.indexOf(startTime) > -1) {
                        option.available = true;
                    }
                });
            });
            $scope.segments.selected = $scope.segments.total.filter(function (segment) {
                return selectedStartTimes.indexOf(segment.startTime) > -1;
            });
        }

        function getMetadataOptions() {
            /*
            get copy of metadata map
             {
              'Character': {
                  'label': 'Characters',
                  'icon': 'person'
             }, etc...
            */
            mappedMetadata = MetadataMappingService.getMetadataMap();

            //don't need dialogue node
            delete mappedMetadata.dialogue;

            /*
             add 'options' property to each metadata map node
             'options' will contain the values from entire asset list
             {
                "Character" : {
                    "label" : "Characters",
                    "icon" : "person",
                    "options" : []
                }, etc...
            */
            angular.forEach(mappedMetadata, function (value, key) {
                mappedMetadata[key].options = [];
            });

            /*
             loop through assets array, pick out metadata bits and add them to 
             appropriate mappedMetadata options array:

             [{
		      "Actions" : "coffee drinking",
		      "seriesTitle" : "Modern Family",
              "Character" : ["Claire Dunphy", "Phil Dunphy", "Alex Dunphy", "Haley Dunphy"],
              "startTime" : "0", etc...
            
            
             {
	          "Character" : {
		          "label" : "Characters",
		          "icon" : "person",
		          "options" : [{
				    "icon" : "person",
			      	"label" : "Claire Dunphy",
			      	"startTimes" : ["0", "20.603916666667", "40.04"],
			      	"available" : true
                  }, etc..

            */
            angular.forEach($scope.metadata.total, function (metadata) {
                angular.forEach(metadata, function (values, key) {
                    var mapItem = mappedMetadata[key];
                    values = angular.isArray(values) ? values : [values];
                    if (mapItem) {
                        angular.forEach(values, function (value) {
                            var index = null;
                            var option = {
                                icon: mapItem.icon,
                                label: value,
                                startTimes: [metadata.startTime],
                                available: true,
                            };
                            angular.forEach(mapItem.options, function (o, i) {
                                if (o.label === value) {
                                    index = i;
                                }
                            });
                            if (index !== null) {
                                mapItem.options[index].startTimes.push(metadata.startTime);
                            } else {
                                mapItem.options.push(option);
                                totalOptions.push(option);
                            }
                        });
                    }
                });
            });
        }

        function createMetadataList() {
            if (!mappedMetadata) return;
            /*
             turns mappedMetadata object into array to bind to view:
            [{
		      "label" : "Characters",
		      "icon" : "person",
		      "options" : [{
			  	"icon" : "person",
			  	"label" : "Claire Dunphy",
			  	"startTimes" : ["0", "20.603916666667", "40.04"],
			  	"available" : true
                  }, etc...
            */
            angular.forEach(mappedMetadata, function (value, key) {
                value.key = key;
                $scope.metadataList.push(value);
            });
        }
    }
})();
