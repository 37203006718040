(function () {
    "use strict";

    angular.module('archie.core')
        .directive('textareaField', function(){
            return {
                'restrict': 'E',
                'template': '<div class="text-area-field md-body-1">{{value|arrayToList}}</div>',
                'scope': {
                    'value': '='
                }
            };
        });
})();