(function () {
    "use strict";

    angular.module('archie.core')
        .controller('GenSearchController', GenSearchController);

    function GenSearchController($scope, $mdPanel, $timeout, $document, $window, FacetService, SearchUtil, keyCodes){

        var searchTimer;
        var resizeTimer;
        var minTextLength = 3;
        var eventTimeout = 350;

        SearchUtil.setSearchText('');

        $scope.searchText = {
            'value': ''
        };

        $scope.menu = {
            'items': []
        };

        //reference to md-panel instance
        $scope.panelRef = null;

        $scope.showMenu = function() {

            // close menu if text input is empty
            if(!$scope.searchText.value) {
                $scope.closeMenu();
                $scope.menu.items = [];
                return;
            }

            //panel is already open, do nothing
            if($scope.panelRef) return;

            var position = $mdPanel.newPanelPosition()
                .relativeTo($scope.element.find('input')) // search text input
                .addPanelPosition($mdPanel.xPosition.ALIGN_START, $mdPanel.yPosition.BELOW);

            var config = {
                attachTo: $scope.element,
                controller: [
                    '$scope',
                    'searchText',
                    'menu',
                    'mdPanelRef',
                    'container',
                    function ($scope, searchText, menu, mdPanelRef, container) {
                        //pass along $scope properties to menu directive
                        $scope.searchText = searchText;
                        $scope.menu = menu;
                        $scope.panelRef = mdPanelRef;
                        $scope.container = container;
                    }],
                template: '<gen-search-menu search-text="searchText" menu="menu" panel-ref="panelRef" container="container"></gen-search-menu>',
                panelClass: 'gen-search-menu',
                position: position,
                clickOutsideToClose: true,
                escapeToClose: true,
                focusOnOpen: false,
                trapFocus: false,
                zIndex: 150,
                origin: $scope.element,
                locals: {
                    searchText: $scope.searchText,
                    menu: $scope.menu,
                    container: $scope.element
                },
                onRemoving: function(){
                    $scope.panelRef = null;
                    $document.off('click', handleClick);
                    angular.element($window).off('resize', handleResize);
                    if($scope.searchText.value && $scope.searchText.value.length < minTextLength) {
                        $scope.menu.items = [];
                    }
                }
            };

            $mdPanel.open(config).then(function ($mdPanelRef) {
                // save instance of md-panel
                $scope.panelRef = $mdPanelRef;
                $scope.hasShown = true;
                $document.on('click', handleClick);
                angular.element($window).on('resize', handleResize);
            });

        };

        $scope.closeMenu = function() {
            if(!$scope.panelRef) return false; // menu panel isn't opened
            return $scope.panelRef.close(); // returns a promise
        };

        $scope.clearSearch = function () {
            $scope.closeMenu();
            $scope.searchText.value = '';
            FacetService.clearSearchText();
        };

        $scope.typeSearch = function ($event) {

            //ignore UP/DOWN ARROW, TAB, and SHIFT keys
            if($event.which === keyCodes.ARROW_DOWN
                || $event.which === keyCodes.ARROW_UP
                || $event.which === keyCodes.TAB
                || $event.which === keyCodes.SHIFT) return;

            $timeout.cancel(searchTimer);

            if(!$scope.searchText.value) {
                $scope.clearSearch();
            }

            if($scope.searchText.value.length >= minTextLength) {
                searchTimer = $timeout(function () {
                    $scope.search();
                }, eventTimeout);
            } else {
                $scope.menu.items = [];
            }

        };

        // handle if text is cut/pasted into search input
        $scope.clipboardEvent = function ($event) {
            //$timeout ensures input text value has changed according to cut/paste result
            $timeout(function () {
                $scope.typeSearch($event);
            });
        };

        $scope.search = function () {
            if(!$scope.searchText.value) return;
            $scope.loading = true;
            $scope.menu.items = [];
            FacetService.getFacetsFromSearchText([], $scope.searchText.value).then(function (facetResponse) {
                $scope.menu.items = facetResponse.filter(function (facet) {
                    return facet.options.length > 0;
                });
            });
        };

        function handleClick($event) {
            var foundEl = $scope.element.find($event.target);
            if(foundEl.length < 1) {
                $scope.closeMenu();
            }
        }
        
        function handleResize() {
            $timeout.cancel(resizeTimer);
            // close menu panel on resize event
            var closedMenu = $scope.closeMenu();
            resizeTimer = $timeout(function () {
                // menu was opened, re-open it
                if(closedMenu) {
                    closedMenu.then(function () {
                        $scope.showMenu();
                    });
                }
            }, eventTimeout);
        }

    }



})();