(function () {
    "use strict";

    angular.module('archie.core')
        .directive('dialogueSearch', function() {
            return {
                'restrict': 'E',
                'replace': true,
                'templateUrl': 'layout/dialogue-search/dialogue-search.html',
                'controller': 'DialogueSearchController'
            }
        });

})();