// This is the base class for all Video.js controls, called "components".
const VideojsComponent = videojs.getComponent('Component');

class ControlSpacerComponent extends VideojsComponent {
    constructor(player, options) {
        super(player, options);
    }

    createEl() {
        const div = videojs.dom.createEl('div', {}, { class: 'vjs-control spacer' });
        return div;
    }
}

videojs.registerComponent('ControlSpacer', ControlSpacerComponent);
