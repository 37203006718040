(function (){
    "use strict";

    /**
     * UI component for setting the dialogue vs non-dialogue search option
     * 
     * Used and visible on Video search
     * 
     */
    angular.module('archie.core')
        .directive('dialogueSearchSelector', ['PreferenceUtilService', 'FacetService', '$mdDialog', '$location', 'SearchUtil', function(PreferenceUtilService, FacetService, $mdDialog, $location, SearchUtil){
            return {
                'restrict': 'E',
                'templateUrl': 'layout/toolbar/dialogue-search-selector/dialog-search-selector.html',
                'scope': {
                    'runSearch': '='
                },
                'controller': ['$scope', function($scope){
                    var confirmDialog = $mdDialog.confirm()
                        .htmlContent(
                            '<p class="md-body-1">One or more facet options are currently selected.</p>'
                            + '<p class="md-body-1">Switching between tag and dialogue search will clear the selected options.</p>'
                            + '<p class="md-body-1">Do you wish to continue?</p>')
                        .ok('Continue')
                        .cancel('Cancel');
                    // Set the initial value of the dialogue preference
                    $scope.dialogue = PreferenceUtilService.getDialogueSearch();

                    /**
                     * Computes the UI string to display the selected value next to the toggle
                     * @returns {string}
                     */
                    $scope.getDialogueText = function(){
                        $scope.dialogue = PreferenceUtilService.getDialogueSearch();
                        return $scope.dialogue ? 'Dialogue' : 'Tag';
                    };

                    /**
                     * Updates the preference when the user toggles the UI
                     */
                    $scope.updateDialoguePreference = function(){
                        if(FacetService.areOptionsSelected()){
                            $mdDialog.show(confirmDialog).then(function() {
                                // Update the dialogue value
                                $scope.dialogue = !$scope.dialogue;
                                // Update the user preference
                                updateUrl();
                            }, function() {
                                // Do nothing if the user cancelled the action
                            });
                        } else {
                            updateUrl();
                        }

                    };

                    /**
                     * Helper function for storing the dialogue search preference
                     */
                    function updateUrl(){
                        var path = $scope.dialogue ? 'dialogue' : 'tag';
                        if(SearchUtil.getSearchText()){
                            path += '/' + SearchUtil.getSearchText();
                        }
                        if($scope.runSearch !== false){
                            $location.path('video-' + path).search('');
                        } else {
                            PreferenceUtilService.setDialogueSearch($scope.dialogue);
                        }
                    }
                }]
            }
        }]);
})();