(function () {
    "use strict";

    /**
     * Sets number to fixed length by adding 0
     * Used on library pages to display related episodes
     * 3 -> 03
     */
    angular.module('archie.core')
        .filter('numFixedLen', function(){
            return function (n, len) {
                if(!angular.isDefined(n)) return '';
                len -= n.toString().length;
                if ( len > 0 )
                {
                    return new Array( len + (/\./.test( n ) ? 2 : 1) ).join( '0' ) + n;
                }
                return n + ''; // always return a string
            };
        });

})();