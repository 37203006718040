(function (){
    "use strict";

    /**
     * Application Values
     */
     angular.module('archie.core')
         .value('loginURL', {})
         .run(function (loginURL) {
	     console.log('Setting loginUrl.base');
             loginURL.base = ''; //Start with nothing, myid login options page will set this
         })
         .value('drm', {})
         .run(function (drm) {
             drm.videoEndpoint = appConfig.videoEndpoint;
             drm.licenseServerUrl = appConfig.licenseServerUrl;
         })
         .value('emails', {})
         .run(function (emails) {
             emails.feedback = {
                 'address': appConfig.feedBackEmail,
                 'subject': 'Content Request: Series, Tag, or Other',
                 'body': ''
             };
             emails.bugReport = {
                 'address': appConfig.feedBackEmail,
                 'subject': 'Bug Report',
                 'body': ''
             };
         })
         .value('facetSearchParams', {})
         .run(function(facetSearchParams) {
             // ensure this option is set to true unless it is explicitly set to false
             facetSearchParams.matchAllFacetOptions = appConfig.matchAllFacetOptions !== false;
        });
})();
