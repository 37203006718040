(function (){
    "use strict";

    // Preference Service
    angular.module('archie.core').factory('PreferenceUtilService', PreferenceUtilService);

    /**
     * PreferenceUtilService - description
     *
     */
    /* @ngInject */
    function PreferenceUtilService($window){

        var defaultCardLayout = 'List';
        var preface = 'archie:';

        return {
            setCardLayout: setCardLayout,
            getCardLayout: getCardLayout,
            setDialogueSearch: setDialogueSearch,
            getDialogueSearch: getDialogueSearch,
            get: get,
            set: set,
            remove: remove
        };

        function get(key) {
            return localStorage.getItem(preface + key);
        }

        function set(key, value) {
            localStorage.setItem(preface + key, value);
        }

        function remove(key) {
            localStorage.removeItem(preface + key);
        }

        /**
         * getCardLayout - Retrieve the card layout preference
         *
         * @return {string}  card layout
         */
        function getCardLayout(){
            // @TODO handle exception
            var cardLayout = get('cardLayout');
            if (!cardLayout) {
                return defaultCardLayout;
            } else {
                return cardLayout;
            }
        }

        /**
         * setCardLayout - Set card layout preference
         *
         * @param  {string} cardLayout card layout preference to set
         */
        function setCardLayout(cardLayout){
            // @TODO exception handling
            set('cardLayout', cardLayout);
        }

        /**
         * Stores the users preference for search dialogue vs non-dialogue
         * @param dialogueSearch
         */
        function setDialogueSearch(dialogueSearch){
            set('dialogueSearch', dialogueSearch);
        }

        /**
         * Gets the users preference for search dialogue vs non-dialogue
         * @param dialogueSearch
         */
        function getDialogueSearch(){
            var dialogueSearch = get('dialogueSearch') + '';
            //return dialogueSearch === 'true';
            // TODO: Always return true until tag search is enabled
            return true;
        }

    }

})();