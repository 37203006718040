(function () {
    'use strict';

    // Any facets or options not present here are assumed to have a whitelist of all facets
    // Each Facet is an object with an options object that contains whitelists for each option for the facet.
    // If the whitelist should apply to all options in the facet, the option name should be "all"
    angular.module('archie.core')
        .constant('FacetMocks',
            [
                {
                    "type":"hasProductionType",
                    "options": {
                        "Features": ["hasProductionType", "hasFeatureTitle", "Character", "Activities", "Events", "Location", "Topic", "hasSegmentType", "Format", "hasProductionCompany"]
                    }
                },
                {
                    "type":"hasFeatureTitle",
                    "options": {
                        "all": ["hasProductionType", "hasFeatureTitle", "Character", "Activities", "Events", "Location", "Topic", "hasSegmentType", "Format", "hasProductionCompany"]
                    }
                }
            ]
        );
})();