/**
 * Component Dependencies:
 *
 * MetadataViewButtonComponent
 */

/**
 * A plugin that places the MetadataViewButton component on the control bar.
 * @param options
 */
function metadataViewPlugin(options) {
    if (!options.config) return;
    var player = this;
    var config = options.config;
    var slot = 8;
    var metadataViewButton;

    // a spacer to place between metdataview button and rest of player controls
    const CustomControlSpacer = videojs.getComponent('CustomControlSpacer');
    class MetadataViewSpacer extends CustomControlSpacer {
        constructor() {
            super(player, options);
        }

        buildCSSClass() {
            var css = 'vjs-control spacer metadata-view-spacer ';
            return css + CustomControlSpacer.prototype.buildCSSClass.apply(this, arguments);
        }
    }

    videojs.registerComponent('metadataViewSpacer', MetadataViewSpacer);

    player.on('ready', function () {
        const controlBar = player.getChild('controlBar');
        controlBar.addChild('metadataViewSpacer', {}, slot);
        metadataViewButton = controlBar.addChild(
            'metadataViewButton',
            {
                showMsg: config.showMsg,
                hideMsg: config.hideMsg,
                disableMsg: config.disableMsg,
                opened: config.opened,
            },
            slot
        );
    });

    player.on('disableMetadataViewToggle', function () {
        metadataViewButton.disable();
    });

    // hide metadata view in fullscreen mode
    player.on('fullscreenchange', function () {
        if (player.isFullscreen_) {
            metadataViewButton.setOpened(false);
        }
    });
}

// Tells videojs about our custom "metadata view" plugin.
videojs.registerPlugin('metadataView', metadataViewPlugin);
