(function () {
    "use strict";

    angular.module('archie.core')
        .directive('listFieldItem', function(){
           return {
                'restrict': 'E',
                'replace': false,
                'templateUrl': 'library/fields/list-field/list-field-item.html',
                'scope': {
                    'item': '='
                }
            };
        });
})();