// Buttons are so common in video.js that they have a special base class that makes implementing them easier.
const ButtonComponent = videojs.getComponent('Button');

/**
 * @typedef {Object} RestartLoopButtonComponentOptions
 * @property {Number} start - The time to start
 */
/**
 * This button will set the current time to the given start time whenever it is clicked.
 */
class RestartLoopButtonComponent extends ButtonComponent {
    /**
     * Creates the button and sets the button's tooltip to 'Restart segment'.
     * @param {Player} player - The video.js Player instance this component is bound to.
     * @param {RestartLoopButtonComponentOptions} options - The options this component will be created with.
     */
    constructor(player, options) {
        super(player, options);
        this.controlText('Restart segment');
    }
    /**
     * Sets the CSS classes that this button will have.
     * @returns {string}
     */
    buildCSSClass() {
        return 'restart-loop ' + ButtonComponent.prototype.buildCSSClass.apply(this, arguments);
    }
    /**
     * The 'onclick' event handler for this plugin, which sets the video player's current time to the given "start"
     * time.
     */
    handleClick() {
        this.player_.currentTime(this.options_.start);
    }
}

// Tells videojs about our custom "restart loop button" component.
videojs.registerComponent('RestartLoopButton', RestartLoopButtonComponent);
