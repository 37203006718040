(function () {
    "use strict";

    angular.module('archie.core')
        .directive('metadataSelect', MetadataSelectDirective);

    function MetadataSelectDirective() {
        return {
            'restrict': 'E',
            'replace': true,
            'templateUrl': 'video-dialog/metadata-select/metadata-select.html',
            'scope': {
                'metadata': '=',
                'segments': '='
            },
            'controller': 'MetadataSelectController',
            'link': function (scope) {
                scope.init();
            }
        }
    }


})();