(function () {
    "use strict";

    angular.module('archie.core')
        .directive('tabRelatedContent', function(){
            return {
                'restrict': 'E',
                'replace': true,
                'templateUrl': 'library/tab-related-content/tab-related-content.html'
            };
        });
})();