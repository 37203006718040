(function () {
    "use strict";

    angular.module('archie.core')
        .controller('tabContentsController', ['$scope', 'FeedbackService', function($scope, FeedbackService) {
            $scope.feedback = FeedbackService.sendFeedback();
            // select proper verb depending on tab label (eg '*are* no storylines', '*is* no series info')
            $scope.verb = 'is';
            if($scope.tabLabel.split('').reverse()[0].toLowerCase() === 's') {
                $scope.verb = 'are';
            }
            // if optional hideEmptyValues is true, remove any items from properties array that have no value
            // if all array items end up being removed, the friendly no data message will be displayed.
            $scope.$watch('properties', function(newVal) {
                if($scope.hideEmptyValues && angular.isArray($scope.properties)) {
                    var i = $scope.properties.length;
                    while (i > 0) {
                        if(!$scope.properties[i-1].value) {
                            $scope.properties.splice(i-1, 1);
                        }
                        i--;
                    }
                }
            });
        }])
        .directive('tabContents', function () {
            return {
                'restrict': 'E',
                'transclude': true,
                'replace': true,
                'templateUrl': 'library/tab-contents/tab-contents.html',
                'controller': 'tabContentsController',
                'scope': {
                    'tabLabel': '=',
                    'assetLabel': '=',
                    'loading': '=',
                    'properties': '=',
                    'hideEmptyValues': '=?'
                }
            };
        });
})();