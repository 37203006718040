// Buttons are so common in video.js that they have a special base class that makes implementing them easier.
const ButtonComponent = videojs.getComponent('Button');

/**
 * A component which creates a "Share" icon button on the control bar, clicking exposes the CopyUrlComponent
 */
class ShareButtonComponent extends ButtonComponent {
    constructor(player, options) {
        super(player, options);
        this.controlText('Share');
    }

    buildCSSClass() {
        return 'share-link ' + ButtonComponent.prototype.buildCSSClass.apply(this, arguments);
    }

    handleClick() {
        this.player_.trigger('shareurl');
    }
}

videojs.registerComponent('shareButton', ShareButtonComponent);
