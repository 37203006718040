(function (){
    "use strict";

    /**
     * Search Controller (Initial Data with Optional Search Text)
     */
    angular.module('archie.core').factory('SearchControllerInitialDataSearchText', SearchControllerInitialDataSearchText);
    /**
     * SearchControllerInitialDataSearchText - Initial Data with Optional Search Text
     * @param  {Array} $rootScope   Root Scope
     * @param  {AssetService} AssetService Asset Service (for fetching assets)
     * @param  {FacetService} FacetService Facet Service (for processing selected facets)
     * @return {Array}              Initial array of assets
     */
    /* @ngInject */
    function SearchControllerInitialDataSearchText($rootScope, AssetService, FacetService, $location,
        SearchUtil, PreferenceUtilService, OmnitureService){

        return function (searchText, isDialogue){

            PreferenceUtilService.setDialogueSearch(isDialogue);
            // Decode the search text since it is being pulled from the URI
            searchText = decodeURIComponent(searchText || '');
            //$rootScope.limit = 20;

            var urlParams = $location.search();
            // $rootScope.offset = +urlParams.offset || 0;
            //
            // $rootScope.searchText = searchText || '';

            //Math.abs + parseInt to prevent negative values and fractions
            SearchUtil.setOffset(Math.abs(parseInt(urlParams.offset)) || 0);
            SearchUtil.setLimit(20);
            SearchUtil.setSearchText(searchText || '');

            //Load from Querystring
            FacetService.setSelectedOptionsByParams();


            var availableFacets = [];
            angular.forEach(urlParams, function(options, facetId){
                if(facetId === 'sort' || facetId === 'offset' || facetId === 'openAsset') return;
                options = angular.isArray(options) ? options : [options];
                availableFacets.push({
                    "urlParam": facetId,
                    "checkbox": true,
                    "isEnabled": true,
                    "isExpanded": true,
                    "availableOptions": options.map(function(optionName){return {'optionName': optionName, 'apiName': optionName, 'isSelected': true}; }),
                    "nameSpace": facetId,
                    "id": facetId,
                    "selectedOptions": options
                });
            });

            var optionParams = FacetService.getOptionParams();
            var staticFacets = FacetService.getStaticFacets();
            var params = SearchUtil.prepareParamsAndCollectionPath(availableFacets, staticFacets, optionParams, 'segment');

            //console.log(params);

            return AssetService.getAssetStreamByX(params)
                .then(function (assetsResponse){
                    FacetService.setFacets(assetsResponse.facets);
                    OmnitureService.searchPageView();
                    OmnitureService.logFacetSearch();
                    return assetsResponse;
                }).catch(function (errorResponse){
                    return [];
                });
        }
    }

    angular.module('archie.core').factory('SearchControllerOpenAsset', SearchControllerOpenAsset);
    function SearchControllerOpenAsset($location, $timeout, AssetService, MessageConsole, VideoDialog) {
        return function () {
            // param for video deeplinking
            var openAsset = $location.search().openAsset;
            var messageData = {
                'msg': 'Video could not be opened. Invalid video clip URL.',
                'type': 'e'
            };
            // no param exists
            if(!openAsset) return null;
            // handle error in case url is altered
            try {
                openAsset = JSON.parse(atob(openAsset));
            } catch(e) {
                $timeout(function () {
                    MessageConsole.displayMessage(messageData);
                    VideoDialog.removeOpenAssetParam();
                }, 500);
                return null;
            }
            return AssetService.getAsset(openAsset.id).then(function (asset) {
                    asset.startTime = openAsset.startTime;
                    asset.endTime = openAsset.endTime;
                    return asset;
                }).catch(function (errorResponse){
                    VideoDialog.removeOpenAssetParam();
                    return null;
                });
        };
    }

//  Search Controller
    angular.module('archie.core').controller('SearchController', SearchController);
    /**
     * SearchController - description
     *
     * @param  {scope} $scope       Scope
     * @param  {mdDialog} $mdDialog    Dialog box (for asset actions)
     * @param  {Array} assets       list of assets
     * @param  {Array} $routeParams Route Params (for gathering search text)
     * @param  {rootScope} $rootScope   Root Scope
     * @param  {FacetService} FacetService Facet Service (for processing selected assets)
     */
    /* @ngInject */
    function SearchController($scope, openAsset, assets, $routeParams, $rootScope, FacetService, $mdSidenav, $location,
                              PageTitleService, SearchUtil, VideoDialog, AuthService){
        var pageTitle = 'Search';
        $scope.availableFacets = assets.facets;
        $scope.assets = assets.assets || [];
        $scope.assets.forEach(function (asset) {
            //add auth query param with MyID token to thumbnail URL
            if(asset.assetThumbnail && asset.assetThumbnail.url) {
                asset.assetThumbnail.url = AuthService.appendUrl(asset.assetThumbnail.url);
            }
        });
        // Decode the search text since it is being pulled from the URI
        $scope.searchText = decodeURIComponent($routeParams.searchText || '');
        SearchUtil.setSearchText($scope.searchText);
        if($scope.searchText) pageTitle += (' "' + $scope.searchText + '"');

        $rootScope.assetCount = assets.total || 0;

        PageTitleService.updateTitle(pageTitle);

        // url contains video deeplink, open video.
        if(openAsset) {
            var dialogTitle = '';
            if(openAsset.seriesTitle) {
                dialogTitle += openAsset.seriesTitle + ': ';
            }
            dialogTitle += openAsset.title;
            if(!openAsset.assetThumbnail) {
                openAsset.assetThumbnail = {};
            }
            VideoDialog.viewVideo(openAsset, 'searchResult', dialogTitle, openAsset.assetThumbnail.url, true);
        }

        /**
         * Run search for assets
         */
        $scope.search = function (){
            $scope.offset = 0;
            SearchUtil.setOffset($scope.offset);
            $location.search('offset', $scope.offset);
        };

        /**
         * Update available facets based upon what's clicked (e.g. video results in video metadata facet)
         */
        $scope.facetClicked = function (index){
            var facetCount = FacetService.getFacetCount();
            for (var i = 0; i < facetCount; i++) {
                if (i != index) {
                    FacetService.setExpandedFacet(i, false);
                } else {
                    FacetService.setExpandedFacet(i, true);
                }
            }
        };

        $scope.showFacets = function (){
            $mdSidenav('facet-side-nav')
                .toggle();
        };

        $scope.$on('showFacets', function (event){
            $scope.showFacets();
        });

        // update available facets array (when facet search is run)
        $scope.$watch(function () {
            return FacetService.getAvailableFacets();
        }, function (availableFacets) {
            $scope.availableFacets = availableFacets;
        });

    }

})();