(function () {
    "use strict";
    // Directive for displaying a search entry as a card in a grid
    angular.module('archie.core')
        .directive('gridCard', function(){
            return {
                'restrict': 'E',
                'replace': true,
                'controller': 'CardController',
                'templateUrl': 'layout/cards/grid/grid-card.html',
                'link': function(scope, elem, attrs){
                    // Store a timeout for hover events
                    scope.hoverTimeout = null;
                    // Store the base width for handling collapsing of the card
                    scope.width = elem.width();
                    // Set a timeout when the user mouses over a card that will cause the card to expand
                    elem.on('mouseenter', function(e){
                        // Clear an existing timeout
                        clearTimeout(scope.hoverTimeout);
                        // Set the new timeout to .8 seconds
                        scope.hoverTimeout = setTimeout(hoverEffect.bind(this, e), 800);
                    });

                    // Collapse the grid after mousing away from it
                    elem.on('mouseleave', function(e){
                        scope.collapseMetadata(e);
                        scope.$digest();
                    });

                    // Clear mouse events when the element is destroyed
                    elem.on('$destroy', function(){
                        elem.off('mouseenter')
                            .off('mouseleave');
                    });

                    /**
                     * Expands the card to display additional metadata after a hover timeout
                     * @param e
                     */
                    function hoverEffect(e){
                        scope.expandMetadata(e);
                        scope.$digest();
                    }
                }
            };
        });
})();