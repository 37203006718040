(function (){
    "use strict";

    // Current User Service
    angular.module('archie.core').factory('UserService', UserService);

    /**
     *
     * @param {PreferenceUtilService} PreferenceUtilService
     * @returns {{profile: Object, setProfile: setProfile, removeProfile: removeProfile}}
     */
    function UserService(PreferenceUtilService) {

        var profile = initProfile();

        return {
            profile: profile,
            initProfile: initProfile,
            setProfile: setProfile,
            setUsername: setUsername,
            setEmail: setEmail,
            setToken: setToken,
            removeProfile: removeProfile
        };

        /**
         * @param {string} username constant defined in config
         * @param {string} token constant defined in config
         * @param {string} email constant defined in config
         */
        function setProfile(username, token, email) {
            setUsername(username);
            setToken(token);
            setEmail(email);
        }

        /**
         * @param {string} username
         */
        function setUsername(username) {
            // save username profile property
            profile.username = username;
        }

        /**
         * @param {string} email
         */
        function setEmail(email) {
            // save email profile property
            profile.email = email;
        }

        /**
         * @param {string} token
         */
        function setToken(token) {
            // save token profile property
            profile.token = token;
            // save token to localStorage
            PreferenceUtilService.set(appConfig.tokenKey, token);
        }

        function removeProfile() {
            // empty profile properties
            setProfile('', '', '');
            // delete localStorage values
            PreferenceUtilService.remove(appConfig.tokenKey);
        }

        /**
         * @returns {Object}
         */
        function initProfile() {
            var user = {};
            // get username & token from localStorage, or set to empty
            user.username = '';
            user.email = '';
            user.token = PreferenceUtilService.get(appConfig.tokenKey) || '';
            return user;
        }

    }

})();