(function (){
    "use strict";

    // Card Set Controller
    angular.module('archie.core').controller('CardSetController', CardSetController);

    /* @ngInject */
    function CardSetController($scope, $rootScope, PreferenceUtilService, FacetService, $location, $log, $timeout, $window, SearchUtil, $route, icons){
        var resizeTimeout = null;
        $scope.chosenFacets = [];
        $scope.showSortMenu = false;

        $scope.availableFacets = $scope.availableFacets || [];

        $scope.assetCount = $rootScope.assetCount;
        $scope.maxCount = 10000;
        $scope.offset = SearchUtil.getOffset();
        $scope.limit = SearchUtil.getLimit();
        $scope.searchText = SearchUtil.getSearchText();
        $scope.icons = icons;
        $scope.shareUrl = $location.$$absUrl;

        // set card set layout
        $scope.cardLayout = $scope.cardLayout || PreferenceUtilService.getCardLayout();
        $rootScope.$on('UPDATE_LAYOUT', function (){
            $scope.cardLayout = PreferenceUtilService.getCardLayout();
        });

        if (($scope.offset + $scope.limit) < $scope.assetCount) {
            $scope.showMoreButton = true;
        } else {
            $scope.showMoreButton = false;
        }

        function masonryResize(){
            var $grid = $('#masonry-tiles');
            $grid.masonry();
            $grid.masonry('reloadItems');
            $grid.masonry();
        }

        $scope.$watch('assets', function(){
            redrawGrid();
        });


        $scope.$on('$destroy', function(){
            $window.removeEventListener('resize', redrawGrid);
        });

        function redrawGrid(){
            if(resizeTimeout) $timeout.cancel(resizeTimeout);
            resizeTimeout = $timeout(masonryResize, 200);
            resizeTimeout.finally(function(){
                resizeTimeout = null;
            });
        }

        $scope.sortFacet = FacetService.getStaticFacets()[0];
        FacetService.setSelectedOptionsByParams();

        angular.forEach($scope.sortFacet.availableOptions, function (option){
            if (option.isSelected) {
                $scope.sortOption = option;
            }
        });

        $scope.availableFacets = FacetService.getAvailableFacets();
        $scope.optionsSelected = FacetService.areOptionsSelected();

        $scope.clearSearch = SearchUtil.clearSearch;

        $scope.clearDialogueSearch = function() {
            $scope.searchText = '';
            SearchUtil.clearDialogueSearch();
        };

        $scope.getViewCount = function (){
            return Math.min(($scope.offset + $scope.limit) || 0, $scope.assetCount || 0);
        };

        $scope.changeOption = function (sortOption){
            var searchHash = $location.search();
            searchHash['offset'] = 0;
            for (var i = 0, len = $scope.sortFacet.availableOptions.length; i < len; i++) {
                $scope.sortFacet.availableOptions[i].isSelected = false;
            }
            sortOption.isSelected = true;
            $scope.sortOption = sortOption;
            searchHash[$scope.sortFacet.urlParam] = FacetService.getSelectedOptionApiNameByFacetName($scope.sortFacet.name);
            $location.search(searchHash);
        };

        $scope.removeOption = function (facet, option){
            //console.log("facet", facet);
            //console.log("option", option);
            var searchHash = $location.search();
            searchHash['offset'] = 0;
            FacetService.removeOption(facet, option);
            searchHash[facet.urlParam] = FacetService.getSelectedOptionApiNameByFacetName(facet.name);
            //console.log("searchHash", searchHash);
            $location.search(searchHash);
        };

        $scope.updateLayout = function (layoutType){
            $log.debug("Set Layout: " + layoutType);
            PreferenceUtilService.setCardLayout(layoutType);
            $rootScope.$emit('UPDATE_LAYOUT');

            if(layoutType === 'Grid' || layoutType === 'SimpleGrid'){
                $timeout(masonryResize);

                $window.addEventListener('resize', redrawGrid);
            } else {
                $window.removeEventListener('resize', redrawGrid);
            }
        };

        $scope.openFacets = function (){
            $scope.$emit('showFacets');
        };

        $scope.closeSortMenu = function (){
            $scope.showSortMenu = false;
        };

        /**
         * Paging function
         */
        $scope.pagingFunction = function (backward){
            if (backward) {
                $scope.offset = $scope.offset - $scope.limit;
            } else {
                $scope.offset = $scope.offset + $scope.limit;
            }
            SearchUtil.setOffset($scope.offset);
            $location.search('offset', $scope.offset);
            $rootScope.showMoreButton = false;
        };

        $scope.goToPage = function (pageNumber){
            var currentPage = ($scope.offset / $scope.limit) + 1;
            // Cancel the search if they are navigating to the page they are currently on
            if(currentPage === pageNumber) return;
            if (pageNumber === 'end') {
                $scope.offset = Math.floor($scope.assetCount / $scope.limit) * $scope.limit;
                if($scope.offset === $scope.assetCount){
                    $scope.offset = $scope.offset - $scope.limit;
                }
            } else {
                $scope.offset = (pageNumber - 1) * $scope.limit;
            }
            SearchUtil.setOffset($scope.offset);
            $location.search('offset', $scope.offset);
            $rootScope.showMoreButton = false;
        };

        $scope.getPages = function (){
            var pages = [];
            var currentPage = ($scope.offset / $scope.limit) + 1;
            // Find the maximum number of pages that could exist in the result set
            var maxPages = Math.ceil($scope.assetCount / $scope.limit);
            //
            var minPage = Math.max(1, currentPage - 2);

            // Don't let the maxPage number be beyond the maximum amount of possible pages
            // Math.floor to prevent fraction if user mucks with offset url param
            var maxPage = Math.floor(Math.min(minPage + 4, maxPages));

            // Ensure that the minimum amount of page number shown is 5, but do not show negative page numbers
            // Math.floor to prevent fraction if user mucks with offset url param
            minPage = Math.floor(Math.max(Math.min(maxPages - 4, minPage), 1));

            for (var i = minPage; i <= maxPage; i++) {
                pages.push(i);
            }
            return pages;
        };

        $scope.isCurrentPage = function (pageNumber){
            var current = $scope.limit * (pageNumber - 1);
            var nextPage = $scope.limit * pageNumber;
            // if user alters offset url param to value that is not a multiple of limit
            // have to choose which pagination button to highlight
            return $scope.offset >= current && $scope.offset < nextPage;
        };

        $scope.noResults = function(){
            return $scope.assetCount === 0 || $scope.assets.length === 0;
        };

        $scope.showLandingText = function () {
            var params = angular.copy($route.current.params);
            var staticFacets = FacetService.getStaticFacets();
            delete params['offset'];
            delete params['openAsset'];
            angular.forEach(staticFacets, function (facet) {
                delete params[facet.urlParam];
            });
            angular.forEach(params, function (value, key) {
                if(!value || !value.length)
                    delete params[key];
            });
            return angular.equals(params, {});
        };

    }

})();