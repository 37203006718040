(function (){
    "use strict";

    /**
     * Created by POKAS002 on 1/19/2016.
     */
// Card Set Controller
    angular.module('archie.core').controller('CardFilterFacetController', CardFilterFacetController);

    /* @ngInject */
    function CardFilterFacetController($scope, $filter, orderByFilter, FacetService, $location, OmnitureService, FacetSearchDialog){
        $scope.maxNumberOfOptionsForCountUpdate = 999999;
        $scope.visibleOptions = 6;
        $scope.optionsOnOnePage = 25;
        $scope.optionsLength = 0;
        $scope.searchOptionText = "";
        $scope.facet.availableOptions = $scope.facet.availableOptions || [];
        $scope.facet.selectedOptions = $scope.facet.selectedOptions || [];
        $scope.searchText = FacetService.getSearchText();

        $scope.init = function(){
            $scope.optionsLength = $scope.facet.availableOptions.length;
            $scope.updateFacetOptions();
        };

        function getNumberOfLastPossiblyShowedOption(){
            var lastShowedOptionNumber = 0;
            for (var i = 0; i < $scope.facet.availableOptions.length; i++) {
                if ($scope.facet.availableOptions[i].show) {
                    lastShowedOptionNumber = i;
                }
            }
            return lastShowedOptionNumber;
        }

        function getNumberOfSelectedShowedOptions(){
            var selectedOption = 0;
            angular.forEach($scope.facet.availableOptions, function (option){
                if (option.isSelected && option.show) {
                    selectedOption++;
                }
            });
            return selectedOption;
        }

        function getNumberOfLastSelectedOption(){
            var lastSelectedOptionNumber = 0;
            for (var i = 0; i < $scope.facet.availableOptions.length; i++) {
                if ($scope.facet.availableOptions[i].isSelected) {
                    lastSelectedOptionNumber = i;
                }
            }
            return lastSelectedOptionNumber;
        }

        function getNumberOfLastShowedOption(){
            var selectedOptionNumbers = getNumberOfSelectedShowedOptions();
            var lastSelectedOptionNumber = getNumberOfLastSelectedOption();

            if ($scope.visibleOptions <= selectedOptionNumbers) {
                return lastSelectedOptionNumber;
            }

            var showedOptionsCount = 0;
            var lastShowedOptionNumber = lastSelectedOptionNumber;

            for (var i = lastSelectedOptionNumber + 1; i < $scope.facet.availableOptions.length; i++) {
                if ($scope.facet.availableOptions[i].show) {
                    lastShowedOptionNumber = i;
                    showedOptionsCount++;
                    if (showedOptionsCount == $scope.visibleOptions - selectedOptionNumbers) {
                        return lastShowedOptionNumber;
                    }
                }
            }
            return lastShowedOptionNumber;
        }

        $scope.onCheckBoxChange = function (facet, option){
            if(option.isSelected) {
                // record checked facet in Omniture
                OmnitureService.facetSearch(facet.name, option.optionName);
            }
            updateSearch();
        };

        $scope.clearOptions = function(){
            for (var i = 0, len = $scope.facet.availableOptions.length; i < len; i++) {
                $scope.facet.availableOptions[i].isSelected = false;
            }
            updateSearch();
        };

        $scope.searchFacetCallback = function (updatedOptions) {
            var newlySelectedOptions = [];
            angular.forEach(updatedOptions, function (updatedOption) {
                var foundOption = $scope.facet.availableOptions.filter(function (option) {
                   return option.optionName === updatedOption.optionName;
                })[0];
                if(!foundOption) {
                    // facet was not returned by original request due to facet limit, need to add it
                    var optionToAdd = angular.copy(updatedOption);
                    optionToAdd.show = true;
                    if(updatedOption.isSelected) {
                        newlySelectedOptions.push(optionToAdd.optionName);
                    }
                    // add new facet option to array from original request
                    $scope.facet.availableOptions.unshift(optionToAdd);
                } else {
                    // was returned by original request, so use it
                    if(updatedOption.isSelected && !foundOption.isSelected) {
                        newlySelectedOptions.push(foundOption.optionName);
                    }
                    foundOption.isSelected = updatedOption.isSelected;
                }
            });
            if(newlySelectedOptions.length > 0) {
                OmnitureService.facetSearch($scope.facet.name, newlySelectedOptions);
            }
            updateSearch();
        };

        $scope.searchFacet = function () {
            FacetSearchDialog.showDialog($scope.facet, $scope.searchFacetCallback);
        };

        $scope.increaseNumberOfVisibleOptions = function (){
            if($scope.optionsLength > $scope.optionsOnOnePage) {
                $scope.visibleOptions = $scope.visibleOptions + $scope.optionsOnOnePage;
            } else {
                $scope.visibleOptions = $scope.optionsLength;
            }
            $scope.updateFacetOptions();
        };

        $scope.updateFacetOptions = function (){
            if(!$scope.facet) return;

            switch ($scope.facet.sort[0]) {
                case 'alpha':
                    $filter('alphanumericSort')($scope.facet.availableOptions, false, 'optionName');
                    break;
                case 'numeric':
                    $filter('alphanumericSort')($scope.facet.availableOptions, true, 'optionName');
                    break;
                default:
                    // order by optionSize property (Descending)
                    // optionSize = Number of results from current search set that contain the option.
                    $scope.facet.availableOptions = orderByFilter($scope.facet.availableOptions, 'optionSize', true);
                    break;
            }

            // Always show selected options at top
            $scope.facet.availableOptions = orderByFilter($scope.facet.availableOptions, function (op1){
                return !(op1.isSelected);
            });

            angular.forEach($scope.facet.availableOptions, function (option){
                if ((option.optionSize > 0 || option.isSelected)
                    && (option.optionName.toLowerCase().indexOf($scope.searchOptionText.toLowerCase()) > -1)) {
                    option.show = true;
                } else {
                    option.show = false;
                }
            });

            $scope.possiblyShowedOption = getNumberOfLastPossiblyShowedOption();
            $scope.showedItems = getNumberOfLastShowedOption();
        };

        function updateSearch(){
            var searchHash = $location.search();
            searchHash['offset'] = 0;
            // Filter the set of available options down to only those selected
            // Then map each selected option down to just the option names
            // Then set the array of option names into the search hash map with the facet urlParam as the key
            searchHash[$scope.facet.urlParam] = $scope.facet.availableOptions.filter(function(f){return f.isSelected;}).map(function(f){return f.optionName;});
            $location.search(searchHash);
        }

        $scope.init();
    }
})();