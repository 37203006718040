(function () {
    "use strict";

    /**
     * @param {Object} obj -> {name:'joe', age:23, job:'clerk'}
     * @returns {string} -> 'name, age, job'
     */
    angular.module('archie.core')
        .filter('objectKeyToList', function(){
            return function(obj){
                if(!angular.isObject(obj)) return obj;
                return Object.keys(obj).join(', ');
            };
        });
})();