(function () {
    "use strict";

    angular.module('archie.core')
        .directive('searchInput', SearchInputDirective);

    function SearchInputDirective() {
        return {
            'restrict': 'E',
            'replace': true,
            'templateUrl': 'layout/search-input/search-input.html',
            'scope': {
                'changeFn': '&?',
                'clearFn': '&?',
                'keyupFn': '&?',
                'clickFn': '&?',
                'searchText': '=',
                'value': '=?',
                'isAutoFocus': '=?',
                'focusOnProp': '=?',
                'placeholder': '@',
                'btnIcon': '@?',
                'btnTooltip': '@?',
                'spacerBefore': '@?',
                'spacerAfter': '@?'
            }
        }
    }


})();