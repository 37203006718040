(function (){
    "use strict";
    // Http Wrapper Service
    angular.module('archie.core').factory('errorInterceptor', ['$q', '$rootScope', '$injector', '$window', function($q, $rootScope, $injector, $window){
            function responseError(rejection){
                //console.log("errorInterceptor", rejection);

                var authService = $injector.get('AuthService');
                var messageConsole = $injector.get('MessageConsole');

                // if 401, not logged in, launch login dialog, don't display error message
                if(rejection.status == 401 && !appConfig.disableAuth) {
                    //removes ui so there's no perceived lag until redirect:
                    authService.setAuthenticated(false);
                    //authService.login();

		    sessionStorage.setItem('lastAttemptedHref', $window.location.href);

		    sessionStorage.removeItem('authSystem');
                    window.location.href = '/#/myidoptions';

                    return $q.reject(rejection);
                }

                rejection.data = rejection.data || {};
                var errorMessage = rejection.data.reason;
                if(rejection.status === -1){
                    errorMessage = 'Resource could not be found.'
                }
                var messageData = {
                    'msg': errorMessage || 'The Genome service has run into an error.',
                    'type': 'e',
                    'delay': 6000
                };
                messageConsole.displayMessage(messageData);

                return $q.reject(rejection);
            }
            return {
                'responseError': responseError
            };
        }])
        .config(['$httpProvider', function($httpProvider){
            $httpProvider.interceptors.push('errorInterceptor');
        }]);
})();
