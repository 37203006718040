(function () {
    "use strict";

    angular.module('archie.core')
        .directive('field', function(){

            var tpl = [
                '<div class="field" layout="column" flex="">',
                    '<div flex="">',
                        '<div class="ellipsis fieldLabel md-body-2">{{label}}</div>',
                        '<div class="fieldContainer" ng-switch="type">',
                            '<chip-field value="value" ng-switch-when="chip"></chip-field>',
                            '<textarea-field value="value" ng-switch-when="textarea"></textarea-field>',
                        '</div>',
                    '</div>',
                    '<md-divider></md-divider>',
                '</div>'
            ];
            tpl = tpl.join('');

            return {
                'restrict': 'E',
                'replace': true,
                'template': tpl,
                'scope': {
                    'label': '=',
                    'value': '=',
                    'type': '='
                }
            };
        });
})();