(function () {
    'use strict';
    angular
        .module('archie.core')
        .directive('backLink', function () {
            var tpl = [
                '<div ng-show="lastSearch.path" class="back-link">',
                '<md-button class="md-primary" ng-href="#{{lastSearch.path}}" sp-track-button-click="Back To">',
                '<md-icon>navigate_before</md-icon>',
                'Back to {{lastSearch.label}}',
                '</md-button>',
                '</div>',
            ];
            tpl = tpl.join('');

            return {
                restrict: 'E',
                replace: true,
                template: tpl,
                controller: 'BackLinkController',
            };
        })
        .controller('BackLinkController', function ($scope, HistoryService) {
            $scope.lastSearch = HistoryService.getLastSearch();
        });
})();
