(function () {
    "use strict";

    angular.module('archie.core')
        .directive('toolbar', function() {
            return {
                'restrict': 'E',
                'replace': true,
                'templateUrl': 'layout/toolbar/toolbar.html',
                'controller': 'ToolbarController'
            }
        });

})();