(function () {
    'use strict';

    var archie = angular.module('archie');

    /**
     * Application Routes
     */
    archie.config([
        '$routeProvider',
        function ($routeProvider) {
            $routeProvider
                .when('/video-dialogue', {
                    templateUrl: 'search/search.html',
                    controller: 'SearchController',
                    details: {
                        type: 'search',
                        label: 'Video Search Results',
                    },
                    resolve: {
                        openAsset: function (SearchControllerOpenAsset) {
                            return SearchControllerOpenAsset();
                        },
                        assets: function ($route, SearchControllerInitialDataSearchText) {
                            return SearchControllerInitialDataSearchText(null, true);
                        },
                    },
                })
                .when('/video-dialogue/:searchText', {
                    templateUrl: 'search/search.html',
                    controller: 'SearchController',
                    details: {
                        type: 'search',
                        label: 'Video Search Results',
                    },
                    resolve: {
                        openAsset: function (SearchControllerOpenAsset) {
                            return SearchControllerOpenAsset();
                        },
                        assets: function ($route, SearchControllerInitialDataSearchText) {
                            return SearchControllerInitialDataSearchText(
                                $route.current.params.searchText,
                                true
                            );
                        },
                    },
                })
                .when('/library/:assetType/:id', {
                    templateUrl: 'library/creative-work.html',
                    controller: 'CreativeWorkController',
                    resolve: {
                        openAsset: function (CreativeWorkControllerOpenAsset) {
                            return CreativeWorkControllerOpenAsset();
                        },
                        asset: function ($route, CreativeWorkControllerInitialData) {
                            var assetType = $route.current.params.assetType;
                            var id = $route.current.params.id;
                            return CreativeWorkControllerInitialData(assetType, id);
                        },
                        assetType: function ($route) {
                            return $route.current.params.assetType;
                        },
                        id: function ($route) {
                            return $route.current.params.id;
                        },
                    },
                })
                .when('/myidoptions', {
                    templateUrl: 'myidoptions/myidoptions.html',
                    controller: 'MyidoptionsController',
                })
                .otherwise({
                    //redirectTo: '/video-dialogue'
                    redirectTo: '/myidoptions',
                });
            /**
                 * NOTE: save for adding single search page back when library search is completed.
                .when('/', {
                    templateUrl: 'landing/landing.html',
                    controller: 'LandingController'
                }).otherwise({
                    redirectTo: '/'
                });
                */
        },
    ]);

    archie.run(function ($rootScope, HistoryService) {
        $rootScope.$on('$routeChangeStart', function (_event, _next, current) {
            // For omniture stats, need to know if next route is page user is entering site on
            // Using $rootScope instead of OmnitureService because injecting any dependencies that rely on
            // $location or $window here breaks the app.
            $rootScope.isEntryPage = !current;
            HistoryService.addRoute(current);
            $rootScope.showTabs = true;
            // DO NO REMOVE ITEMS FROM TEMPLATE CACHE!!!
            // if (typeof(current) !== 'undefined') {
            //   $templateCache.remove(current.templateUrl);
            // }
        });
        $rootScope.$on('$routeChangeSuccess', function (_event, next, _current) {
            if (!next.$$route) return;
            //TODO: temporary fix: hide search box on series detail page until Library search is added
            var details = next.$$route.details || {};
            $rootScope.showSearch = details.type === 'search';
            if (window.snowplowVendor) {
                window.snowplowVendor.firePageView({
                    // There is an additional url field that is automatically captured
                    // $location.url() probably will duplicate url property in payload
                    title: next.$$route.originalPath,
                });
            }
        });
        $rootScope.$on('$routeChangeError', function (event, current, previous, rejection) {
            console.log('$routeChangeError', { event, current, previous, rejection });
            // The route failed to load, so go back to the previous view
            // window.history.back();
        });
    });
})();
