(function (){
    "use strict";

    // Http Wrapper Service
    angular.module('archie.core').factory('HttpWrapperService', HttpWrapperService);

    /**
     * HttpWrapperService - Http Wrapper Service
     *
     * @param  {http} $http               http (handles requests to REST service)
     * @param  {rootScope} $rootScope      $rootScope
     * @param  {service} UserService       User Service
     */
    /* @ngInject */
    function HttpWrapperService($http, $rootScope, $filter, UserService){
        var requestsInProgress = 0;
        return {
            get: get,
            put: put,
            post: post,
            request: request
        };

        /**
         * @param settings
         * @returns {*}
         */
        function setSettings(settings) {
            if (!angular.isObject(settings))
                settings = {};
            if (!angular.isObject(settings.headers))
                settings.headers = {};
            return settings;
        }

        /**
         * Wrap GET requests. See $http.get()
         */
        function get(uriSnippet, settings){
            return request(uriSnippet, null, settings, 'GET');
        }

        /**
         * Wrap POST requests. See $http.post()
         */
        function post(uriSnippet, postData, settings){
            settings = setSettings(settings);
            settings.headers['Content-Type'] = 'application/json';
            return request(uriSnippet, postData, settings, 'POST');
        }

        /**
         * Wrap PUT requests. See $http.post()
         */
        function put(uriSnippet, putData, settings){
            settings = setSettings(settings);
            settings.headers['Content-Type'] = 'application/json';
            return request(uriSnippet, putData, settings, 'PUT');
        }

        function request(uriSnippet, data, settings, method){

            settings = setSettings(settings);

            var url = appConfig.discoveryServiceURL + uriSnippet;

            // Cache buster
            if(settings['ignoreCache'] !== false){
                url += $filter('paramDelimiter')(url);
                url += ('_=' + Date.now());
            }

            settings.headers.Accept = 'application/json';

            if(!appConfig.disableAuth) {
                settings.headers.Authorization = 'Bearer ' + UserService.profile.token;
            }

            requestsInProgress++;
            $rootScope.$broadcast('progressIndicator', !!(requestsInProgress));

            // Show processing indicator
            var promise;

            switch(method){
                case 'POST':
                    promise = $http.post(url, data, settings);
                    break;
                case 'PUT':
                    promise = $http.put(url, data, settings);
                    break;
                default:
                    promise = $http.get(url, settings);
                    break;
            }

            // Hide indicator after request finishes
            promise.finally(function(){
                requestsInProgress--;
                requestsInProgress = requestsInProgress < 0 ? 0 : requestsInProgress;
                $rootScope.$broadcast('progressIndicator', !!(requestsInProgress));
            });

            return promise;
        }

    }

})();