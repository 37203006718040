(function () {
    "use strict";
    // Directive for displaying a search entry as a card in a grid
    angular.module('archie.core')
        .directive('listCard', function(){
            return {
                'restrict': 'E',
                'replace': true,
                'controller': 'CardController',
                'templateUrl': 'layout/cards/list/list-card.html'
            };
        });
})();