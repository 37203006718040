(function () {
    "use strict";

    /**
     * Remove qualifiers from string
     * Qualifiers are portions of a string in between parentheses, usually at the end of the string.
     * Uses a whitelist of valid qualifiers that should NOT be removed.
     * Ex: T'Challa (Black Panther) -> T'Challa
     *
     */
    angular.module('archie.core')
        .filter('stripQualifiers', function(QualifierWhitelistMocks){
            return function(data){
                if(!data || data === null) {
                    return data;
                }
                if(angular.isArray(data)) {
                    for(var i = 0; i < data.length; i++) {
                        var unformattedStr = data[i];
                        data[i] = filterSingleString(unformattedStr);
                    }
                    return data;
                } else if(typeof data === 'string') {
                    return filterSingleString(data);
                } else {
                    return data;
                }
            };

            function filterSingleString(termStr) {
                // match all qualifiers inside ()
                var parenList = termStr.match(/[^\(\)]+(?=\))/g);

                if(parenList === null) {
                    return termStr;
                }

                // special case: if it starts with "EP XX:", then remove that piece before continuing processing
                var cleanedTermStr = termStr;
                if(termStr.match(/^[EP]{2}[ ][0-9]{2}[:][ ]/) !== null) {
                    cleanedTermStr = termStr.replace(/^[EP]{2}[ ][0-9]{2}[:][ ]/, "");
                }

                var qualifierList = parenList.filter(function(paren) {
                    // add ( and ) to account for cases like "Black Panther (Black Panther)"
                    var parenFull = '(' + paren + ')';
                    var indexOfCloseParen = cleanedTermStr.indexOf(parenFull) + parenFull.length - 1;
                    var isEnd = indexOfCloseParen === cleanedTermStr.length - 1;
                    var hasCloseParen = cleanedTermStr.charAt(indexOfCloseParen) === ')';
                    var hasOpenParen = cleanedTermStr.charAt(indexOfCloseParen + 1) === '('
                        || (cleanedTermStr.charAt(indexOfCloseParen + 1) === ' '
                            && cleanedTermStr.charAt(indexOfCloseParen + 2) === '(');

                    // ensure that paren statement is not the whole term
                    return parenFull.length !== cleanedTermStr.length && (isEnd || (hasCloseParen && hasOpenParen));
                });

                // if not followed by )( or ) ( in termstr, then remove
                if(qualifierList !== null) {
                    // get list of invalid qualifiers
                    var qualifierListFiltered = qualifierList.filter(function (qualifier) {
                        return !QualifierWhitelistMocks.includes(qualifier); // true if invalid qualifier
                    });

                    // remove invalid qualifiers from full string
                    var term = termStr;
                    for(var i = 0; i < qualifierListFiltered.length; i++) {
                        // add ( and ) to account for cases like "Black Panther (Black Panther)"
                        var indexOfItem = term.indexOf('(' + qualifierListFiltered[i] + ')');
                        var tempTerm = term.substring(0, indexOfItem)
                            + term.substring(indexOfItem + qualifierListFiltered[i].length + 2);
                        term = tempTerm;
                    }

                    return term.trim();
                } else {
                    return termStr;
                }
            }
        });
})();