(function () {
    'use strict';

    /**
     * Component Dependencies:
     *
     * videoTitle
     */

    function videoTitlePlugin(options) {
        var player = this;
        player.on('ready', function () {
            // only add button if title is defined
            if (!options.text) return;
            player.addChild('videoTitle', { text: options.text }, 90);
        });
    }

    videojs.registerPlugin('title', videoTitlePlugin);
})();
