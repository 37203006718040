import debounce from 'lodash/debounce';
import { trackButtonClick, DEBOUNCE_TIMEOUT } from '../utils/snowplow-utils';

angular.module('archie.core').directive(
    'spTrackButtonClick',
    /* @ngInject */ () => {
        return {
            restrict: 'A',
            link: (scope, element, atributes) => {
                let btnName: string = atributes.spTrackButtonClick;
                let [name, label] = btnName.split('::');

                if (!label) {
                    label = name;
                }

                const handleBtnClick = debounce(() => {
                    trackButtonClick(name, label);
                }, DEBOUNCE_TIMEOUT);

                element.on('click', handleBtnClick);

                scope.$on('$destroy', function () {
                    element.off('click', handleBtnClick);
                });
            },
        };
    }
);
