(function () {
    'use strict';

    // Auth Service
    angular.module('archie.core').factory('AuthService', AuthService);

    /**
     *
     * @param HttpWrapperService
     * @param UserService
     * @param PreferenceUtilService
     * @param snowplowService
     * @param loginURL
     * @param $q
     * @param $window
     * @returns {{isDisabled: isDisabled, setDisabled: setDisabled, setAuthenticated: setAuthenticated, isAuthenticated: isAuthenticated, tokenCheck: tokenCheck, logout: logout, login: login}}
     * @constructor
     */
    function AuthService(
        HttpWrapperService,
        UserService,
        PreferenceUtilService,
        snowplowUtilsService,
        loginURL,
        $q,
        $window,
        $filter
    ) {
        var authenticated = false;
        var disabled = appConfig.disableAuth; // constant defined via config

        return {
            isDisabled: isDisabled,
            setDisabled: setDisabled,
            setAuthenticated: setAuthenticated,
            isAuthenticated: isAuthenticated,
            tokenCheck: tokenCheck,
            logout: logout,
            login: login,
            appendUrl: appendUrl,
        };

        /**
         * @param {boolean} value
         */
        function setDisabled(value) {
            disabled = value;
        }

        /**
         * @returns {boolean}
         */
        function isDisabled() {
            return disabled;
        }

        /**
         * @param {boolean} value
         */
        function setAuthenticated(value) {
            authenticated = value;
        }

        /**
         * @returns {boolean}
         */
        function isAuthenticated() {
            // if authentication is disabled via config, always return true.
            return isDisabled() ? true : authenticated;
        }

        /**
         * @returns {promise}
         */
        function tokenCheck() {
            return HttpWrapperService.get('/login/user').then(
                function (response) {
                    UserService.setUsername(response.data[appConfig.userKey]);
                    UserService.setEmail(response.data[appConfig.emailKey]);
                    setAuthenticated(true);
                    const uid = snowplowUtilsService.getUserId(response.data);
                    snowplowUtilsService.setUserId(uid);
                    snowplowUtilsService.trackUnknownUser(response.data);
                },
                function (rejection) {
                    setAuthenticated(false);
                    return $q.reject(rejection);
                }
            );
        }

        function logout() {
            UserService.removeProfile(); //clear stored username, token
            setAuthenticated(false); //remove ui from DOM
            PreferenceUtilService.remove(appConfig.referrerKey);
            $window.location.href = appConfig.logoutUri;
        }

        function login() {
            //save current url so return here after login complete
            PreferenceUtilService.set(appConfig.referrerKey, $window.location.href);
            $window.location.href = loginURL.base;
        }

        /**
         *
         * @param url {string} a url to be appended with auth param
         * @returns {string} url appended with auth param containing token
         */
        function appendUrl(url) {
            if (!disabled) {
                url += $filter('paramDelimiter')(url);
                url += 'auth=' + encodeURIComponent('Bearer ' + UserService.profile.token);
            }
            return url;
        }

        function generateNonce() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = (Math.random() * 16) | 0,
                    v = c == 'x' ? r : (r & 0x3) | 0x8;
                return v.toString(16);
            });
        }
    }
})();
