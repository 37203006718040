(function () {
    "use strict";

    angular.module('archie.core')
        .controller('ShareUrlController', ShareUrlController);

    function ShareUrlController($scope, $mdPanel, $window, $timeout, appContainerId) {

        var panelRef;
        var timer; 
        var xPosition = $scope.xposition || 'ALIGN_START';
        var yPosition = $scope.yposition || 'CENTER';

        // to be called by some transcluded element
        $scope.clickFn = function() {
            openPanel();
        };

        // for programatic open/closing of panel (used by videojs component)
        $scope.$watch('toggleOn', function(val) {
            if(val) {
                openPanel();
            } else {
                closePanel();
            }
        });

        function openPanel() {

            // ensure panel is closed
            closePanel();

            var position = $mdPanel.newPanelPosition()
                            .relativeTo($scope.relativeTo)
                            .addPanelPosition($mdPanel.xPosition[xPosition], $mdPanel.yPosition[yPosition]);
        
            var config = {
                attachTo: angular.element($scope.attachTo || '#'+appContainerId),
                controller: 'CopyUrlController',
                templateUrl:'layout/share-url/copy-url.panel.html',
                panelClass: 'copy-url-panel',
                position: position,
                clickOutsideToClose: true,
                escapeToClose: true,
                focusOnOpen: false,
                trapFocus: !!$scope.trapFocus,
                zIndex: 200,
                locals: {
                    'url': $scope.url
                },
                onRemoving: function() {
                    panelRef = null;
                    $scope.toggleOn = false;
                    angular.element($window).off('resize', closeOnResize);
                }
            };

            $mdPanel.open(config).then(function(result) {
                // save open panel reference
                panelRef = result;
                // close panel on window resize
                angular.element($window).on('resize', closeOnResize);
            });
        }

        function closePanel() {
            if(panelRef) {
                panelRef.close();
            }
        }

        function closeOnResize() {
            $timeout.cancel(timer);
            timer = $timeout(function() {
                closePanel();
            }, 150);
        }

    }

})();