(function (){
    "use strict";

    // Auth Service
    angular.module('archie.core').factory('ErrorService', ErrorService);

    function ErrorService(HttpWrapperService, OmnitureService, MessageConsole, videoJsErrorCodes, deviceDetector, unsupportedVideoPlayback){

        var endpoint = '/log-error';

        return {
            logError: logError,
            videoError: videoError
        };

        function logError(type, reason, consoleMessage) {
            reason += ' | User Info: ';
            reason += deviceDetector.os + ' ' + deviceDetector.os_version;
            reason += ', ' + deviceDetector.browser + ' ' + deviceDetector.browser_version;
            var error = {
                'type': type,
                'reason': reason
            };
            if(angular.isString(consoleMessage)) {
                MessageConsole.displayMessage({ 'type': 'e', 'msg': consoleMessage });
            }
            return HttpWrapperService.post(endpoint, error);
        }
        
        function unsupportedDeviceOrOs() {
            var unsupportedBrowser = unsupportedVideoPlayback.browser.indexOf(deviceDetector.browser) >= 0;
            var unsupportedOs = unsupportedVideoPlayback.os.indexOf(deviceDetector.os) >= 0;
            return unsupportedBrowser || unsupportedOs;

        }

        function videoError(errorCode, errorMessage, customMessage) {
            var consoleMessage = videoJsErrorCodes()[errorCode] || videoJsErrorCodes()['unhandledException'];
            var reason = '';
            if(errorCode === 3 && !unsupportedDeviceOrOs()) {
                consoleMessage = videoJsErrorCodes()['unhandledException'];
            }
            if(errorCode && errorMessage) {
                reason += 'Error Code: ' + errorCode + ' -';
                OmnitureService.videoError(errorCode, errorMessage);
            }
            if(errorMessage) reason += ' ' + errorMessage;
            if(customMessage) reason += ' ' + customMessage;
            return logError('Video Playback Error', reason, consoleMessage);
        }

    }

})();