export const DEBOUNCE_TIMEOUT = 1000;

export function isSnowplowReady() {
    return !!window.snowplowVendor;
}

export function setUserId(userId: string) {
    if (isSnowplowReady()) {
        window.snowplowVendor.setUserId(userId);
    } else {
        (window as any).spUID = userId;
    }
}

export function firePageView(title: string) {
    if (isSnowplowReady()) {
        window.snowplowVendor.firePageView({
            title: title,
        });
    }
}

export function trackComponent(
    componentType: string,
    componentName: string,
    actionName: string,
    label: string,
    options?: {
        value?: Record<string, any>[];
        params?: Record<string, any>;
    }
) {
    if (isSnowplowReady()) {
        window.snowplowVendor.trackComponent({
            componentType: componentType, // Button
            componentName: componentName, // 'Authority Task Submit'
            actionName: actionName, // click
            label: label, //'Submit Task'
            ...options,
        });
    }
}

export function trackComponentClick(componentType: string, name: string, label: string) {
    trackComponent(componentType, name, 'click', label);
}

export function trackButtonClick(name: string, label: string) {
    trackComponentClick('Button', name, label);
}

export function trackCheckboxSelect(name: string, label: string) {
    trackComponent('Checkbox', name, 'select', label);
}

export function trackPageType(page: string, pageType: string) {
    trackComponent('Page', page, 'Page View', page, {
        value: [
            {
                pageType: pageType,
            },
        ],
    });
}

const UNKNOWN_USER = 'Unknown';

export function trackUnknownUser(userData: OIDCUserData | SamlUserData) {
    const component = 'Auth';
    const action = 'Login';
    if (getUserId(userData) === UNKNOWN_USER)
        trackComponent(component, component, action, action, {
            value: [
                {
                    user: userData,
                },
            ],
        });
}

function isOIDC(userData: OIDCUserData | SamlUserData): userData is OIDCUserData {
    return (<OIDCUserData>userData).nonce !== undefined;
}

export function getUserId(userData: OIDCUserData | SamlUserData): string {
    const fullName =
        !!userData.given_name && !!userData.family_name
            ? `${userData.given_name} ${userData.family_name}`
            : '';
    let fallBackId = '';
    if (isOIDC(userData)) {
        fallBackId = userData.preferred_username || userData.name || userData.profile;
    } else {
        fallBackId = userData.hub_id;
    }
    return userData.email || fullName || fallBackId || UNKNOWN_USER;
}
