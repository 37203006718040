(function (){
    "use strict";

    // Preference Service
    angular.module('archie.core').factory('PrettyUrlService', PrettyUrlService);

    function PrettyUrlService(HttpWrapperService, $q){

        return {
            getPrettyUrl: getPrettyUrl
        };

        function getPrettyUrl(url) {
            // check if http protocol exists in url string
            // if not the POST request will fail
            // so just return whatever argument is passed
            if (url.search(/^http[s]?\:\/\//) === -1) {
                var deferred = $q.defer();
                deferred.resolve(url);
                return deferred.promise;
            }
            var postData = {
                'longUrl': url
            };
            return HttpWrapperService.post('/url-shortener', postData)
                .then(function (response) {
                    return response.data.shortUrl || url;
                }).catch(function (errorResponse) {
                    // if request fails, just return the original url.
                    // user can just copy that and still be ok.
                    return url;
                });
        }

    }

})();