(function () {
    "use strict";

    angular.module('archie.core')
        .directive('shareUrl', ShareUrlDirective);

    function ShareUrlDirective($mdPanel) {
        return {
            'restrict': 'E',
            'replace': true,
            'transclude': true,
            'templateUrl': 'layout/share-url/share-url.html',
            'scope': {
                'url': '=', // the url to be shortened
                'relativeTo': '@?', // (optional) selector for element the panel should open relative to
                'xposition': '@?', // (optional) can be: CENTER | ALIGN_START (default) | ALIGN_END | OFFSET_START | OFFSET_END
                'yposition': '@?', // (optional) can be: CENTER (default) | ALIGN_TOPS | ALIGN_BOTTOMS | ABOVE | BELOW
                'attachTo': '=?', // (optional) selector for element panel should be appended to, body tag is default
                'toggleOn': '=?', // (optional) boolean that will be watched for programatic opening/closing panel
                'trapFocus': '=?' // (optional) prevent user from interacting with the rest of the page until the panel is closed, default false
            },
            'controller': 'ShareUrlController'
        };
    }


})();