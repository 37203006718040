// This is the base class for all Video.js controls, called "components".
const VideojsComponent = videojs.getComponent('Component');

/**
 * This will show a red bar over the section of the seek bar that the video is looping over.
 */
class LoopProgressBarComponent extends VideojsComponent {
    constructor(player, options) {
        super(player, options);
    }
    /**
     * Creates the overlay div element.
     * @returns {HTMLDivElement}
     */
    createEl() {
        // The start percentage can't be greater than 100%
        const startPercent = Math.min(this.options_.start / this.player_.duration(), 1);
        // The end percentage can't be less than the start percentage, and can't be greater than 100%
        const endPercent = Math.max(
            Math.min(this.options_.end / this.player_.duration(), 1),
            startPercent
        );

        const div = videojs.dom.createEl('div', {}, { class: 'loop-bar vjs-slider-bar' });
        div.style.left = (startPercent * 100).toFixed(2) + '%';
        div.style.width = ((endPercent - startPercent) * 100).toFixed(2) + '%';
        return div;
    }
}

// Tells videojs about our custom "loop progress bar" component.
videojs.registerComponent('LoopProgressBar', LoopProgressBarComponent);
