(function () {
    "use strict";
    angular.module('archie.core')
        .service('SearchUtil', SearchUtil);

    /* @ngInject */
    function SearchUtil($rootScope, $location, facetSearchParams){
        var limit = 20;
        var offset = 0;
        var searchText = '';
        
        function serializeParams(params) {
            var queryParts = params.map(function(param){
                return param.key + '=' + encodeURIComponent(param.value);
            });
            return queryParts.join('&');
        }

        function createFacetParam(facet) {
            facet = facet || {};
            facet.availableOptions = facet.availableOptions || [];
            var facetJson = {
                'id': facet.id
            };
            var facetOptions = [];
            var param;
            angular.forEach(facet.availableOptions, function (option){
                if (option != null && option.apiName != '' && option.isSelected && option.apiName != 'All') {
                    facetOptions.push(option.apiName);
                }
            });
            if (facetOptions.length != 0) {
                facetJson.selectedOptions = facetOptions;
                param = {
                    'key': 'facet',
                    'value': JSON.stringify(facetJson)
                };
            }
            return param;
        }

        function prepareParamsAndCollectionPath(availableFacets, staticFacets, optionParams, entityType){
            var result = {
                params: [{
                    'key': 'offset',
                    'value': offset
                }, {
                    'key': 'limit',
                    'value': limit
                }],
                collectionPath: ''
            };

            // include false flag for matchAllFacetOptions if config is set to false
            setMatchAllFacetOptionsParam(result.params);

            angular.forEach(availableFacets.concat(staticFacets), function (value, key){
                var facet = value;
                switch (facet.name) {
                    case "Sort":
                    case "Version":
                        angular.forEach(facet.availableOptions, function (option){
                            if (option != null && option.apiName != '' && option.isSelected) {
                                result.params.push({
                                    'key': facet.urlParam,
                                    'value': option.apiName
                                });
                            }
                        });
                        break;
                    case "Collection":
                        angular.forEach(facet.availableOptions, function (option){
                            if (option != null && option.apiName != '' && option.isSelected) {
                                result.collectionPath = option.apiName;
                            }
                        });
                        break;
                    default:
                        var facetParam = createFacetParam(facet);
                        if(facetParam)
                            result.params.push(facetParam);
                        break;
                }
            });
            if (searchText && searchText != '') {
                result.params.push({
                    'key': 'text',
                    'value': searchText
                });
            }

            if(entityType){
                result.params.push({
                    'key': 'entityType',
                    'value': entityType
                })
            }

            return serializeParams(result.params);
        }

        function prepareFacetParams(availableFacets, entityType, facetOptionText) {
            var params = [];
            angular.forEach(availableFacets, function (facet){
                var facetParam = createFacetParam(facet);
                if(facetParam)
                    params.push(facetParam);
            });
            if(searchText) {
                params.push({
                    'key': 'dialogueText',
                    'value': searchText
                });
            }
            if(entityType){
                params.push({
                    'key': 'entityType',
                    'value': entityType
                });
            }
            params.push({
               'key': 'facetOptionText',
               'value': facetOptionText.toLowerCase()
            });

            // include false flag for matchAllFacetOptions if config is set to false
            setMatchAllFacetOptionsParam(params);

            return serializeParams(params);
        }

        function setSearchText(text){
            searchText = text;
        }

        function getSearchText(){
            return searchText;
        }

        function setOffset(num){
            offset = num;
        }

        function getOffset(){
            return offset;
        }

        function setLimit(num){
            limit = num;
        }

        function getLimit(){
            return limit;
        }

        function getMatchAllFacetOptions() {
            return !!facetSearchParams.matchAllFacetOptions;
        }
        
        function setMatchAllFacetOptions(bool) {
            facetSearchParams.matchAllFacetOptions = !!bool;
        }

        function clearSearch(){
            $location.path($location.path().split('/')[1]);
            $location.search('');
        }

        function clearDialogueSearch() {
            var path = $location.path().split('/')[1];
            var searchParams = $location.search();
            setSearchText('');
            $rootScope.offset = 0;
            setOffset(0);
            searchParams['offset'] = 0;
            $location.path('/' + path + '/').search(searchParams);
        }
        
        function setMatchAllFacetOptionsParam(arr) {
            if(getMatchAllFacetOptions() === true) {
                return arr;
            } else {
                arr.push({
                    'key': 'matchAllFacetOptions',
                    'value': false
                });
                return arr;
            }
        }

        return {
            'serializeParams': serializeParams,
            'prepareParamsAndCollectionPath': prepareParamsAndCollectionPath,
            'prepareFacetParams': prepareFacetParams,
            'setSearchText': setSearchText,
            'getSearchText': getSearchText,
            'setOffset': setOffset,
            'getOffset': getOffset,
            'setLimit': setLimit,
            'getLimit': getLimit,
            'getMatchAllFacetOptions': getMatchAllFacetOptions,
            'setMatchAllFacetOptions': setMatchAllFacetOptions,
            'clearSearch': clearSearch,
            'clearDialogueSearch': clearDialogueSearch
        }
    }
})();