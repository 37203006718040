(function () {
    'use strict';

    /**
     * Application Modules and Configuration
     */
    var archie = angular.module('archie', [
        'ngRoute',
        'ngMaterial',
        'angular.filter',
        'angularMoment',
        'archie.core',
        'ngAnimate',
        'ngMockE2E',
        'ngSanitize',
        //'infinite-scroll',
        /* video playback */
        'ng.deviceDetector',
    ]);

    archie.config(function ($logProvider) {
        $logProvider.debugEnabled(false);
    });

    archie.config(function ($mdThemingProvider) {
        var limeAccent = $mdThemingProvider.extendPalette('lime', {
            50: themeColors['accent-50'],
            200: themeColors['accent-200'],
            A400: themeColors['accent-A400'],
        });
        $mdThemingProvider.definePalette('limeAccent', limeAccent);

        var blueGreyBackground = $mdThemingProvider.extendPalette('grey', {
            50: themeColors['bg-50'],
            100: themeColors['bg-100'],
            200: themeColors['bg-200'],
            800: themeColors['bg-800'],
            A100: themeColors['bg-A100'],
            A200: themeColors['bg-A200'],
            A400: themeColors['bg-A400'],
            A700: themeColors['bg-A700'],
        });
        $mdThemingProvider.definePalette('blueGreyBackground', blueGreyBackground);

        $mdThemingProvider
            .theme('default')
            .primaryPalette('indigo')
            .accentPalette('limeAccent')
            .backgroundPalette('blueGreyBackground');
    });

    archie.run(function () {
        document.title = appConfig.title;
    });

    angular.module('archie.core', []).controller('appController', [
        '$scope',
        'AuthService',
        'UserService',
        'HttpWrapperService',
        'TemplateService',
        'appContainerId',
        'loginURL',
        function (
            $scope,
            AuthService,
            UserService,
            HttpWrapperService,
            TemplateService,
            appContainerId,
            loginURL
        ) {
            $scope.appContainerId = appContainerId;
            $scope.feedBackEmail = appConfig.feedBackEmail == '' ? false : appConfig.feedBackEmail;
            $scope.copyright = appConfig.copyright == '' ? false : appConfig.copyright;

            $scope.$watch(
                function () {
                    return AuthService.isAuthenticated();
                },
                function (isAuthenticated) {
                    $scope.authenticated = isAuthenticated;
                    if (isAuthenticated) {
                        // NOTE: don't delete may use in future
                        // get and set various site configurations
                        // HttpWrapperService.get('/configuration').then(function (response) {
                        // creative-work display templates
                        // TemplateService.setTemplates(response.data.templates);
                        // });
                        TemplateService.setTemplates();
                        $scope.ready = true;
                        console.log('scope is ready');
                    } else {
                        $scope.ready = false;
                        console.log('scope not ready');
                    }
                }
            );

            $scope.displayApp = function () {
                return $scope.ready;
            };

            if (!AuthService.isDisabled()) {
                //if user has stored token, check validity, else login
                //(UserService.profile.token) ? AuthService.tokenCheck() : AuthService.login();
                //set token to null/undefined if user decides to change auth systems
                if (
                    UserService.profile.token &&
                    sessionStorage.getItem('authSystem') != undefined
                ) {
                    console.log('user has profile token');
                    AuthService.tokenCheck().then(() => {
                        console.log('after token check');
                        //if (sessionStorage.getItem('authSystem') != undefined) {
                        let target = sessionStorage.getItem('lastAttemptedHref');
                        if (
                            target != undefined &&
                            target.includes('http') &&
                            target != 'https://genome.disney.com/' &&
                            target != 'https://dev.genome.disney.com/' &&
                            target != 'https://qa.genome.disney.com/' &&
                            target != 'https://staging.genome.disney.com/' &&
                            target != 'http://localhost:8080/' &&
                            !target.includes('#/myidoptions')
                        ) {
                            console.log('Redirecting to last attempted href ' + target);
                            window.location = target;
                        } else if (window.location.hash === '#/myidoptions') {
                            window.location = '#/video-dialogue';
                        }
                    });
                } else {
                    console.log('user does not have profile token');
                    //AuthService.login();

                    if (sessionStorage.getItem('authSystem') == 'OIDC') {
                        loginURL.base = appConfig.authUriOIDC;
                        AuthService.login();
                    } else if (sessionStorage.getItem('authSystem') == 'SAML') {
                        loginURL.base = appConfig.authUriSAML;
                        AuthService.login();
                    } else {
                        if (!window.location.href.endsWith('#/myidoptions')) {
                            console.log('window href was: ' + window.location.href);
                            sessionStorage.setItem('lastAttemptedHref', window.location.href);
                            window.location = '#/myidoptions';
                        }
                        console.log('redirecting to myid login options page');
                    }
                }
            }
        },
    ]);

    archie.run(function showVideoImprovementMessage($rootScope, AuthService, MessageConsole) {
        const STORAGE_MESSAGE_HIDDEN_KEY = 'showVideoImprovementMessage_hidden';

        let stopWatching = $rootScope.$watch(
            () => {
                if (!AuthService.isAuthenticated()) {
                    return false;
                }

                let alreadyShown;
                try {
                    alreadyShown = window.localStorage.getItem(STORAGE_MESSAGE_HIDDEN_KEY) === '1';
                } catch (e) {
                    return true;
                }

                if (alreadyShown) {
                    stopWatching();
                    return false;
                } else {
                    return true;
                }
            },
            (shouldShow) => {
                if (!shouldShow) {
                    return;
                }

                let messageData = {
                    type: 'n',
                    trustHtml: true,
                    msg: `
                    Our team has made improvements to Video Search.
                    If you experience any issues, we want to know.
                    Please click on the 'Report Bug'
                    <span class="md-24 material-icons">feedback</span>
                    link to report any problems`,
                    onHide: () => {
                        try {
                            window.localStorage.setItem(STORAGE_MESSAGE_HIDDEN_KEY, '1');
                        } catch (e) {}
                    },
                };

                MessageConsole.displayMessage(messageData);
                stopWatching();
            }
        );
    });
})();
