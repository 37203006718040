(function () {
    "use strict";
    angular.module('archie.core')
        .directive('cardFiltersFacet', function(){
            return {
                'restrict': 'E',
                'replace': true,
                'scope': {
                    'facet': '='
                },
                'templateUrl': 'layout/card-filters/facet/card-filters-facet.html',
                'controller': 'CardFilterFacetController',
                'link': function(scope){
                    scope.init();
                }
            }
        });
})();