(function () {
    "use strict";
    angular.module('archie.core')
        .controller('browserDetectionController', BrowserDetectionController);

    function BrowserDetectionController($scope, $rootScope, deviceDetector, MessageConsole, videoJsErrorCodes, AuthService, unsupportedVideoPlayback) {


        function compatibility() {
            // Compare current browser and OS to unsupported browser and OS constants found in app.constants.js
            var unsupportedBrowser = unsupportedVideoPlayback.browser.indexOf(deviceDetector.browser) >= 0;
            var unsupportedOs = unsupportedVideoPlayback.os.indexOf(deviceDetector.os) >= 0;

            if(unsupportedBrowser || unsupportedOs) {
                var messageData = {
                    'type': 'e',
                    'msg': videoJsErrorCodes()['unsupportedBrowser']
                };
                $scope.$watch(function () {
                    return AuthService.isAuthenticated();
                }, function (authenticated) {
                    if(!!authenticated) {
                        MessageConsole.displayMessage(messageData);
                    }
                });
            }
            return false;
        }

        $rootScope.compatibility = compatibility();

    }

    angular.module('archie.core').directive('browserDetection', function(){
        return {
            'restrict': 'A',
            'controller': 'browserDetectionController'
        }
    });

})();
