(function () {
    "use strict";

    angular.module('archie.core')
        .controller('RelatedContentController', RelatedContentController);

    function RelatedContentController($scope, $filter, AssetService, FeedbackService) {
        $scope.assetId = atob($scope.id);
        $scope.data = [];
        $scope.loading = true;

        function getEpisodes() {
            $scope.pages = [];
            AssetService.getRelatedAssets($scope.key, $scope.relatedId, $scope.type).then(function (data) {
                var relatedContent = [];

                if(data.seasons !== null) {
                    data.seasons.forEach(function (season) {
                        var seasonData = {
                            // if season number is not a number ("Unknown") set seasonNumber to -1 so that it is sorted to the bottom
                            'seasonNumber': isNaN(parseInt(season.seasonNumber)) ? -1 : season.seasonNumber,
                            'label': 'Season ' + season.seasonNumber,
                            'children': []
                        };
                        season.episodes = season.episodes || [];
                        season.episodes.forEach(function (episode) {
                            var item = {};
                            item.url = '#/library/episodes/' + btoa(episode.episodeId);

                            // Handle null airOrderNumbers and episodeTitles
                            // Only display if the property is not null
                            var val = "Unknown Episode";
                            if(episode.airOrderNumber !== null) {
                                val = 'EP ' + $filter('numFixedLen')(episode.airOrderNumber, 2);
                            }
                            if(episode.episodeTitle !== null) {
                                val += ": " + episode.episodeTitle;
                            }
                            item.value = val;
                            item.toolTip = episode.episodeTitle + ': ' + $filter('quoteFormat')(episode.description);
                            seasonData.children.push(item);
                            if ($scope.assetId === episode.episodeId) {
                                $scope.selectedPage = season.seasonNumber;
                            }
                        });
                        relatedContent.push(seasonData);
                    });
                    $scope.data = sortContent(relatedContent, 'seasonNumber').reverse();
                } else {
                    $scope.feedback = FeedbackService.sendFeedback();
                    // select proper verb depending on tab label (eg '*are* no storylines', '*is* no series info')
                    $scope.verb = 'is';
                    if($scope.type.split('').reverse()[0].toLowerCase() === 's') {
                        $scope.verb = 'are';
                    }
                }
                $scope.loading = !$scope.loading;
            });
        }

        function sortContent(arr, prop) {
            return arr.sort(function (a, b) {
                if (prop) {
                    a = a[prop];
                    b = b[prop];
                }
                a = parseInt(a);
                b = parseInt(b);
                if (a < b) return -1;
                if (a > b) return 1;
                return 0;
            });
        }

        switch ($scope.type) {
            // in future cases will include 'season', 'series', & others
            case 'episodes':
                getEpisodes();
                break;
            default:
                $scope.loading = !$scope.loading;
                return;
        }
    }

})();