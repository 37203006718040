(function () {
    "use strict";

    angular.module('archie.core')
        .controller('CopyUrlController', CopyUrlController);

    function CopyUrlController($scope, url, PrettyUrlService, mdPanelRef, $timeout, $window, MessageConsole) {

        var timer;

        $scope.btnText = 'Copy URL';
        $scope.loading = true;
        
        // make sure panel is finished loading in DOM prior to init()
        $timeout(function() {
            init();
        });

        $scope.closePanel = function() {
            mdPanelRef.close();
        };

        $scope.copyText = function() {
            selectUrl();
            try {
                // copy to clipboard
                var copy = document.execCommand('copy');
                if(!copy) {
                    // show error message
                    copyError();
                    return;
                }
                $scope.btnText = 'Copied!';
                // close panel after copy
                timer = setTimeout(function() {
                    $scope.closePanel();
                }, appConfig.shareUrlTimeout);
            }
            catch (err) {
                // show error message
                copyError();
            }
        };
        
        // retreive shortened URL from service
        PrettyUrlService.getPrettyUrl(url).then(function(prettyUrl) {
            $scope.url = prettyUrl;
            $scope.loading = false;
            // highlight input text when complete
            $timeout(function() {
                selectUrl();
            });
        });

        function init() {
            // append panel's config onRemoving function 
            // to remove window click event listener on close
            var onRemoving = angular.copy(mdPanelRef._config.onRemoving);
            mdPanelRef._config.onRemoving = function() {
                onRemoving();
                angular.element($window).off('click', clickCapture);
            };
            // hide page overlay if trapFocus is not set
            if(!mdPanelRef._config.trapFocus) {
                mdPanelRef._panelEl.parent().css({
                    'width': 0,
                    'height': 0
                });
            }
            angular.element($window).on('click', clickCapture);
        }

        // close panel if clicked outside panel
        function clickCapture($event) {
            if(!angular.element($event.target).closest(mdPanelRef._panelEl).length) {
                $scope.closePanel();
            }
        }

        // highlight input text
        function selectUrl() {
            mdPanelRef._panelEl.find('input').select();
        }

        // show toast message if document.execCommand not supported by browser
        function copyError() {
            MessageConsole.displayMessage({
                'type': 'e',
                'msg': 'please press Ctrl/Cmd+C to copy',
                'delay': 5000
            });
        }

    }

})();