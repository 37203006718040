(function () {
    'use strict';

    /**
     * Component Dependencies:
     *
     * segmentContainer
     */

    /**
     *
     */
    function timelineMetadataPlugin(options) {
        if (!options.segments) return;
        var player = this;
        var segmentContainer;
        var controlBar;

        player.one('ready', function () {
            controlBar = player.getChild('controlBar');
            controlBar.addClass('segments');
        });

        player.one('loadedmetadata', function () {
            segmentContainer = controlBar.addChild('segmentContainer', {}, 0);
            segmentContainer.initSegmentContainer();
            if (options.segments && options.segments.length > 0) {
                segmentContainer.addSegments(options.segments);
            }
            player.on('addTimelineSegments', function (_event, data) {
                segmentContainer.addSegments(data);
            });
            player.on('addTimelineSegmentsComplete', function () {
                segmentContainer.addSegmentsComplete();
            });
            player.on('highlightSegments', function (_event, data) {
                segmentContainer.highlightSegments(data);
            });
        });
    }

    videojs.registerPlugin('timelineMetadata', timelineMetadataPlugin);
})();
