$(document).ready(function () {
    function loadScript(src, callback) {
        var script = document.createElement('script');
        script.src = src;
        script.onload = function () {
            if (callback) callback();
        };
        document.head.appendChild(script);
    }

    function initOmniture() {
        // if adblocker blocks AppMeasurement.js, s object will not be created
        if (!window.s) return;

        /* You may give each page an identifying name, server, and channel on
         the next lines. */
        s.server = window.appConfig.omnitureServer;
        s.channel = '';
        s.pageType = '';
        s.prop1 = '';
        s.prop2 = '';
        s.prop3 = '';
        s.prop4 = '';
        s.prop5 = '';
        /* Conversion Variables */
        s.campaign = '';
        s.state = '';
        s.zip = '';
        s.events = '';
        s.products = '';
        s.purchaseID = '';
        s.eVar1 = '';
        s.eVar2 = '';
        s.eVar3 = '';
        s.eVar4 = '';
        s.eVar5 = '';
        //NOTE: don't delete, might use in future
        //var s_code=s.t();if(s_code)document.write(s_code)
    }

    function initSnowplow() {
        const appName = 'video-search-' + appConfig.clientId;
        const config = {
            env: appConfig.clientId,
            appName: appName,
            activityTracking: {
                minimumVisitLength: 10,
                heartbeatDelay: 10,
            },
        };

        const snowplowVendor = new Snowplow({ config });
        snowplowVendor.enableAutomaticLinkTracking();
        snowplowVendor.refreshLinkTracking();
        snowplowVendor.enableFormTracking();
        snowplowVendor.enableErrorTracking();
        window.snowplowVendor = snowplowVendor;
        if (window.spUID) {
            // Login happened before sp library is ready
            window.snowplowVendor.setUserId(window.spUID);
        }
    }

    window.s_account = appConfig.omnitureId;
    loadScript('../omniture/AppMeasurement.js', initOmniture);
    loadScript('../snowplow/index.js', initSnowplow);

    angular.bootstrap(angular.element('body')[0], ['archie']);

    console.log('Git branch:', GIT_BRANCH);
    console.log('Git revision:', GIT_REVISION);
});
