(function () {
    "use strict";

    angular.module('archie.core')
        .directive('tabAdditionalInfo', function(){
            return {
                'restrict': 'E',
                'replace': true,
                'templateUrl': 'library/tab-additional-info/tab-additional-info.html',
                'scope': {
                    'assetLabel': '=',
                    'additionalInfo': '='
                }
            };
        });
})();