(function () {
    'use strict';

    // Whitelist for valid term qualifiers
    angular.module('archie.core')
        .constant('QualifierWhitelistMocks',
            [
                // Age
                "adult", "age 4", "age 5", "age 6", "age 8", "age 9", "age 11", "age 15", "age 18", "baby", "collegiate",
                "old", "older", "pre-teen", "teen", "tween", "toddler", "young", "younger",
                // Character Names
                "Dr. Henry Pym", "Scott Lang"]
        );
})();