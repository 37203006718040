(function () {
    "use strict";

    /**
     * Returns either '?' or '&' as appropriate based on whether or not string contains query params
     */
    angular.module('archie.core')
        .filter('paramDelimiter', function(){
            return function(url){
                return (url.split('?').length > 1 && url.split('?')[1] !== '') ? '&' : '?';
            }
        });
})();