(function () {
    "use strict";

    angular.module('archie.core')
        .controller('FacetSearchController', FacetSearchController);

    function FacetSearchController($scope, $window, $timeout, orderByFilter, $filter) {

        var searchTimer;
        var resizeTimer;
        var eventTimeout = 350;

        var altSortOption = {
            'id': $scope.altSortProp,
            'label': $scope.altSortLabel,
            'fontIcon': 'sort'
        };
        var alphaSortOption = {
            'id': 'alpha',
            'label': 'Alphabetically',
            'fontIcon': 'sort_by_alpha'
        };
        var numericSortOption = {
            'id': 'numeric',
            'label': 'Numerically',
            'fontIcon': 'format_list_numbered'
        };

        $scope.shownItems = [];
        $scope.sortOptions = [];

        $scope.checkboxChange = function (item) {
            // retain checked/unchecked items in updatedData array
            if($scope.updatedData.indexOf(item) < 0) {
                $scope.updatedData.push(item);
            }
            // if using match all facet config, need to get new
            // list of options on every checkbox click
            if($scope.updateOnChange) {
                $scope.clearSearchText();
                getOptionsFromBackend($scope.updatedData);
            }
        };

        $scope.init = function () {
            $scope.facetSize = $scope.data.length;
            angular.forEach($scope.sortedBy, function (sortOption) {
                switch (sortOption) {
                    case $scope.altSortProp:
                        $scope.sortOptions.push(altSortOption);
                        break;
                    case 'alpha':
                        $scope.sortOptions.push(alphaSortOption);
                        break;
                    case 'numeric':
                        $scope.sortOptions.push(numericSortOption);
                        break;
                }
            });
            $scope.sortData($scope.sortOptions[0]);
            $scope.updateShownItems();
            $scope.focusInput = true;
        };

        $scope.updateShownItems = function () {
            // remove items that do not contain search text string
            // retain all scrollGroups objects in array
            $scope.shownItems = $scope.data.filter(function (item) {
                return item.scrollGroupLabel
                    || (item[$scope.itemLabelProp]
                        && item[$scope.itemLabelProp].toLowerCase().indexOf($scope.searchText.value.toLowerCase()) > -1)
                    || item[$scope.itemModelProp];
            });
            // can't chain filter below with one above, because below depends on results from above
            // remove any scrollGroupLabel objects when no items withing that group are visible due to filter above
            $scope.shownItems = $scope.shownItems.filter(function (item, index) {
                return item[$scope.itemLabelProp]
                    || (item.scrollGroupLabel
                    && $scope.shownItems[index+1] && $scope.shownItems[index+1][$scope.itemLabelProp]);
            });
        };

        $scope.searchTextChanged = function () {
            if($scope.facetLimit && $scope.facetSize >= $scope.facetLimit && angular.isFunction($scope.getItemsFn)) {
                $timeout.cancel(searchTimer);
                searchTimer = $timeout(getOptionsFromBackend, eventTimeout);
            } else {
                $scope.updateShownItems();
            }
        };

        $scope.clearSearchText = function () {
            $scope.searchText.value = '';
            $scope.searchTextChanged();
        };

        $scope.goToGroup = function (group) {
            angular.forEach($scope.shownItems, function (item, index) {
                if(item.scrollGroupLabel && item.scrollGroupLabel === group) {
                    $scope.topIndex = index;
                }
            });
            // Seems to be a bug in md-virtual-repeat, where browser zooming in/out causes it to miscalculate
            // the translateY transform value of md-virtual-repeat-offsetter when updating the md-top-index value.
            // Adding a new item to the repeater, then removing and calling 'md-resize' seems to get it to recalculate correctly.
            $scope.shownItems.push({});
            // $timeout so watcher in md-virtual-repeat will register change before removing.
            $timeout(function () {
                $scope.shownItems.pop();
                $scope.$broadcast('$md-resize');
            });
        };

        $scope.sortData = function(sortBy, forceSort) {
            if($scope.sortBy === sortBy && !forceSort) return;
            $scope.sortBy = sortBy;
            $scope.scrollGroups = [];
            removeScrollGroupLabels($scope.data);

            switch (sortBy.id) {
                case $scope.altSortProp:
                    altSort();
                    break;
                case 'alpha':
                    alphabetSort();
                    break;
                case 'numeric':
                    numberSort();
                    break;
            }
            $scope.updateShownItems();
            $scope.topIndex = 0;
        };

        function altSort() {
            $scope.data = orderByFilter($scope.data, $scope.altSortProp, true);
        }

        function alphabetSort() {
            var numSectionStart;
            var numSectionLength = -1;
            var startNumSectionCount = false;

            //sort alphabetically descending
            $filter('alphanumericSort')($scope.data, false, $scope.itemLabelProp);

            // insert Letter objects into item array at appropriate spot:
            // [ { scrollGroupLabel: 'A' }, { A item }, { A item }, { scrollGroupLabel: 'B' }, etc. ]
            for (var i = 0; i < $scope.data.length; i++) {
                var letter = $filter('cleanString')($scope.data[i][$scope.itemLabelProp]);
                letter = letter.toUpperCase().charAt(0);
                letter = (!letter || !isNaN(parseInt(letter))) ? '#' : letter;
                if ($scope.scrollGroups.indexOf(letter) < 0) {
                    var obj = {
                        'scrollGroupLabel': letter
                    };
                    $scope.data.splice(i, 0, obj);
                    //push available letters into scrollGroups array for links
                    $scope.scrollGroups.push(letter);

                    //save start index and length of non alpha items
                    if(letter === '#') {
                        numSectionStart = i+1;
                        startNumSectionCount = true;
                    } else {
                        startNumSectionCount = false;
                    }
                }
                if(startNumSectionCount) numSectionLength++;
            }

            // if there # items, sort by number
            if(numSectionStart) {
                // pick # section of data array to sort
                var numArray = $scope.data.splice(numSectionStart, numSectionLength);
                $filter('alphanumericSort')(numArray, true, $scope.itemLabelProp);
                // place sorted # items back in data array
                for (var j = 0; j < numArray.length; j++) {
                    $scope.data.splice(numSectionStart+j, 0, numArray[j]);
                }
            }
        }

        function numberSort() {
            var chunk = 10;
            $filter('alphanumericSort')($scope.data, true, $scope.itemLabelProp);
            // insert number objects into item array at appropriate spot:
            // [ { scrollGroupLabel: '1' }, { 1 item }, { 1 item }, { scrollGroupLabel: '10' }, etc. ]
            for (var i = 0; i < $scope.data.length; i++) {
                var number = parseInt($scope.data[i][$scope.itemLabelProp]);
                if(!isNaN(number)) {
                    var groupLabel = Math.max(1, Math.floor(number/chunk)*chunk);
                    if ($scope.scrollGroups.indexOf(groupLabel) < 0) {
                        var obj = {
                            'scrollGroupLabel': groupLabel
                        };
                        $scope.data.splice(i, 0, obj);
                        //push available letters into scrollGroups array for links
                        $scope.scrollGroups.push(groupLabel);
                    }
                }
            }
        }

        function handleResize() {
            $timeout.cancel(resizeTimer);
            resizeTimer = $timeout(function () {
                //force md-virtual-repeat to recalculate heights/offsets/etc.
                $scope.$broadcast('$md-resize');
            }, 350);
        }
        
        function removeScrollGroupLabels(arr) {
            var i = arr.length;
            while (i > 0) {
                if(arr[i - 1].scrollGroupLabel) {
                    arr.splice(i - 1, 1);
                }
                i--;
            }
            return arr;
        }

        function getOptionsFromBackend(updatedData) {
            $scope.loading = true;
            $scope.getItemsFn({ updatedData: updatedData }).then(function(data) {
                $scope.data = data;
                preserveSelectedOptions();
                $scope.sortData($scope.sortBy, true);
                $scope.loading = false;
            });
        }

        function preserveSelectedOptions() {
            // in case selected facet option is found in search, need to replace with updated version
            var i = $scope.data.length;
            while (i > 0) {
                angular.forEach($scope.updatedData, function(updatedOption) {
                    if(updatedOption[$scope.itemLabelProp] === $scope.data[i-1][$scope.itemLabelProp]) {
                        $scope.data.splice(i-1, 1, updatedOption);
                    }
                });
                i--;
            }
            
            // in case selected facet option is not found in search, want to display it anyway, so add to array
            angular.forEach($scope.updatedData, function(updatedOption) {
                if(updatedOption[$scope.itemModelProp]) {
                    var index = $scope.data.map(function(item) { return item[$scope.itemLabelProp]; }).indexOf(updatedOption[$scope.itemLabelProp]);
                    if(index === -1) $scope.data.push(updatedOption);
                }
            });
        }

        $scope.sortData($scope.sortOptions[0]);

        $scope.$on('$destroy', function () {
            angular.element($window).off('resize', handleResize);
        });

        $scope.$watch('sortBy', function (newSort, oldSort) {
            if(!angular.equals(newSort, oldSort)) {
                $scope.showOptionCount = angular.equals($scope.sortBy.id, $scope.itemSizeProp);
            }
        });

        angular.element($window).on('resize', handleResize);

    }


})();