(function () {
    'use strict';

    /**
     * Card Filters Controller
     */
    angular
        .module('archie.core')
        .controller('CardFiltersController', CardFiltersController)
        .directive('cardFilters', CardFiltersDirective);

    function CardFiltersController(
        $scope,
        $rootScope,
        $timeout,
        FacetService,
        keyCodes,
        snowplowUtilsService
    ) {
        var timer;

        //always clear facet search text when search params change & controller instantiates
        FacetService.clearSearchText();

        $scope.searchText = {
            value: FacetService.getSearchText(),
        };
        $scope.availableFacets = $scope.availableFacets || [];

        $scope.clearSearch = function () {
            $scope.searchText.value = '';
            FacetService.clearSearchText();
            resetFacets();
            const btnName = 'Clear Filter By';
            snowplowUtilsService.trackButtonClick(btnName, btnName);
        };

        $scope.typeSearch = function (keycode) {
            if ($scope.searchText.value === FacetService.getSearchText()) return;

            $timeout.cancel(timer);

            if (!$scope.searchText.value) {
                $scope.clearSearch();
            }

            if (keycode === keyCodes.ENTER) {
                //ENTER key
                $scope.search();
            } else {
                if ($scope.searchText.value.length >= 3) {
                    timer = $timeout(function () {
                        $scope.search();
                    }, 250);
                }
            }
        };

        $scope.search = function () {
            if (!$scope.searchText.value) return;
            FacetService.setSearchText($scope.searchText.value);
            FacetService.getFacetsFromSearchText(
                $scope.availableFacets,
                $scope.searchText.value
            ).then(function (facetResponse) {
                FacetService.setFacets(facetResponse);
            });
        };

        $scope.handleSearchBtnClick = function () {
            $scope.search();
            const btnName = 'Filter By';
            snowplowUtilsService.trackButtonClick(btnName, btnName);
        };

        $scope.expandFacet = function (facet) {
            facet.isExpanded = !facet.isExpanded;
            if (facet.isExpanded && !$scope.searchText.value) {
                facet.loading = true;
                FacetService.getFacetsFromBackend(facet.id).then(function (facetResponse) {
                    facetResponse.isExpanded = true;
                    $scope.availableFacets[$scope.availableFacets.indexOf(facet)] = facetResponse;
                    facet.loading = false;
                });
            }
        };

        $scope.$watch('availableFacets', function () {
            if (!$scope.searchText.value) return;
            expandFacets();
        });

        // set defaults for card set
        expandFacets();

        function expandFacets() {
            $scope.availableFacets.forEach(function (facet) {
                facet.isExpanded = facet.availableOptions && facet.availableOptions.length > 0;
            });
        }

        function resetFacets() {
            FacetService.getFacetsFromBackend().then(function (facetResponse) {
                $scope.availableFacets = facetResponse;
            });
        }
    }

    /* @ngInject */
    function CardFiltersDirective() {
        return {
            restrict: 'E',
            templateUrl: 'layout/card-filters/card-filters.html',
            replace: true,
            controller: 'CardFiltersController',
            scope: {
                availableFacets: '=',
            },
        };
    }
})();
