(function (){
    "use strict";

    /**
     * Application Constants
     */

    angular.module('archie.core')
        .constant('env', appConfig.env)
        .constant('buildVersion', appConfig.buildVersion)
        .constant('releaseVersion', 1)
        .constant('appContainerId', 'archieApp')
        .constant('keyCodes', {
            'ENTER': 13,
            'SHIFT': 16,
            'TAB': 9,
            'ARROW_UP': 38,
            'ARROW_DOWN': 40
        })
        .constant('icons', {
            'DIALOGUE_SEARCH': 'question_answer',
            'FILTER_BY_SEARCH': 'search',
            'FILTER_MENU': 'filter_list'
        })
        .constant('unsupportedVideoPlayback', {
            'browser': ['ie', 'ms-edge'],
            'os': ['ios']
        })
        .constant('videoJsErrorCodes', function () {
            var extMonitor  = 'Video playback failed. '
                + 'If you\'re using Safari, video might not play if external monitors are connected. '
                + 'Try disconnecting them or use Chrome or Firefox browser instead';
            var unsupportedBrowser = 'Video playback is not supported for this device or browser. '
                             + 'Try using the latest desktop version of Chrome or Firefox instead.';
            var unhandledException = 'Video playback error — the Genome Team has been notified. Please try again later.';

            return {
                '0': extMonitor,
                '3': unsupportedBrowser,
                '4': unsupportedBrowser,
                'extMonitor': extMonitor,
                'unsupportedBrowser': unsupportedBrowser,
                'unhandledException': unhandledException
            };

        })
        .constant('mappings', {
            'asset' : {
                'type' : {
                    'prop' : 'productionType',
                    'label' : 'Type',
                    'omniture' : 'prop15'
                },
                'network' : {
                    'prop' : 'channel',
                    'label' : 'Network',
                    'omniture' : 'prop4'
                },
                'title' : {
                    'prop' : 'hasFeatureTitle',
                    'label' : 'Title',
                    'omniture' : 'prop16'
                },
                'series' : {
                    'prop' : 'seriesTitle',
                    'label' : 'Series',
                    'omniture' : 'prop5'
                },
                'season' : {
                    'prop' : 'season',
                    'label' : 'Season',
                    'omniture' : 'prop6'
                },
                'episodeNumber' : {
                    'prop' : 'episode',
                    'label' : 'Episode'
                },
                'episodeTitle' : {
                    'prop' : 'title',
                    'label' : 'Episode',
                    'omniture' : 'prop7'
                },
                'date' : {
                    'prop' : 'releaseDate',
                    'label' : 'Original Air Date'
                },
                'radar' : {
                    'prop': 'radarProductId',
                    'label': 'RADAR Product ID'
                },
                'featureTitle' : {
                    'prop' : 'title',
                    'label' : 'Feature'
                },
                'featureId' : {
                    'prop' : 'featureId',
                    'label' : 'Feature ID'
                },
                'characters' : {
                    'prop' : 'Character',
                    'label' : 'Characters',
                    'omniture' : 'prop8',
                    'isTemporal': true,
                    'icon': 'person',
                    'order': 0
                },
                'locations' : {
                    'prop' : 'Location',
                    'label' : 'Locations',
                    'omniture' : 'prop9',
                    'isTemporal': true,
                    'icon': 'location_on',
                    'order': 1
                },
                'activities' : {
                    'prop' : 'Actions',
                    'label' : 'Activities',
                    'omniture' : 'prop10',
                    'isTemporal': true,
                    'icon': 'directions_run',
                    'order': 2
                },
                'events' : {
                    'prop' : 'Background-Activities',
                    'label' : 'Events',
                    'omniture' : 'prop11',
                    'isTemporal': true,
                    'icon': 'event',
                    'order': 3
                },
                'dialogue' : {
                    'prop' : 'dialogue',
                    'label' : 'Dialogue',
                    'omniture' : 'prop3',
                    'isTemporal': true,
                    'icon': 'chat',
                    'partialReplace': true,
                    'order': 4
                },
                'confidenceValue' : {
                    'prop' : 'confidenceValue',
                    'label' : '(Beta) Confidence Value',
                    'omniture' : 'prop12',
                    'isTemporal': true,
                    'icon': 'thumb_up',
                    'order': 5
                }
            }
        });

})();
