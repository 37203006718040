(function() {
    "use strict";
    angular.module('archie.core')
        .directive('autoFocus', function ($timeout) {
            return {
                restrict: 'A',
                scope: {
                    delay: '@',
                    focusOn: '=?'
                },
                link: function(scope, element) {
                    var delay = scope.delay || 0; //in milliseconds
                    function focus() {
                        $timeout(function() {
                            element[0].focus();
                        }, delay);
                    }
                    scope.$watch('focusOn', function (newVal) {
                       if(newVal)
                           focus();
                    });

                    focus();
                }
            }
        });
})();
