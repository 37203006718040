(function () {
    "use strict";

    angular.module('archie.core')
        .directive('chipField', function(){
            return {
                'restrict': 'E',
                'template': '<md-chips class="chip-field" readonly="true" ng-model="values"></md-chips>',
                'scope': {
                    'value': '='
                },
                'controller': ['$scope', function($scope){
                    $scope.values = angular.isArray($scope.value) ? $scope.value : ($scope.value ? [$scope.value] : []);
                }]
            };
        });
})();