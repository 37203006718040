(function (){
    "use strict";

    // Metadata Mapping Service
    angular.module('archie.core').factory('MetadataMappingService', MetadataMappingService);

    /**
     * MetadataMappingService - Metadata Mapping Service
     * @param mappings 
     * @returns {{mapMetadata: mapMetadata}}
     */
    function MetadataMappingService(mappings){

        var map = {};

        angular.forEach(mappings.asset, function(assetMap, key) {
            if(assetMap.isTemporal === true) {
                map[assetMap.prop] = assetMap;
            }
        });

        return {
            getMetadataMap: getMetadataMap,
            mapMetadata: mapMetadata
        };

        function getMetadataMap() {
            return angular.copy(map);
        }

        /**
         *
         * @param asset
         * @returns {Array} of metadata values mapped to some extra data for use in templates
         */
        function mapMetadata(asset){
            asset = asset || {};
            var metadata = [];

            angular.forEach(angular.copy(map), function(obj) {
                if(asset.hasOwnProperty(obj.prop)) {
                    obj.key = obj.prop;
                    obj.value = asset[obj.prop];
                    this.push(obj);
                }
            }, metadata);

            metadata.sort(function(a, b) {
                if (a.order < b.order) {
                    return -1;
                }
                if (a.order > b.order) {
                    return 1;
                }
                return 0;
            });

            return metadata;
        }


    }
})();