(function () {
    'use strict';

    // Auth Service
    angular.module('archie.core').factory('OmnitureService', OmnitureService);

    function OmnitureService(
        $rootScope,
        UserService,
        FacetService,
        SearchUtil,
        snowplowUtilsService,
        $window,
        $location,
        deviceDetector,
        mappings
    ) {
        var delimiter = '|';

        // how custom properties are mapped in Omniture
        // prop1: Users (janed001)
        // prop2: Page Type (SEARCH)
        // prop3: Dialogue Text Search
        // prop4: Network
        // prop5: Series
        // prop6: Season
        // prop7: Episode
        // prop8: Characters
        // prop9: Locations
        // prop10: Activities
        // prop11: Events
        // prop12: Facet Category (Channel, Series, etc.)
        // prop13: Video - for loaded video src's
        // prop14: Video playback error
        // prop15: Type (Production Type)
        // prop16: Title

        $window.s = $window.s || {};

        return {
            searchPageView: searchPageView,
            libraryPageView: libraryPageView,
            facetSearch: facetSearch,
            dialogueSearch: dialogueSearch,
            videoView: videoView,
            videoError: videoError,
            logFacetSearch: logFacetSearch,
        };

        function getUrlPath() {
            var path = $location.path();
            return $location.path().substr(0, path.lastIndexOf('/'));
        }

        function getCommonPageProps() {
            var user = UserService.profile.email || 'Unknown';
            return {
                userAndLocation: user + ' ' + $location.url(),
                user: user,
            };
        }

        function prepareOmnitureObj(commonPageProps) {
            //clear out custom props and evars from previous page hit
            clearPropsAndVars();

            // common Omniture props & vars
            s.eVar1 = commonPageProps.userAndLocation;
            s.prop1 = commonPageProps.user; //user
            s.linkTrackEvents = 'None';
            s.linkTrackVars = 'eVar1';
        }

        function clearPropsAndVars() {
            s.pageName = '';
            s.pageType = '';
            // there are 75 omniture props and evars each
            for (var i = 1; i <= 75; i++) {
                s['prop' + i] = '';
                s['eVar' + i] = '';
            }
        }

        function logSnowplowDialogueSearch(searchText) {
            snowplowUtilsService.trackComponent(
                'Text Input', // Component type
                'Dialogue Search Input', // Component name
                'Search By', // Action Name
                'Dialogue Search Input', // label
                {
                    value: [
                        {
                            searchText: searchText, // prop3
                        },
                    ],
                }
            );
        }

        function dialogueSearch(searchText) {
            clearPropsAndVars();
            var searchTextNormalized = searchText.replace(/%20/g, ' ');
            s.prop3 = searchTextNormalized;
            //unlike s.t(), s.tl() does not track page views
            if (angular.isFunction(s.tl)) s.tl(); // send to Omniture

            logSnowplowDialogueSearch(searchTextNormalized);
        }

        function getFacets() {
            var filters = {};
            var facetCategories = [];
            var facetsByType = {};
            //loop through facet map properties (corresponds to facet categories)
            angular.forEach(mappings.asset, function (assetMap) {
                if (angular.isDefined(assetMap.omniture)) {
                    var facet = FacetService.getFacetByFacetName(assetMap.label);
                    var selectedOptions = [];

                    if (facet && facet.selectedOptions && facet.selectedOptions.length) {
                        // loop through selected facets within this category
                        // and save them to selectedOptions array
                        angular.forEach(facet.selectedOptions, function (option) {
                            selectedOptions.push(option.optionName.replace(delimiter, ''));
                        });
                        facetsByType[assetMap.label] = selectedOptions;
                    }

                    if (selectedOptions.length) {
                        facetCategories.push(assetMap.label);
                    }
                }
            });

            if (facetCategories.length) {
                filters.facetTypes = facetCategories;
                filters.facetsByType = facetsByType;
            }

            return filters;
        }

        function logFacetSearch() {
            const { facetTypes, facetsByType } = getFacets();
            if (!facetTypes || !facetsByType || $rootScope.isEntryPage) {
                return;
            }
            snowplowUtilsService.trackComponent(
                'Checkbox List', // Component type
                'Facets', // Component name
                'Search By', // Action Name
                'Facets', // label
                {
                    value: [
                        {
                            facetTypes: facetTypes, // prop12: Array<string>
                            facetsByType: facetsByType, // prop4...prop12, prop15, prop16: { [key: string]: Array<string> }
                        },
                    ],
                }
            );
        }

        function facetSearch(facet, optionsInput) {
            var option = optionsInput;
            var facetsByType = {};
            clearPropsAndVars();
            // handle multiple selected options
            if (angular.isArray(option)) {
                option = option.join(delimiter);
            } else {
                optionsInput = [optionsInput];
            }
            angular.forEach(mappings.asset, function (assetMap) {
                if (facet === assetMap.label && angular.isDefined(assetMap.omniture)) {
                    s[assetMap.omniture] = option;
                    facetsByType[assetMap.label] = optionsInput;
                }
            });
            s.prop12 = facet;

            //unlike s.t(), s.tl() does not track page views
            if (angular.isFunction(s.tl)) s.tl(); // send to Omniture
            // logFacetSearch([facet], facetsByType);
        }

        function getSearchPageProps() {
            return {
                pageName: $location.url().replace(/%20/g, ' '),
                pageURL: $location.absUrl(),
                pageType: 'search',
                pageTypeAlt: 'SEARCH',
            };
        }

        function logSnowplowSearchCriteria(filters) {
            // var filters: {
            //     searchText: filters.searchText, // prop3
            //     facetTypes: filters.facetTypes, // prop12: Array<string>
            //     facetsByType: filters.facetsByType, // prop4...prop12, prop15, prop16: { [key: string]: Array<string> }
            // };

            snowplowUtilsService.trackComponent(
                'Page', // Component type
                'Search Page', // Component name
                'Search By', // Action Name
                'Search Page', // label
                {
                    value: [filters],
                }
            );
        }

        function searchPageView() {
            var commonPageProps = getCommonPageProps();
            var searchPageProps = getSearchPageProps();
            var filters = {};

            prepareOmnitureObj(commonPageProps);
            s.pageName = searchPageProps.pageName;
            s.pageURL = searchPageProps.pageURL;
            s.pageType = searchPageProps.pageType;
            s.prop2 = searchPageProps.pageTypeAlt;

            // if entry page has facets selected or search text entered, need to track in Omniture
            if ($rootScope.isEntryPage) {
                var facetCategories = [];
                var searchTextNormalized = SearchUtil.getSearchText().replace(/%20/g, ' ');
                s.prop3 = searchTextNormalized;

                if (!!searchTextNormalized) {
                    filters.searchText = searchTextNormalized;
                }

                var facetsByType = {};
                //loop through facet map properties (corresponds to facet categories)
                angular.forEach(mappings.asset, function (assetMap) {
                    if (angular.isDefined(assetMap.omniture)) {
                        var facet = FacetService.getFacetByFacetName(assetMap.label);
                        var selectedOptions = [];

                        if (facet && facet.selectedOptions && facet.selectedOptions.length) {
                            // loop through selected facets within this category
                            // and save them to selectedOptions array
                            angular.forEach(facet.selectedOptions, function (option) {
                                selectedOptions.push(option.optionName.replace(delimiter, ''));
                            });
                            // assign selected options (pipe deliminated)
                            // to corresponding custom Omniture prop
                            s[assetMap.omniture] = selectedOptions.join(delimiter);
                            facetsByType[assetMap.label] = selectedOptions;
                        }

                        if (selectedOptions.length) {
                            facetCategories.push(assetMap.label);
                        }
                    }
                });

                if (facetCategories.length) {
                    s.prop12 = facetCategories.join(delimiter);
                    filters.facetTypes = facetCategories;
                    filters.facetsByType = facetsByType;
                }

                snowplowUtilsService.trackPageType('Search Page', searchPageProps.pageTypeAlt);
            }

            // track page view
            if (angular.isFunction(s.t)) s.t(); // send to Omniture

            if (!!filters.facetTypes || !!filters.searchText) {
                logSnowplowSearchCriteria(filters);
            }
        }

        function getLibraryPageProps(type, name) {
            var pageType = 'Library: ' + type;
            return {
                pageName: getUrlPath() + '/' + name,
                pageURL: $location.absUrl(),
                pageType: pageType,
                pageTypeAlt: pageType,
            };
        }

        function libraryPageView(type, name) {
            var commonPageProps = getCommonPageProps();
            var libraryPageProps = getLibraryPageProps(type, name);

            prepareOmnitureObj(commonPageProps);
            s.pageName = libraryPageProps.pageName;
            s.pageURL = libraryPageProps.pageURL;
            s.pageType = libraryPageProps.pageType;
            s.prop2 = libraryPageProps.pageTypeAlt;
            // track page view
            if (angular.isFunction(s.t)) s.t(); // send to Omniture

            snowplowUtilsService.trackPageType('Asset Library Page', libraryPageProps.pageTypeAlt);
        }

        function logSnowplowVideoView(video) {
            return snowplowUtilsService.trackComponent(
                'Video Player', // Component type
                'Video Player', // Component name
                'Metadata Loaded', // Action Name
                'Video Player', // label
                {
                    value: [
                        {
                            video: video, // pro13
                        },
                    ],
                }
            );
        }

        function videoView(title) {
            clearPropsAndVars();
            var video = title.replace(/https:\/\/genome-sms\.disney\.com\/results\//g, '');
            s.prop13 = video;
            //unlike s.t(), s.tl() does not track page views
            if (angular.isFunction(s.tl)) s.tl(); // send to Omniture

            logSnowplowVideoView(video);
        }

        function logSnowplowVideoError(error) {
            return snowplowUtilsService.trackComponent(
                'Video Player', // Component type
                'Video Player', // Component name
                'Video Player Error', // Action Name
                'Video Player', // label
                {
                    value: [
                        {
                            videoError: error, // pro14
                        },
                    ],
                }
            );
        }

        function videoError(errorCode, errorMessage) {
            clearPropsAndVars();

            var value = '';
            value += deviceDetector.os + ' ' + deviceDetector.os_version;
            value += ', ' + deviceDetector.browser + ' ' + deviceDetector.browser_version;
            value += ', Error Code: ' + errorCode + ' - ' + errorMessage;

            s.prop14 = value;

            //unlike s.t(), s.tl() does not track page views
            if (angular.isFunction(s.tl)) s.tl(); // send to Omniture

            logSnowplowVideoError(value);
        }
    }
})();
