(function () {
    'use strict';

    let DEBUG = true;
    let globalIframeRef;

    function DRMSource(options) {
        let player = this;
        player.eme();

        DEBUG && console.log('VideoJS options', player.options());
        DEBUG && console.log('DRMSource plugin', options);

        let sources = options.src;
        sources = Array.isArray(options.src) ? options.src : [options.src];

        let builtSources = buildSources(options, sources);
        let selected = player.selectSource(builtSources);
        DEBUG && console.log('selected source', selected);
        if (!selected) {
            console.warn('No video type can be selected by VideoJS');
        }

        const cookieRequest = selected.source.cookieRequest;
        if (cookieRequest) {
            player.addClass('vjs-waiting');

            loadDRMCookies(cookieRequest)
                .then(() => {
                    player.removeClass('vjs-waiting');
                })
                .then(() => {
                    console.log('Setting video source', selected.source);
                    player.src(selected.source || buildSources);
                });
            return;
        } else {
            player.src(selected.source);
        }

        return;

        ///////////////////////////

        function buildSources(options, sources) {
            let authHeader = options.authToken;
            let licenseServer = options.drmSettings.licenseServerUrl;

            let results = sources.map((def) => {
                console.log({ def });
                let source = {
                    src: def.video,
                    type: def.videoType,
                    withCredentials: true,
                    cookieRequest: def.cookieRequest,
                    keySystems: Object.fromEntries(
                        def.keySystems.map((keySys) => {
                            let keyType = keySys.keyType;
                            if (keyType === 'com.apple.fps.1_0') {
                                return [
                                    keyType,
                                    {
                                        certificateUri: `${licenseServer}/cert?accountId=clipportal&url=${encodeURIComponent(
                                            keySys.certPath
                                        )}&auth=${authHeader}`,
                                        licenseUri: `${licenseServer}/la?url=${encodeURIComponent(
                                            `${keySys.licensePath}&keyId=${keySys.keyId}`
                                        )}&auth=${authHeader}&keyId=${keySys.keyId}`,
                                    },
                                ];
                            }

                            return [
                                keySys.keyType,
                                `${licenseServer}/la?url=${encodeURIComponent(
                                    keySys.licensePath
                                )}&auth=${authHeader}`,
                            ];
                        })
                    ),
                };

                return source;
            });
            DEBUG && console.log('buildSources', { sources, results, authHeader, licenseServer });
            let TYPE_ORDER = /Version\/([0-9\._]+).*Safari/.test(navigator.userAgent)
                ? ['application/x-mpegURL', 'application/dash+xml']
                : ['application/dash+xml', 'application/x-mpegURL'];

            results.sort((a, b) => {
                return TYPE_ORDER.indexOf(a.type) - TYPE_ORDER.indexOf(b.type);
            });
            return results;
        }

        function loadDRMCookies(cookieRequest) {
            return new Promise((resolve) => {
                if (globalIframeRef) {
                    document.body.removeChild(globalIframeRef);
                }

                globalIframeRef = document.createElement('iframe');
                globalIframeRef.width = '0';
                globalIframeRef.height = '0';
                globalIframeRef.src = cookieRequest;
                globalIframeRef.onload = () => {
                    // We need to execute our deferred resolve on the next browser tick
                    // to make sure that the iframe JS has executed, because otherwise
                    // we're in a race condition with it's <body onload> callback
                    setTimeout(() => {
                        resolve();
                    });
                };
                document.body.appendChild(globalIframeRef);
            });
        }
    }

    // Tells videojs about our custom drmSource plugin.
    videojs.registerPlugin('drmSource', DRMSource);
})();
