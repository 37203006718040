(function () {
    "use strict";
    // Directive for displaying a search entry as a card in a grid
    angular.module('archie.core')
        .directive('simpleGridCard', function(){
            return {
                'restrict': 'E',
                'replace': true,
                'controller': 'CardController',
                'templateUrl': 'layout/cards/simple-grid/simple-grid-card.html'
            };
        });
})();