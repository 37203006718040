(function () {
    "use strict";

    /**
     * Format date
     * @param {string} date -> '2010-03-05'
     * @returns {string} -> 'March 05, 2010'
     */
    angular.module('archie.core')
        .filter('formatDate', function(){
            return function (dateString) {
                var dateFormatRegex = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;
                var validInputFormat = dateFormatRegex.test(dateString);
                if (isNaN(Date.parse(dateString)) || !validInputFormat) {
                    return dateString;
                }
                var splitDate = dateString.split('-');
                var formattedDate = '';
                switch (splitDate[1]) {
                    case '01':
                        formattedDate += 'January';
                        break;
                    case '02':
                        formattedDate += 'February';
                        break;
                    case '03':
                        formattedDate += 'March';
                        break;
                    case '04':
                        formattedDate += 'April';
                        break;
                    case '05':
                        formattedDate += 'May';
                        break;
                    case '06':
                        formattedDate += 'June';
                        break;
                    case '07':
                        formattedDate += 'July';
                        break;
                    case '08':
                        formattedDate += 'August';
                        break;
                    case '09':
                        formattedDate += 'September';
                        break;
                    case '10':
                        formattedDate += 'October';
                        break;
                    case '11':
                        formattedDate += 'November';
                        break;
                    case '12':
                        formattedDate += 'December';
                        break;
                    default:
                        break;
                }

                formattedDate += ' ' + splitDate[2] + ', ' + splitDate[0];
                return formattedDate;
            }
        });
})();