(function (){
    "use strict";
    /**
     * Card Controller
     */
    angular.module('archie.core').controller('CardController', CardController);
    // Global controller variable to make sure each hover effect is over the other grid cards in the set
    var zIndex = 1;
    /* @ngInject */
    function CardController($scope, $location, $rootScope, $mdDialog, VideoDialog, mappings, appContainerId){

        var routeChangeListener;

        $scope.thumbnailPosition = -2;
        $scope.requestedOnce = false;
        $scope.expanded = false;
        $scope.panelOpen = false;
        $scope.dialogTitle = '';
        if($scope.asset.seriesTitle) {
            $scope.dialogTitle += $scope.asset.seriesTitle + ': ';
        }
        $scope.dialogTitle += $scope.asset.title;
        $scope.thumbnail = ($scope.asset.assetThumbnail && $scope.asset.assetThumbnail.url)
                            ? $scope.asset.assetThumbnail.url : '';

        $scope.mappings = mappings;
        $scope.series = mappings.asset['series'].prop;
        $scope.episodeTitle = mappings.asset['episodeTitle'].prop;
        $scope.type = mappings.asset['type'].prop;


        $scope.disableLibrary = !!appConfig.disableLibrary;

        /**
         * Handles routing action to the specific execution code
         * @param action
         * @param $event
         * @param data
         */
        $scope.executeAction = function (action, $event, data){
            switch (action) {
                case 'expandMetadata':
                    if($scope.expanded){
                        collapseMetadata($event);
                    } else {
                        expandMetadata($event);
                    }
                    break;
                case 'view':
                    viewAsset($event, data);
                    break;
                default:
                    // do nothing
                    break;
            }
        };

        // Attach functions to scope to make them accessible from the directive
        $scope.expandMetadata = expandMetadata;
        $scope.collapseMetadata = collapseMetadata;

        /**
         * Expands the hovered card to display additional metadata at a glance
         * @param $event
         */
        function expandMetadata($event){
            // Clear out the hoverTimeout in case one is active
            clearTimeout($scope.hoverTimeout);
            // The card is already expanded, so need to do any further actions
            if($scope.expanded) return;

            // Get the parent card element
            var elem = angular.element($event.currentTarget).closest('.grid-tile');
            // Cancel the operation if there is no parent card element
            if(elem.length === 0) return;
            // Store the width of the current card for faster referencing
            var width = $scope.width;
            var right, top, parentWidth;
            // Calculate the relative left offset of the grid element by subtracting the offset of the parent from its
            // current offset
            var left = elem.offset()['left'] - elem.offsetParent().offset()['left'];

            // Prevent the action from propagating. This is to deal with a user clicking on the the expand button
            // so that the video is accidentally played.
            $event.stopPropagation();

            // Store the width of the parent element to assist with determining the direction of expansion
            parentWidth = elem.offsetParent().width();
            // Cancel the expand operation if the expanded grid item will be wider than the view port width
            if(width*4 > parentWidth) return;

            // Continue calculating the expanded grid card width
            right = parentWidth - (left + width);
            // Get the relative top offset
            top = elem.offset()['top'] - elem.offsetParent().offset()['top'];
            if(left+width*3 > parentWidth){
                // Expand to the left so that the expanded card isn't hanging outside the view port
                elem.css({
                    'right': right + 'px',
                    'left': ''
                });
                elem.addClass('expanded-left');
            }

            // Set the width and coordinates to expand the card
            elem.css({
                'width': width*3 + 'px',
                'top': top +'px',
                'position': 'absolute',
                'zIndex': ++zIndex
            });
            // Store the expansion state for future reference
            $scope.expanded = true;
        }

        /**
         * Resets the size of the card to its non-expanded state
         * @param $event
         */
        function collapseMetadata($event){
            // Clear a hoverTimeout so the card isn't expanded later
            clearTimeout($scope.hoverTimeout);
            // The card is NOT currently expanded, so cancel any dom updated
            if(!$scope.expanded) return;
            // Get the parent card element
            var elem = angular.element($event.currentTarget).closest('.grid-tile');
            // Prevent propagation in case the user clicked the expand icon to collapse the card
            // This prevents the video from being accidentally clicked
            $event.stopPropagation();
            // Reset the width of the card to its default width
            elem.css({
                'width': $scope.width + 'px'
            });
            // Remove the class after the closing animation ends
            setTimeout(function(){
                elem.removeClass('expanded-left');
            }, 600);
            // Set the expanded flag
            $scope.expanded = false;
        }

        /**
         * Used to display the video related to the selected card entry
         * @param $event jQuery Event
         * @param type string
         */
        function viewAsset($event, type){
            if(type === 'title'){
                viewTitle($event);
            } else {
                VideoDialog.viewVideo($scope.asset, 'searchResult', $scope.dialogTitle, $scope.thumbnail, true);
            }
        }

        function viewTitle($event){
            var assetID = $scope.asset.id;
            $location.path('/library/' + encodeURIComponent($scope.asset.productionType) + '/' + encodeURIComponent(assetID)).search('');
        }

        /**
         * Opens a popup for the selected card entry that contains the full set of entry metadata
         * @param $event
         */
        $scope.viewFullMetadata = function($event){
            if($scope.panelOpen) return;

            var highlights = $scope.asset.highlights || {};
            // Create the panel config with the animation and position
            $mdDialog.show({
                parent: angular.element('#'+appContainerId),
                controller: ['MetadataMappingService', '$scope', 'asset', function(MetadataMappingService, $scope, asset){
                    // Closes the panel popup
                    $scope.asset = asset;
                    $scope.highlights = highlights || {};
                    $scope.metadata = MetadataMappingService.mapMetadata($scope.asset);
                    $scope.closeDialog = function(){
                        $mdDialog.hide();
                    };
                }],
                templateUrl: 'layout/cards/metadata-panel.html',
                clickOutsideToClose: true,
                escapeToClose: true,
                onComplete: function () {
                    $scope.panelOpen = true;
                },
                onRemoving: function(){
                    $scope.panelOpen = false;
                },
                resolve: {
                    asset: ['PreferenceUtilService', 'AssetService', function (PreferenceUtilService, AssetService){
                        var promise = AssetService.getAsset($scope.asset.id, PreferenceUtilService.getDialogueSearch());
                        promise.catch(function(){
                            $scope.panelOpen = false;
                        });
                        return promise;
                    }]
                }
            });

            routeChangeListener = $rootScope.$on('$routeChangeStart', function() {
                routeChangeListener();
                $mdDialog.hide();
            });

        };

        $scope.showTitleLink = function() {
            return !$scope.disableLibrary;
        };

        // Used to reset the data structure after the layout is changed from grid to list, or vice versa
        $rootScope.$on('UPDATE_LAYOUT', function (event){
            if ($scope.thumbnails) {
                $scope.asset.thumbnail = $scope.thumbnails[0]
            }
        });
    }

})();