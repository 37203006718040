(function () {
    "use strict";

    angular.module('archie.core')
        .directive('segmentTooltip', SegmentTooltip);

    function SegmentTooltip($timeout, $window) {
        return {
            'restrict': 'E',
            'replace': false,
            'templateUrl': 'video-dialog/segment-tooltip/segment-tooltip.html',
            'scope': {
                'data': '=',
                'player': '='
            },
            'link': function (scope, element) {

                if(!scope.player || !scope.player.el_) return;

                angular.element('body').find('segment-tooltip').remove();

                var padLeft = scope.player.el_.getBoundingClientRect().left;
                var padRight = scope.player.el_.getBoundingClientRect().width + padLeft;

                var convertSeconds = function(seconds) {
                    return new Date(seconds * 1000).toISOString().substr(11, 8);
                };

                var getPosByIndex = function () {
                    return angular.element(scope.player.el_).find('.segment[data-index="' + scope.data.index + '"]')[0].getBoundingClientRect();
                };

                scope.startTime = convertSeconds(scope.data.startTime);
                scope.endTime = convertSeconds(scope.data.endTime);
                scope.thumbnail = scope.data.thumbnail;
                scope.data.metadata = angular.copy(scope.data.metadata);
                scope.data.metadata.forEach(function (data, index) {
                    if(data.key === 'dialogue') {
                        scope.data.metadata.splice(index, 1);
                    }
                });

                if(scope.player.isFullscreen()) {
                    angular.element(scope.player.el_).append(element);
                } else {
                    angular.element('body').append(element);
                }

                $timeout(function () {
                    var pos = scope.data.pos || getPosByIndex();
                    var posRight;
                    var top = pos.top - element.outerHeight() - 12 + 'px';
                    var left = (pos.left + (pos.width/2)) - (element.outerWidth()/2);
                    var right = (pos.left + (pos.width/2)) + (element.outerWidth()/2);
                    var className = 'center';

                    if(left < padLeft) {
                        className = 'left';
                        left = Math.max(pos.left + (pos.width/2) - 12, padLeft) + 'px';
                    } else if(right >= padRight) {
                        left = 'auto';
                        className = 'right';
                        posRight = Math.max($window.innerWidth - pos.right, ($window.innerWidth - padRight)) + 'px';
                    }

                    element
                        .css({
                            'top': top,
                            'left': (posRight) ? 'auto' : left,
                            'right': posRight || 'auto',
                            'display': 'block'
                        })
                        .addClass(className);

                }, 0);
            }
        }
    }


})();