(function () {
    'use strict';

    /**
     * Component Dependencies:
     *
     * loopProgressBar
     * loopToggleButton
     * restartLoopButton
     */

    /**
     * @typedef {Object} LoopSegmentPluginOptions
     * @property {Number} start - The start time for the loop.
     * @property {Number} end - The end time for the loop.
     */

    /**
     * A plugin which sets up the events that will trigger looping, as well as the controls that control the loop.
     * @param {LoopSegmentPluginOptions} options - The options this plugin will be created with.
     */
    function loopSegmentPlugin(options) {
        var player = this;

        var loopingDisabled = false;

        if (!angular.isDefined(options.start)) return;

        //normalize start time
        var start = parseFloat(options.start).toFixed(3);

        //normalize end time
        var end = parseFloat(options.end).toFixed(3);

        // for interval function
        var timeupdate;

        function startTimeUpdate(loopToggle) {
            // using this interval function instead of videojs timeupdate event, because
            // 1) can't disable it with on seeking because
            timeupdate = setInterval(function () {
                // Every time the time is updated, if looping is enabled, we'll need to check whether we are out of
                // bounds for the loop and, if so, move us back to the start.
                if (loopToggle.checked()) {
                    var currentTime = player.currentTime();
                    // for debugging:
                    //console.log(start, currentTime, end);
                    if (currentTime < start || currentTime >= end) {
                        player.currentTime(start);
                    }
                }
            }, 250);
            // 250 per videojs 'timeupdate' event documentation:
            // Fired when the current playback position has changed
            // * During playback this is fired every 15-250 milliseconds, depending on the playback technology in use.
        }

        // Watch video's current time to see when in enters the clip segment time range.
        function watchSegmentLoop(loopToggle) {
            clearInterval(timeupdate);
            timeupdate = setInterval(function () {
                if (player.currentTime() >= start) {
                    //video's current time is now inside the range.
                    clearInterval(timeupdate);
                    startTimeUpdate(loopToggle);
                }
            }, 250);
            // 250 per videojs 'timeupdate' event documentation:
            // Fired when the current playback position has changed
            // * During playback this is fired every 15-250 milliseconds, depending on the playback technology in use.
        }

        // We need to wait until the player is set up to add our controls to the control bar, so I figured I would wrap
        // the entire section with a ready event.
        player.on('ready', function () {
            // If the start time was passed, we will start at the player at the specified time. This can't happen until
            // the metadata about the video, which includes the length, is loaded.
            if (isFinite(start)) {
                player.one('loadedmetadata', function () {
                    // start the video at start time of segment
                    setTimeout(function () {
                        // setTimeout handles a bug in Safari, where *occasionally* it tries to start playing
                        // video at time 0 after we set currentTime.  Wrapping in setTimeout seems to fix it.
                        player.currentTime(start);
                    }, 250);
                });

                // If the end time was passed, we have a loop and shall proceed with setting up the loop controls.
                if (isFinite(end)) {
                    // Both the loop toggle and the restart loop button will live in the control bar after the component
                    // "CustomControlSpacer", which is at index 8 in the control bar.
                    var controlBar = player.getChild('controlBar');

                    // This control will allow a user to enable/disable looping.
                    var loopToggle = controlBar.addChild('loopToggleButton', {}, 8);
                    controlBar.addChild(
                        'segmentTime',
                        {
                            start: start,
                            end: end,
                        },
                        9
                    );

                    // This button allows the user to go back to the beginning of the loop.
                    controlBar.addChild(
                        'restartLoopButton',
                        {
                            start: start,
                        },
                        9
                    );

                    // Unfortunately, we can't add the loop overlay until the metadata is loaded, since it needs to know the
                    // total duration of the video.
                    player.one('loadedmetadata', function () {
                        var progressControl = controlBar.getChild('progressControl');
                        var seekBar = progressControl.getChild('seekBar');
                        // This MUST exist within the seek bar in order for it to work properly.
                        seekBar.addChild('loopProgressBar', {
                            start: start,
                            end: end,
                        });
                    });

                    player.on(['pause', 'seeking', 'dispose'], function () {
                        clearInterval(timeupdate);
                    });

                    player.on(['seeked', 'play'], function () {
                        if (player.paused()) return;
                        if (player.currentTime() <= end) {
                            // if video time is before segment end time,
                            // need to watch for when it enters the segment time range.
                            watchSegmentLoop(loopToggle);
                        }
                    });

                    player.on('timeupdate', function () {
                        if (loopingDisabled) {
                            clearInterval(timeupdate);
                        }
                    });

                    player.on('toggleMetadataView', function (event, data) {
                        loopingDisabled = data.opened;
                        if (!loopingDisabled) {
                            player.trigger('seeked');
                        }
                    });
                }
            }
        });
    }

    // Tells videojs about our custom "loop segment" plugin.
    videojs.registerPlugin('loopSegment', loopSegmentPlugin);
})();
