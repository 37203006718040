import debounce from 'lodash/debounce';
import {
    trackButtonClick,
    trackComponentClick,
    trackComponent,
    DEBOUNCE_TIMEOUT,
} from '../../utils/snowplow-utils';

function snowplowPlugin() {
    var player = this;

    const handlePlayBtnClick = debounce(() => {
        const btnName = 'Video Player: Play';
        trackButtonClick(btnName, btnName);
    }, DEBOUNCE_TIMEOUT);

    const handleMuteBtnClick = debounce(() => {
        const btnName = 'Video Player: Mute';
        trackButtonClick(btnName, btnName);
    }, DEBOUNCE_TIMEOUT);

    const handleFullScreenBtnClick = debounce(() => {
        const btnName = 'Video Player: FullScreen';
        trackButtonClick(btnName, btnName);
    }, DEBOUNCE_TIMEOUT);

    const handleVolumeChange = debounce(() => {
        const ctrlName = 'Video Player: Volume Slider';
        trackComponent('Slider', ctrlName, 'Volume Change', ctrlName);
    }, DEBOUNCE_TIMEOUT);

    const handleShareLinkBtnClick = debounce(() => {
        const btnName = 'Video Player: Share Link';
        trackButtonClick(btnName, btnName);
    }, DEBOUNCE_TIMEOUT);

    const handleRestartSegmentBtnClick = debounce(() => {
        const btnName = 'Video Player: Restart Segment';
        trackButtonClick(btnName, btnName);
    }, DEBOUNCE_TIMEOUT);

    const handleCloseBtnClick = debounce(() => {
        const btnName = 'Video Player: Close';
        trackButtonClick(btnName, btnName);
    }, DEBOUNCE_TIMEOUT);

    const handleProgressBarClick = debounce(() => {
        const cmpName = 'Video Player: Progress';
        trackComponentClick('Progress Bar', cmpName, cmpName);
    }, DEBOUNCE_TIMEOUT);

    const handleMetadataViewBtnClick = debounce(() => {
        const btnName = 'Video Player: Metadata View Toggle';
        trackButtonClick(btnName, btnName);
    }, DEBOUNCE_TIMEOUT);

    const handleLoopSegmentBtnClick = debounce(() => {
        const btnName = 'Video Player: Loop Segment';
        trackButtonClick(btnName, btnName);
    }, DEBOUNCE_TIMEOUT);

    const logMissingControlError = (el: Element | null) => {
        if (!el) {
            console.error("Video Player Control wasn't found");
        }
    };

    let playBtn: HTMLButtonElement | null = null;
    let muteBtn: HTMLButtonElement | null = null;
    let fullScreenBtn: HTMLButtonElement | null = null;
    let shareLinkBtn: HTMLButtonElement | null = null;
    let restartSegmentBtn: HTMLButtonElement | null = null;
    let closeVideoBtn: HTMLButtonElement | null = null;
    let progressCtrl: HTMLDivElement | null = null;
    let metadataViewBtn: HTMLButtonElement | null = null;
    let loopSegmentBtn: HTMLButtonElement | null = null;

    player.one('ready', function () {
        const el: HTMLDivElement = player.el_;

        playBtn = el.querySelector('.vjs-play-control');
        logMissingControlError(playBtn);
        playBtn?.addEventListener('click', handlePlayBtnClick);

        muteBtn = el.querySelector('.vjs-mute-control');
        logMissingControlError(muteBtn);
        muteBtn?.addEventListener('click', handleMuteBtnClick);

        fullScreenBtn = el.querySelector('.vjs-fullscreen-control');
        logMissingControlError(fullScreenBtn);
        fullScreenBtn?.addEventListener('click', handleFullScreenBtnClick);

        shareLinkBtn = el.querySelector('.share-link.vjs-control');
        logMissingControlError(shareLinkBtn);
        shareLinkBtn?.addEventListener('click', handleShareLinkBtnClick);

        restartSegmentBtn = el.querySelector('.restart-loop.vjs-control');
        logMissingControlError(restartSegmentBtn);
        restartSegmentBtn?.addEventListener('click', handleRestartSegmentBtnClick);

        closeVideoBtn = el.querySelector('.close-video.vjs-control');
        logMissingControlError(closeVideoBtn);
        closeVideoBtn?.addEventListener('click', handleCloseBtnClick);

        progressCtrl = el.querySelector('.vjs-progress-control.vjs-control');
        logMissingControlError(progressCtrl);
        progressCtrl?.addEventListener('click', handleProgressBarClick);

        metadataViewBtn = el.querySelector('.metadata-view-button.vjs-control');
        logMissingControlError(metadataViewBtn);
        metadataViewBtn?.addEventListener('click', handleMetadataViewBtnClick);

        loopSegmentBtn = el.querySelector('.loop-segment-btn.vjs-control');
        logMissingControlError(loopSegmentBtn);
        loopSegmentBtn?.addEventListener('click', handleLoopSegmentBtnClick);

        player.on('volumechange', handleVolumeChange);
    });

    player.on('dispose', function () {
        playBtn?.removeEventListener('click', handlePlayBtnClick);

        muteBtn?.removeEventListener('click', handleMuteBtnClick);

        fullScreenBtn?.removeEventListener('click', handleFullScreenBtnClick);

        shareLinkBtn?.removeEventListener('click', handleShareLinkBtnClick);

        restartSegmentBtn?.removeEventListener('click', handleRestartSegmentBtnClick);

        closeVideoBtn?.removeEventListener('click', handleCloseBtnClick);

        progressCtrl?.removeEventListener('click', handleProgressBarClick);

        metadataViewBtn?.removeEventListener('click', handleMetadataViewBtnClick);

        loopSegmentBtn?.removeEventListener('click', handleLoopSegmentBtnClick);
    });
}

videojs.registerPlugin('snowplow', snowplowPlugin);
