(function () {
    "use strict";

    /**
     * Removes non-alphanumeric characters from String
     * @param {String} str -> String to be cleaned
     * @returns Cleaned String
     */
    angular.module('archie.core')
        .filter('cleanString', function(){
            return function(str){
                if(!angular.isString(str)) return str;
                return str.replace(/[^a-z0-9]/gi, '').trim();
            }
        });
})();