(function () {
    'use strict';

    /**
     * Component Dependencies:
     *
     * closeVideoButton
     */

    function closeVideoPlugin(options) {
        var player = this;
        player.on('ready', function () {
            // only add button if close method is defined
            if (!options.closeFn) return;

            //want close button to appear in front of everything so gave high index # (100)
            player.addChild('closeVideoButton', { closeFn: options.closeFn }, 100);
        });
    }

    videojs.registerPlugin('closeVideo', closeVideoPlugin);
})();
