(function(){
    "use strict";

    angular.module('archie.core')
        .directive('cardSetNoResults', ['FeedbackService', function(FeedbackService){
            return {
                'restrict': 'E',
                'templateUrl': 'layout/card-set/card-set-no-results.html',
                'scope': {
                  'searchText': '='
                },
                'controller': [
                    '$scope',
                    function($scope){
                        $scope.feedback = FeedbackService.sendFeedback();
                    }
                ]
            }
        }]);
})();