(function () {
    "use strict";

    // authInterceptor
    angular.module('archie.core').run(['$httpBackend', function ($httpBackend) {


        function escapeRegExp(str) {
            return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
        }

        if (appConfig.mockLoggedOut) {
            var regex = new RegExp(escapeRegExp(appConfig.discoveryServiceURL + '/') + '.*'); // any api endpoint
            $httpBackend.whenGET(regex).respond(function () {
                return [401, {'reason': 'Unauthorized'}, {}];
            });
            $httpBackend.whenPOST(regex).respond(function () {
                return [401, {'reason': 'Unauthorized'}, {}];
            });
            $httpBackend.whenPUT(regex).respond(function () {
                return [401, {'reason': 'Unauthorized'}, {}];
            });
        }

        /* NOTE:
        // don't delete, handy for testing good vs bad token responses

        regex = new RegExp(escapeRegExp(appConfig.discoveryServiceURL + '/login/user') + '.*'); // user endpoint

        //bad token
         $httpBackend.whenGET(regex).respond(function () {
            return [401, {'reason': 'Unauthorized'}, {}];
        });

        //good token
        $httpBackend.whenGET(regex).respond(function () {
            return [
                200,
                {
                    'mail': 'JANE.DOE@DISNEY.COM',
                    'username': 'doej001',
                    'exp': 1481150875,
                    'scope': [
                        'openid',
                        'email',
                        'profile'
                    ],
                    'client_id': 'genome_dev_client',
                    'iss': 'efs.disney.com'
                },
                {}
            ];
        });

        */

        // let requests pass through;
        $httpBackend.whenGET().passThrough();
        $httpBackend.whenPOST().passThrough();
        $httpBackend.whenPUT().passThrough();
    }]);


})();