(function() {
    "use strict";

    angular.module('archie.core').factory('FacetSearchDialog', FacetSearchDialog);

    function FacetSearchDialog($mdDialog, appContainerId) {

        var dialog =  {
            'isShowing': false
        };

        return {
            showDialog: showDialog,
            closeDialog: closeDialog,
            dialog: dialog
        };

        function showDialog(facet, callback) {
            if (dialog.isShowing) return;

            $mdDialog.show({
                'parent': angular.element('#'+appContainerId),
                'templateUrl': 'layout/card-filters/facet-search/facet-search-dialog.html',
                'clickOutsideToClose': true,
                'controller': FacetSearchDialogController,
                'locals': {
                    'facet': facet,
                    'callback': callback
                },
                'onRemoving': function () {
                    dialog.isShowing = false;
                },
                'onComplete': function () {
                    dialog.isShowing = true;
                }
            });
        }

        function closeDialog() {
            $mdDialog.hide();
        }

    }

    angular.module('archie.core').controller('FacetSearchDialogController', FacetSearchDialogController);

    function FacetSearchDialogController($scope, FacetSearchDialog, FacetService, facet, callback, SearchUtil) {
        $scope.searchText = {
            'value': ''
        };
        $scope.facetIndex = FacetService.getAvailableFacets().indexOf(facet);
        $scope.facetId = facet.id;
        $scope.facetLimit = appConfig.facetLimit;
        $scope.title = facet.name;
        $scope.options = facet.availableOptions;
        $scope.callback = callback;
        $scope.sort = facet.sort;
        $scope.updateOnChange = SearchUtil.getMatchAllFacetOptions();

        $scope.items = angular.copy($scope.options) || [];
        $scope.updatedItems = [];
        $scope.hasSelectedItems = false;
        $scope.hasPreviouslySelectedItems = $scope.items.filter(function (item) {
           return item.isSelected;
        }).length;

        $scope.closeDialog = function () {
            FacetSearchDialog.closeDialog();
        };

        $scope.submit = function () {
            if(angular.isFunction(callback)) callback($scope.updatedItems);
            FacetSearchDialog.closeDialog();
        };

        $scope.deselectAll = function () {
            angular.forEach($scope.items, function (item) {
               if(item.isSelected) {
                   item.isSelected = false;
                   $scope.updatedItems.push(item);
               }
            });
            $scope.submit();
        };

        $scope.getItemsFromBackend = function(updatedData) {
            if($scope.searchText.value !== '') {
                return FacetService.getFacetsFromSearchText(FacetService.getAvailableFacets(), $scope.searchText.value).then(function(facetResponse) {
                    var facet = FacetService.getFacetById($scope.facetId, facetResponse);
                    return angular.isObject(facet) ? facet.availableOptions : [];
                });
            } else {
                var availableFacets;
                var facetCopy;
                // if user has selected checkbox, create an updated version of selected options
                // use that to create the api request via FacetService method.
                if(updatedData) {                  
                    facetCopy = angular.copy(facet); 
                    angular.forEach(facetCopy.availableOptions, function(option) {
                        angular.forEach(updatedData, function(item) {
                            if(option.optionName === item.optionName) {
                                option.isSelected = item.isSelected;
                            }
                        });
                    });
                    availableFacets = angular.copy(FacetService.getAvailableFacets());
                    // replace existing facet options with new updated list.
                    availableFacets.splice($scope.facetIndex, 1, facetCopy);
                }
                return FacetService.getFacetsFromBackend($scope.facetId, availableFacets).then(function (facetResponse) {
                    availableFacets = facetCopy = null;
                    return facetResponse.availableOptions;
                });
            }
        };

        $scope.$watch(function () {
            return FacetSearchDialog.dialog.isShowing;
        }, function (isShowing) {
            $scope.focusInput = isShowing;
        });

        $scope.$watch('updatedItems', function (updatedItems) {
            var arr = $scope.items.concat(updatedItems);
            $scope.hasSelectedItems = arr.filter(function (item) {
                return item.isSelected === true;
            }).length;
        }, true);

    }


})();