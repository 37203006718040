(function () {
    "use strict";

    angular.module('archie.core')
        .filter('highlightSearchResult',  ['$sce', function ($sce) {

            /**
             * Replaces a result set with all highlighted text for a given property
             *
             * Maintains the non-highlighted results as well
             */
            return function (resultArray, highlights, property, partialReplace) {

                highlights = highlights || {};
                // Force the set of results into array for simpler iteration
                if(!angular.isArray(resultArray)){
                    resultArray = [resultArray];
                }
                // Set default value of highlighted text to an empty array
                var highlightArray = highlights[property] || [];

                // Store the raw text (no highlighted tags) for array lookup
                function getRawText(str) {
                    return str.replace(/\<\/?highlighted\>/g, '');
                }

                function getHighlightRegex(highlight) {
                    return '\\b' + highlight + '([^\'\\w]|(?!\'?\\w))';
                }

                // currently used for property = 'dialogue'
                function resultPartialReplace() {
                    // do only if highlights exist
                    if(highlightArray.length) {
                        highlightArray.forEach(function(highlight) {
                            // Note that the spaces below will be captured as part of the replace, so you need to
                            // remember to include them as part of the replace using '$1'.
                            var insensitiveRegex = new RegExp(getHighlightRegex(highlight), 'gi');
                            var caseSensitiveMatches = [];
                            var matchArray;
                            do {
                                matchArray = insensitiveRegex.exec(resultArray[0]);
                                if (matchArray !== null) {
                                    var caseSensitiveMatch;
                                    if (matchArray[1].length === 0) {
                                        caseSensitiveMatch = matchArray[0];
                                    } else {
                                        caseSensitiveMatch = matchArray[0].replace(matchArray[1], "");
                                    }
                                    if (caseSensitiveMatches.indexOf(caseSensitiveMatch) === -1) {
                                        caseSensitiveMatches.push(caseSensitiveMatch);
                                        var caseSensitiveRegex = new RegExp(getHighlightRegex(caseSensitiveMatch), 'g');
                                        resultArray = [resultArray[0].replace(caseSensitiveRegex, '<highlighted>' + caseSensitiveMatch + '</highlighted>$1')];
                                    }
                                }
                            } while (matchArray !== null);
                        });
                    }
                }


                // used for other property types
                function resultReplace() {
                    // Loop through all of the highlighted text and replace the base text with it
                    highlightArray.forEach(function(highlight){
                        // Find the index of the raw text in the result set
                        var baseIndex = resultArray.indexOf(getRawText(highlight));
                        // Set the result item to the highlight text if it was found in the result array
                        if(baseIndex !== -1){
                            resultArray[baseIndex] = highlight;
                        }
                    });
                }

                (partialReplace === true) ? resultPartialReplace() : resultReplace();

                // Return the updated results, joined to a string
                // If there are no results, then set a default message
                // Finally, wrap the entire thing in a trust so it can be rendered as HTML
                return $sce.trustAsHtml(resultArray.join(', ') || 'No ' + property.toLowerCase() + ' set');
            };
        }]);
})();