(function () {
    'use strict';

    angular.module('archie.core')
        .constant('TemplateMocks',
            [
                {
                    "type" : "series",
                    "relatedContent": {
                        "label": "Episodes",
                        "type": "episodes",
                        "key": "series",
                        "id": "seriesId",
                        "properties": {
                            "col": 4,
                            "colFlow": "leftToRight",
                            "isExpanded": true
                        }
                    },
                    "title": "seriesTitle",
                    "titleTypeLabel": "Series",
                    "thumbnailUrl": "thumbnailFileUrl",
                    "flatten": "libraryData",
                    "detailsGroups" : [{
                        "label" : "Series Snapshot",
                        "properties" : [
						{
                            "label" : "AKA",
                            "prop" : "akaTitle"
                        },
						{
                            "label" : "Years Running",
                            "prop" : "yearsRunning"
                        },
                        {
                            "label" : "No. of Seasons",
                            "prop" : "airedSeasonsCount"
                        },
						{
                            "label" : "Genre",
                            "prop" : "genres"
                        },
                        {
                            "label" : "Presentation",
                            "prop" : "presentation"
                        },			
                        {
                            "label" : "Network",
                            "prop" : "network"
                        },	
                        {
                            "label" : "Production Co.",
                            "prop" : "productionCompany"
                        },
						{
                            "label": "Radar ID",
                            "prop": "radar"
                        },
                        {
                            "label": "MDM ID",
                            "prop": "mdmId"
                        }]
                    }],
                    "additionalInfoGroups": [{
                        "label": "Series Info",
                        "display": "chip",
                        "properties": [
                            {
                                "label": "Time/ERA",
                                "prop": "era"
                            },
                            {
                                "label": "Setting",
                                "prop": "setting"
                            },
                            {
                                "label": "Generation",
                                "prop": "generation"
                            },
                            {
                                "label": "Scope/Scale",
                                "prop": "scale"
                            },
                            {
                                "label": "Disciplines",
                                "prop": "disciplineOrIndustry"
                            },
                            {
                                "label": "Agents",
                                "prop": "agent"
                            }
                        ]
                    }],
                    "metadataGroups" : [
                        {
                            "label" : "Characters",
                            "prop" : "characters",
                            "type": "arrayList",
                            "display": "list",
                            "sort": {
                                "prop": "episodeAppearances",
                                "type": "number",
                                "order": "descending",
                                "minWeight": 5,
                                "weightCapMessage": {
                                    "text": "Additional characters in this series may be found on their relevant episode pages.",
                                }
                            },
                            "col": 4,
                            "colFlow": "leftToRight",
                            "properties": {
                                "value": "characterName"
                            }
                        },
                        {
                            "label" : "Locations",
                            "prop" : "locations",
                            "type": "objectKeys",
                            "display": "collapseable",
                            "isExpanded": true,
                            "replace": {
                                "-location": "",
                                "-": " " 
                            },
                            "col": 3,
                            "properties": {
                                "label": "locationTypeName",
                                "value": "locationName"
                            }
                        }

                    ], 
                    "tabs": [
                        // NOTE: don't delete, re-enable when we have data for this
                        /*
                        {
                            "id": "additionalInfo",
                            "type": "additionalInfo"
                        },
                        */
                        {
                            "id": "relatedContent",
                            "type": "relatedContent"
                        },
                        {
                            "id": "characters",
                            "type": "metadataGroups",
                            "include": "characters"
                        }
                    ]
                },
                {
                    "type" : "episodes",
                    "relatedContent": {
                        "label": "More Episodes",
                        "type": "episodes",
                        "key": "series",
                        "id": "seriesId",
                        "properties": {
                            "col": 4,
                            "colFlow": "leftToRight",
                            "isExpanded": true
                        }
                    },
                    "title": "episodeTitle",
                    "titleTypeLabel": "Episode",
                    "titleNo": "airOrderNumber",
                    "seasonNo": "seasonNumber",
                    "thumbnailUrl": "thumbnailFileUrl",
                    "hasVideo": true,
                    "parentTitle": {
                        "id": "seriesId",
                        "title": "seriesTitle",
                        "type": "series",
                        "label": "Series"
                    },
                    "detailsGroups": [{
                        "label": "Episode Snapshot",
                        "properties": [{
                            "label": "Original Air Date",
                            "prop": "originalAirDate"
                        }, {
                            "label": "Rating",
                            "prop": "rating"
                        }, {
                            "label": "Radar ID",
                            "prop": "radarProductId"
                        }, {
                            "label": "EIDR ID",
                            "prop": "eidr"
                        },
                        {
                            "label": "MDM ID",
                            "prop": "mdmId"
                        }]
                    }],
                    "metadataGroups": [
                        {
                            "label" : "Storylines",
                            "prop" : "storylines",
                            "display": "table",
                            "type": "tabular",
                            "sort": {
                                "prop": "storylineWeight"
                            },
                            "properties": [
                                {"label": "Weight", "prop": "storylineWeight", "width": "100", "name":"weight"},
                                {"label": "Description", "prop": "storylineLabel", "name":"description"}
                            ]
                        },
                        {
                            "label" : "Cast & Characters - BETA",
                            "prop" : "characters",
                            "type" : "tabular",
                            "display" : "table",
                            "compiledData" : true,
                            "sort" : {
                                "prop" : "characterName"
                            },
                            "properties" : [
                                {"label" : "Cast", "prop" : "portrayedBy", "list" : true, "value" : "talentName"},
                                {"label" : "Character", "prop" : "characterName", "list" : false}
                            ]
                        },
                        {
                            "label" : "Locations",
                            "prop" : "locations",
                            "type": "objectKeys",
                            "display": "collapseable",
                            "isExpanded": true,
                            "replace": {
                                "-location": "",
                                "-": " " 
                            },
                            "col": 3,
                            "properties": {
                                "label": "locationTypeName",
                                "value": "locationName"
                            }
                        }
                    ],
                    compileData: [
                        {
                            "label": "Characters",
                            "prop": "characters",
                            "type": "arrayList",
                            "containsProperties": [
                                {
                                    "prop": "characters",
                                    "type": "arrayList",
                                    "properties": [
                                        "characterName",
                                        "portrayedBy"
                                    ]
                                }
                            ]
                        }
                    ],
                    "tabs": [
                        {
                            "id": "relatedContent",
                            "type": "relatedContent"
                        },
                        {
                            "id": "storylines",
                            "type": "metadataGroups",
                            "include": "storylines"
                        },
                        {
                            "id": "characters",
                            "type": "metadataGroups",
                            "include": "characters"
                        }
                    ]
                },
                {
                    "type" : "features",
                    "title": "featureTitle",
                    "titleTypeLabel": "Feature",
                    "thumbnailUrl": "thumbnailFileUrl",
                    "flatten": "libraryData",
                    "hasVideo": true,
                    "detailsGroups" : [{
                        "label" : "Feature Snapshot",
                        "properties" : [
                            {
                                "label" : "Original Premiere Date",
                                "prop" : "originalPremiereDate"
                            },
                            {
                                "label" : "Rating",
                                "prop" : "rating"
                            },
                            {
                                "label" : "Copyright",
                                "prop" : "Copyright"
                            },
                            {
                                "label" : "Production Co.",
                                "prop" : "productionCompany"
                            },
                            {
                                "label" : "EIDR ID",
                                "prop" : "eidr"
                            },
                            {
                                "label": "MDM ID",
                                "prop": "mdmId"
                            },
                            {
                                "label" : "Genre",
                                "prop" : "genres"
                            },
                            {
                                "label" : "Setting",
                                "prop" : "settings"
                            },
                            {
                                "label" : "Time/Era",
                                "prop" : "time_era"
                            },
                            {
                                "label" : "Disciplines/Industries",
                                "prop" : "disciplines_industries"
                            },
                            {
                                "label" : "Agents",
                                "prop" : "agents"
                            }
                        ]
                    }],
                    "additionalInfoGroups": [{
                        "label": "Feature Info",
                        "display": "chip",
                        "properties": []
                    }],
                    "metadataGroups" : [
                        {
                            "label" : "Cast & Characters - BETA",
                            "prop" : "characters",
                            "type" : "tabular",
                            "display" : "table",
                            "compiledData" : true,
                            "sort" : {
                                "prop" : "characterName"
                            },
                            "properties" : [
                                {"label" : "Cast", "prop" : "portrayedBy", "list" : true, "value" : "talentName"},
                                {"label" : "Character", "prop" : "characterName", "list" : false}
                            ]
                        },
                        {
                            "label" : "Storylines",
                            "prop" : "storylines",
                            "display": "table",
                            "type": "tabular",
                            "sort": {
                                "prop": "storylineWeight"
                            },
                            "properties": [
                                {"label": "Plot Type", "prop": "storylineComponents", "name":"plotType"},
                                {"label": "Storylines", "prop": "storylineLogLine", "name":"storylines"},
                                {"label": "Lead Character", "prop": "storylineLeadCharacter", "width":"15%", "name":"leadCharacter"},
                                {"label": "Story Archetypes", "prop": "storylineArchetypes", "name":"storyArchetypes"}
                            ],
                            "storyRoles": {
                                "name":"storyRoles",
                                "display": "table",
                                "type": "tabular",
                                "properties": [
                                    {"label": "Character", "prop": "characterLabel", "name":"character"},
                                    {"label": "Archetype", "prop": "Archetype", "prefix": ["starting", "ending", "throughout" ], "name":"archetype"},
                                    {"label": "Motivation", "prop": "Motivation", "prefix": ["starting", "ending", "throughout" ], "name":"motivation"},
                                    {"label": "Trait", "prop": "Trait", "prefix": ["starting", "ending", "throughout" ], "name":"trait"}
                                ]
                            }
                        },
                        {
                            "label" : "Story Objects",
                            "prop" : "concepts",
                            "type" : "arrayList",
                            "display" : "list",
                            "compiledData" : true,
                            "sort" : {
                                "prop" : "conceptLabel"
                            },
                            "col": 1,
                            "properties": {
                                "value": "conceptLabel"
                            }
                        }
                    ],
                    compileData: [
                        {
                            "label": "Characters",
                            "prop": "characters",
                            "type": "arrayList",
                            "containsProperties": [
                                {
                                    "prop": "characters",
                                    "type": "arrayList",
                                    "properties": [
                                        "characterName",
                                        "portrayedBy"
                                    ]
                                }
                            ]
                        },
                        {
                            "label": "Story Objects",
                            "prop": "concepts",
                            "type": "arrayList",
                            "containsProperties": [
                                {
                                    "prop": "concepts",
                                    "type": "objectKeys",
                                    "properties": [
                                        "storyObjects"
                                    ]
                                },
                                {
                                    "prop": "storyObjects",
                                    "parent": "compiled",
                                    "type": "arrayList",
                                    "properties": []
                                }
                            ]
                        }
                    ],
                    "tabs": [
                        {
                            "id": "storylines",
                            "type": "metadataGroups",
                            "include": "storylines"
                        },
                        {
                            "id": "characters",
                            "type": "metadataGroups",
                            "include": "characters"
                        },
                        {
                            "id": "storyObjects",
                            "type": "metadataGroups",
                            "include": "concepts"
                        }
                    ]
                }
            ]
        );
})();