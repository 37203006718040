(function () {
    "use strict";

    angular.module('archie.core')
        .controller('GenSearchMenuController', GenSearchMenuController);

    function GenSearchMenuController($scope, $location, $document, $window, $timeout, SearchUtil, OmnitureService, keyCodes) {

        var menuEl;
        var inputEl;
        var dialogueMenuItemEl;
        // make a copy of panel's config onRemoving function
        var onRemoving = $scope.panelRef._config.onRemoving;
        var searchPath = '/video-dialogue';
        var keyEvent = 'keydown';

        // store which index of menu list item has focus
        $scope.selectedIndex = 0;

        $scope.init = function () {
            menuEl = $scope.panelRef._panelEl;
            // the search text input element:
            inputEl = $scope.container.find('input');
            dialogueMenuItemEl = angular.element('#genSearchDialogue').find('button');
            // angular material puts an invisible DIV between open panel and rest of screen to capture click events
            // need to hide this so user can interact with other elements while menu is open, adding class so can style it.
            menuEl.parent().addClass('gen-search-menu-wrapper');
            // match width of input
            menuEl.width(inputEl.outerWidth())
            .css({
                // make sure menu height doesn't extend off screen.
                // don't want to extend menu to very bottom of screen, so use 90% of window height for our calculation
                'max-height': (angular.element($window).height() * .9) - ($scope.container.offset().top + inputEl.outerHeight())
            });
            // add event handlers
            $document.on(keyEvent, handleKeyEvent);
            inputEl.on('focus', handleInputFocus);
            inputEl.on('blur', handleInputBlur);
            // dialogue search is the default selected item
            // so if search input has focus, and user tabs out
            // want to skip over dialogue search menu item
            dialogueMenuItemEl.attr('tabindex', '-1');
        };

        $scope.dialogueSearch = function () {
            $scope.panelRef.close();
            SearchUtil.setSearchText($scope.searchText.value);
            $location.path(searchPath + '/' + encodeURIComponent($scope.searchText.value));
            OmnitureService.dialogueSearch($scope.searchText.value);
        };

        $scope.selectItem = function(item, option) {
            $scope.panelRef.close();
            var searchHash = {};
            // NOTE: don't delete, may want to display text of what item user selected in menu
            // inside the text input, waiting for feedback.
            // $scope.searchText.value = item.name + ': ' + option.optionName;
            searchHash[item.id] = option.optionName;
            $location.path(searchPath).search(searchHash);
            OmnitureService.facetSearch(item.name, option.optionName);
        };

        // overwrite panel config's onRemoving function so we can remove document event listeners
        $scope.panelRef._config.onRemoving = function () {
            $document.off(keyEvent, handleKeyEvent);
            inputEl.off('focus', handleInputFocus);
            inputEl.off('blur', handleInputBlur);
            // be sure to include the original function saved in var
            onRemoving();
        };

        function handleKeyEvent($event) {
            // event handlers so user can use arrow keys to change menu item focus
            switch ($event.which) {
                case keyCodes.ARROW_DOWN:
                    handleArrow($event);
                    break;
                case keyCodes.ARROW_UP:
                    handleArrow($event, 'up');
                    break;
                case keyCodes.TAB:
                    // $timeout ensures new focus has been set
                    $timeout(function () {
                        handleTab($event);
                    });
                    break;
                case keyCodes.ENTER:
                    // dialogue search is the default
                    if($scope.selectedIndex === 0) {
                        $scope.dialogueSearch();
                    }
                    break;
            }
        }

        // returns array of selectable menu dom elements
        function listItems() {
            return $scope.panelRef._panelEl.find('md-list-item');
        }

        function handleArrow($event, up) {
            // so up/down arrows will function like TAB/SHIFT+TAB,
            // selecting next/previous menu item
            $event.preventDefault();
            $scope.selectedIndex = up
                ? Math.max($scope.selectedIndex - 1, 0)
                : Math.min($scope.selectedIndex + 1, listItems().length - 1);
            angular.element(listItems()[$scope.selectedIndex]).find('button').focus();
            $scope.$apply();
        }

        function handleTab($event) {
            // updates selectedIndex prop with index of selected menu item
            // when user uses tab key to scroll through menu
            // document.activeElement = what has focus
            var selectedItem = angular.element($document[0].activeElement).parent()[0];
            angular.forEach(listItems(), function (listItem, index) {
                if(listItem === selectedItem) {
                    $scope.selectedIndex = index;
                }
            });
            $scope.$apply();
        }

        function handleInputFocus($event) {
            $scope.selectedIndex = 0;
            dialogueMenuItemEl.attr('tabindex', '-1');
            menuEl[0].scrollTop = 0;
            $scope.$apply();
        }

        function handleInputBlur($event) {
            // restore tabindex to dialogue search menu item on search input blur
            dialogueMenuItemEl.removeAttr('tabindex');
        }

    }

})();