(function () {
    "use strict";

    angular.module('archie.core')
        .directive('tabMetadata', function(){
            return {
                'restrict': 'E',
                'replace': true,
                'templateUrl': 'library/tab-metadata/tab-metadata.html',
                'scope': {
                    'assetLabel': '<',
                    'metadataGroups': '=',
                    'selectFn': '&',
                    'tabSelectFn': '&',
                    'include': '=?'
                }
            };
        });
})();