/**
 * Component for adding X close button to upper right corner of player
 */

const ButtonComponent = videojs.getComponent('Button');

class CloseVideoButtonComponent extends ButtonComponent {
    constructor(player, options) {
        super(player, options);
        this.controlText('Close Video');
    }

    buildCSSClass() {
        return 'close-video ' + ButtonComponent.prototype.buildCSSClass.apply(this, arguments);
    }

    handleClick() {
        //call the method passed via the plugin
        this.options_.closeFn();
    }
}

videojs.registerComponent('CloseVideoButton', CloseVideoButtonComponent);
