(function () {
    "use strict";

    // History Service
    angular.module('archie.core').factory('HistoryService', HistoryService);

    /**
     *
     * @returns {{addRoute: addRoute, setLastSearch: setLastSearch, getLastSearch: getLastSearch}}
     *
     */
    function HistoryService() {

        var preface = 'archie:';
        var lastSearch = initLastSearch();

        return {
            addRoute: addRoute,
            setLastSearch: setLastSearch,
            getLastSearch: getLastSearch
        };

        /**
         * @param route (angular $route object)
         */
        function addRoute(route) {
            if(!route || !route.$$route) return;
            var routeDetails = route.$$route.details || {};
            switch (routeDetails.type) {
                case 'search':
                    if(!angular.equals(route.params, {})) {
                        setLastSearch(route);
                    }
                    break;
            }
        }

        /**
         * @returns {string}
         */
        function initLastSearch() {
            return JSON.parse(sessionStorage.getItem(preface+'search')) || '';
        }

        /**
         * @param route (angular $route object)
         */
        function setLastSearch(route) {
            var path = getPathFromRoute(route);
            lastSearch = {
                'label': route.$$route.details.label,
                'path': path
            };
            sessionStorage.setItem(preface+'search', JSON.stringify(lastSearch));
        }

        /**
         * @returns {object} lastSearch
         */
        function getLastSearch() {
            return lastSearch;
        }

        /**
         * @param route (angular $route object)
         * @returns {string}
         */
        function getPathFromRoute(route) {
            // all params (path and querystring):
            var params = angular.copy(route.params);
            // just path params:
            var pathParams = angular.copy(route.pathParams);
            // route's path value ex: '/video-dialogue/:searchText':
            var path = route.$$route.originalPath;
            var queryParams = [];
            var queryString = '';

            angular.forEach(pathParams, function (value, key) {
                // remove pathParams from params array
                delete params[key];
                // place the pathParam value in path string
                path = path.replace(':'+key, encodeURIComponent(value));
            });

            angular.forEach(params, function (value, key) {
                // create a queryString params array
                if(angular.isArray(value)) {
                    angular.forEach(value, function (v) {
                        queryParams.push(key+'='+encodeURIComponent(v));
                    })
                } else {
                    queryParams.push(key+'='+encodeURIComponent(value));
                }
            });

            if(queryParams.length) {
                queryString += '?' + queryParams.join('&');
            }

            return path + queryString;
        }

    }

})();