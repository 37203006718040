(function () {
    'use strict';

    angular
        .module('archie.core')
        .factory('CreativeWorkControllerInitialData', CreativeWorkControllerInitialData);

    /**
     *
     * @param AssetService
     * @returns {Function}
     * @constructor
     */
    function CreativeWorkControllerInitialData(AssetService) {
        return function (assetType, id) {
            return AssetService.getAssetByTypeAndId(assetType, id)
                .then(function (assetsResponse) {
                    return assetsResponse;
                })
                .catch(function (errorResponse) {
                    return false;
                });
        };
    }

    angular
        .module('archie.core')
        .factory('CreativeWorkControllerOpenAsset', CreativeWorkControllerOpenAsset);
    function CreativeWorkControllerOpenAsset(
        $location,
        $timeout,
        $route,
        AssetService,
        MessageConsole,
        VideoDialog
    ) {
        return function () {
            // param for video deeplinking
            var openAsset = $location.search().openAsset;
            var messageData = {
                msg: 'Video could not be opened. Invalid video clip URL.',
                type: 'e',
            };
            // no param exists
            if (!openAsset) return null;
            // handle error in case url is altered
            try {
                openAsset = JSON.parse(atob(openAsset));
            } catch (e) {
                $timeout(function () {
                    MessageConsole.displayMessage(messageData);
                    VideoDialog.removeOpenAssetParam();
                }, 500);
                return null;
            }
            var assetType = $route.current.pathParams.assetType;
            return AssetService.getAssetByTypeAndId(assetType, openAsset.id)
                .then(function (asset) {
                    asset.startTime = openAsset.startTime;
                    asset.endTime = openAsset.endTime;
                    return asset;
                })
                .catch(function (errorResponse) {
                    VideoDialog.removeOpenAssetParam();
                    return null;
                });
        };
    }

    angular.module('archie.core').controller('CreativeWorkController', CreativeWorkController);

    /**
     *
     * @param $scope
     * @param asset
     * @param assetType
     * @param id
     * @param TemplateService
     * @param AssetService
     */
    function CreativeWorkController(
        $scope,
        $timeout,
        asset,
        assetType,
        id,
        openAsset,
        TemplateService,
        AssetService,
        VideoDialog,
        OmnitureService
    ) {
        $scope.asset = asset;
        $scope.assetType = assetType;
        $scope.id = id;
        $scope.template = TemplateService.getTemplateByType(assetType);
        $scope.relatedContent = !!$scope.template.relatedContent
            ? {
                  label: $scope.template.relatedContent.label,
                  type: $scope.template.relatedContent.type,
                  key: $scope.template.relatedContent.key,
                  properties: $scope.template.relatedContent.properties,
                  id: $scope.asset[$scope.template.relatedContent.id],
                  expanded: false,
              }
            : {};
        $scope.relatedContentType = $scope.template.relatedContentType;
        $scope.relatedContentKey = $scope.template.relatedContentKey;
        $scope.relatedContentId = $scope.asset[$scope.template.relatedContentId];
        $scope.title = $scope.asset[$scope.template.title];
        $scope.dialogTitle = $scope.title;
        $scope.thumbnailUrl = $scope.asset[$scope.template.thumbnailUrl];
        $scope.index = 0;
        $scope.indicatorPosition = 0;
        $scope.titleTypeLabel = $scope.template.titleTypeLabel;
        $scope.titleNo = $scope.asset[$scope.template.titleNo];
        $scope.seasonNo = $scope.asset[$scope.template.seasonNo];
        $scope.hasVideo = $scope.template.hasVideo;
        $scope.tabs = $scope.template.tabs;
        $scope.compiledData = {};

        $timeout(function () {
            var selectedTabIndex = $scope.tabs
                .map(function (e) {
                    return e.isSelected;
                })
                .indexOf(true);
            $scope.selectedTabIndex = selectedTabIndex > 0 ? selectedTabIndex : 0;
        });

        if ($scope.template.parentTitle) {
            $scope.parentTitle = {
                id: $scope.asset[$scope.template.parentTitle.id],
                title: $scope.asset[$scope.template.parentTitle.title],
                type: $scope.template.parentTitle.type,
                label: $scope.template.parentTitle.label,
            };
            $scope.dialogTitle = $scope.parentTitle.title + ': ' + $scope.dialogTitle;
        }
        // url contains video deeplink, open video.
        if (openAsset) {
            var dialogTitle = '';
            if (openAsset.seriesTitle) {
                dialogTitle += openAsset.seriesTitle + ': ';
            }
            if (openAsset.featureTitle) {
                dialogTitle += openAsset.featureTitle + ': ';
            } else {
                dialogTitle += openAsset.title;
            }
            var thumbailUrl = openAsset.thumbnailFileUrl
                ? openAsset.thumbnailFileUrl
                : openAsset.assetThumbnail.url;
            VideoDialog.viewVideo(openAsset, $scope.assetType, dialogTitle, thumbailUrl, true);
        }

        omniturePageView();
        buildCompiledData();
        buildDetailsData();
        buildMetadataGroups();

        $scope.viewIndex = function (index) {
            $scope.index = index;
        };

        $scope.calcOffset = function () {
            var offset = '0';
            if ($scope.index) {
                offset = '-' + $scope.index + '00%';
            }
            return offset;
        };

        $scope.isActiveDetailIndex = function (index) {
            return $scope.index === index;
        };

        $scope.expandMetadata = function (metadata) {
            if ($scope.asset[metadata.prop]) return;
            var params = [
                {
                    key: 'detailType',
                    value: metadata.prop,
                },
            ];
            metadata.loading = true;
            AssetService.getAssetByTypeAndId($scope.assetType, $scope.id, params).then(function (
                asset
            ) {
                $scope.asset = angular.merge($scope.asset, asset);
                buildCompiledData();
                buildDetailsData();
                buildMetadataGroups();
                metadata.loading = false;
            });
        };

        $scope.showDialog = function () {
            VideoDialog.viewVideo(
                $scope.asset,
                $scope.assetType,
                $scope.dialogTitle,
                $scope.thumbnailUrl,
                true
            );
        };

        $scope.selectTab = function (tabId) {
            $scope.selectedTabIndex = $scope.tabs
                .map(function (e) {
                    return e.id;
                })
                .indexOf(tabId);
        };

        function omniturePageView() {
            var pageName = $scope.title;
            if ($scope.parentTitle) {
                pageName += ' (' + $scope.parentTitle.title + ')';
            }
            OmnitureService.libraryPageView($scope.titleTypeLabel, pageName);
        }

        function buildCompiledData() {
            if ($scope.template.compileData && !isNullOrEmpty($scope.template.compileData)) {
                $scope.template.compileData.forEach(function (item) {
                    $scope.compiledData[item.prop] = $scope.asset[item.containsProperties[0].prop]
                        ? compileData(item)
                        : [];
                });
            }
        }

        /**
         * Compiles multiple pieces of data into one place
         * @param item the template item representing the data to be compiled
         * @returns array or object of compiled data
         */
        function compileData(item) {
            var compiled;
            if (item.type === 'arrayList') {
                var root;
                item.containsProperties.forEach(function (prop) {
                    var obj = {};
                    if (!!prop.parent && prop.parent === 'compiled') {
                        root = compiled[prop.prop];
                    } else {
                        root = $scope.asset[prop.prop];
                    }

                    if (prop.type === 'arrayList') {
                        if (isNullOrEmpty(prop.properties)) {
                            obj = root;
                        } else {
                            var compilingList = [];
                            root.forEach(function (dataItem) {
                                var buildingBlock = {};
                                prop.properties.forEach(function (targetProp) {
                                    buildingBlock[targetProp] = dataItem[targetProp];
                                });
                                compilingList.push(buildingBlock);
                            });
                            obj = compilingList;
                        }
                    } else if (prop.type === 'objectKeys') {
                        prop.properties.forEach(function (targetProp) {
                            obj[targetProp] = root[targetProp];
                        });
                    }
                    compiled = obj;
                });
            } else if (item.type === 'objectKeys') {
                item.containsProperties.forEach(function (prop) {
                    var root = $scope.asset[prop.prop];
                    if (prop.type === 'arrayList') {
                        root.forEach(function (dataItem) {
                            if (isNullOrEmpty(prop.properties)) {
                                compiled = root;
                            } else {
                                prop.properties.forEach(function (targetProp) {
                                    compiled[targetProp] = dataItem[targetProp];
                                });
                            }
                        });
                    } else if (prop.type === 'objectKeys') {
                        if (isNullOrEmpty(prop.properties)) {
                            compiled = root;
                        } else {
                            prop.properties.forEach(function (targetProp) {
                                compiled[targetProp] = root[targetProp];
                            });
                        }
                    }
                });
            } else {
                compiled = [];
            }
            return compiled;
        }

        function buildDetailsData() {
            $scope.details = $scope.template.detailsGroups.map(buildDataGroup);
            if ($scope.template.additionalInfoGroups) {
                $scope.additionalInfo = $scope.template.additionalInfoGroups.map(buildDataGroup);
            }
        }

        function buildDataGroup(group) {
            return {
                label: group.label,
                display: group.display,
                properties: group.properties.map(function (prop) {
                    return {
                        label: prop.label,
                        value: group.prop
                            ? $scope.asset[group.prop][prop.prop]
                            : $scope.asset[prop.prop],
                        display: prop.display,
                    };
                }),
            };
        }

        function buildMetadataGroups() {
            $scope.buildSection = 'metadataGroups';
            $scope.metadataGroups = $scope.template.metadataGroups.map(mapProperties);
        }

        function mapProperties(item) {
            var asset = item.compiledData ? $scope.compiledData : $scope.asset;
            // from library template json
            var map = {
                label: item.label,
                prop: item.prop,
                isSelected: !!item.isSelected,
                isExpanded: !!item.isExpanded,
                type: item.type,
                display: item.display,
                sort: item.sort,
                col: item.col,
                colFlow: item.colFlow,
                storyRoles: item.storyRoles,
            };
            var srMap = {};

            // sort if necessary
            if (!isNullOrEmpty(asset[item.prop]) && map.sort) {
                asset[item.prop] = sortContent(asset[item.prop], map.sort);
                // if has minWeight property (from template json)
                // remove items from array that are less than minWeight value
                if (map.sort.minWeight) {
                    // initial length before removing items
                    var len = asset[item.prop].length;
                    asset[item.prop] = weighContent(asset[item.prop], map.sort);
                    // add a property indicating the weighContent function has removed items
                    map.sort.isAbbreviated = len > asset[item.prop].length;
                }
            }

            switch (item.type) {
                case 'arrayList':
                    map.value = mapArrayList(asset[item.prop], item);
                    break;
                case 'objectKeys':
                    map.value = mapObjectKeys(asset[item.prop], item);
                    break;
                case 'tabular':
                    map.value = angular.isArray(asset[item.prop]) ? asset[item.prop] : [];
                    map.properties = item.properties;
                    break;
                default:
                    map.value = angular.isArray(asset[item.prop]) ? asset[item.prop] : [];
                    break;
            }

            if (item.storyRoles) {
                srMap = {
                    label: item.label,
                    prop: item.prop,
                    isSelected: !!item.storyRoles.isSelected,
                    isExpanded: !!item.storyRoles.isExpanded,
                    type: item.storyRoles.type,
                    display: item.storyRoles.display,
                    sort: item.storyRoles.sort,
                    col: item.storyRoles.col,
                    colFlow: item.storyRoles.colFlow,
                    name: item.storyRoles.name,
                };

                // sort if necessary
                if (!isNullOrEmpty(asset[item.prop]) && item.storyRoles.sort) {
                    asset[item.prop].forEach(function (storyline) {
                        storyline.storyRoles = sortContent(storyline.storyRoles, srMap.sort);

                        // if has minWeight property (from template json)
                        // remove contentItems from array that are less than minWeight value
                        if (srMap.sort.minWeight) {
                            // initial length before removing contentItems
                            var len = storyline.storyRoles.length;
                            storyline.storyRoles = weighContent(storyline.storyRoles, srMap.sort);
                            // add a property indicating the weighContent function has removed contentItems
                            srMap.sort.isAbbreviated = len > storyline.storyRoles.length;
                        }
                    });
                }

                if (item.storyRoles.type === 'tabular') {
                    srMap.properties = item.storyRoles.properties;
                }

                item.storyRoles = srMap;
            }

            return map;
        }

        /**
         *
         * @param assetProp
         * @param prop
         * @returns {*}
         */
        function mapArrayList(assetProp, prop) {
            if (isNullOrEmpty(assetProp)) return [];
            return assetProp.map(function (item) {
                return {
                    label: item[prop.properties.label],
                    value: item[prop.properties.value],
                };
            });
        }

        /**
         *
         * @param assetProp
         * @param prop
         * @returns {Array}
         */
        function mapObjectKeys(assetProp, prop) {
            var arr = [];
            if (isNullOrEmpty(assetProp)) return arr;
            angular.forEach(assetProp, function (value, key) {
                arr.push({
                    label: strReplace(key, prop.replace),
                    value: mapArrayList(value, prop),
                });
            });
            return arr;
        }

        /**
         *
         * @param str {String} - some string
         * @param replace {Object} - values to find & replace in the str
         * @returns {String} - a modified string
         */
        function strReplace(str, replace) {
            var modifiedStr = str;
            angular.forEach(replace, function (value, key) {
                var regex = new RegExp(key, 'g');
                modifiedStr = modifiedStr.replace(regex, value);
            });
            return modifiedStr;
        }

        /**
         *
         * @param prop {Any} - determines if some property contains any data
         * @returns {boolean}
         */
        function isNullOrEmpty(prop) {
            return !prop || angular.equals(prop, {}) || angular.equals(prop, []);
        }

        /**
         *
         * @param arr {Array} - array to sort
         * @param sortData {Object} - contains info on how to sort (from template json)
         * @returns {Array} - a sorted array
         */
        function sortContent(arr, sortData) {
            if (sortData.prop) {
                arr = arr.sort(function (a, b) {
                    // sort according to sortData prop
                    if (sortData.prop) {
                        a = a[sortData.prop];
                        b = b[sortData.prop];
                    }
                    // be sure to turn strings to numbers if necessary
                    if (sortData.type && sortData.type === 'number') {
                        a = parseInt(a);
                        b = parseInt(b);
                    }
                    if (a < b) return -1;
                    if (a > b) return 1;
                    return 0;
                });
            }
            //
            if (sortData.order && sortData.order === 'descending') {
                arr = arr.reverse();
            }
            return arr;
        }

        /**
         *
         * @param arr {Array} - array to remove items from
         * @param sortData {Object} - contains info on determining which items to remove (from template json)
         * @returns {Array} - array with items removed if their sort prop value is less than minWeight
         */
        function weighContent(arr, sortData) {
            // find the largest value in array according to property we are weighing
            var max = Math.max.apply(
                Math,
                arr.map(function (o) {
                    return parseInt(o[sortData.prop]);
                })
            );
            var i = arr.length;
            while (i > 0) {
                // remove items from array whose sortData.prop value
                // is a percentage of 'max' that is less than minWeight value
                if ((parseInt(arr[i - 1][sortData.prop]) / max) * 100 < sortData.minWeight) {
                    arr.splice([i - 1], 1);
                }
                i--;
            }
            return arr;
        }

        // NOTE:
        // just for dev so dialog opens automatically when page loads
        // handy for working on video player
        // $scope.showDialog();
    }
})();
