import {
    firePageView,
    getUserId,
    isSnowplowReady,
    setUserId,
    trackButtonClick,
    trackComponent,
    trackPageType,
    trackUnknownUser,
} from '../utils/snowplow-utils';

angular.module('archie.core').factory('snowplowUtilsService', SnowplowUtilsService);

function SnowplowUtilsService() {
    return {
        isSnowplowReady: isSnowplowReady,
        setUserId: setUserId,
        firePageView: firePageView,
        trackComponent: trackComponent,
        trackButtonClick: trackButtonClick,
        trackPageType: trackPageType,
        getUserId: getUserId,
        trackUnknownUser: trackUnknownUser,
    };
}
