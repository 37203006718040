(function () {
    "use strict";

    // Asset Service
    angular.module('archie.core').service('TemplateService', TemplateService);

    /**
     * TemplateService - Template Service
     *
     */
    /* @ngInject */
    function TemplateService(TemplateMocks) {

        var templates = [];

        return {
            setTemplates: setTemplates,
            getTemplateByType: getTemplateByType
        };

        function setTemplates(value) {
            // NOTE: don't delete may use in future
            // templates = value;
            templates = TemplateMocks;
        }

        function getTemplateByType(assetType){
            var foundTemplate;
            angular.forEach(templates, function (template) {
               if(template.type === assetType) {
                   foundTemplate = template;
               }
            });
            return foundTemplate;
        }


    }
})();