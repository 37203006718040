(function () {
    "use strict";

    angular.module('archie.core')
        .directive('pagination', function(){
            return {
                'restrict': 'E',
                'replace': false,
                'templateUrl': 'library/pagination/pagination.html',
                'scope': {
                    'pages': '=',
                    'label': '=',
                    'selectedPage': '='
                },
                'controller': ['$scope', function ($scope) {
                    $scope.selectPage = function (page) {
                        $scope.selectedPage = page;
                    };
                }]
            };
        });
})();
