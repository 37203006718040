(function (){
    "use strict";

    // Asset Service
    angular.module('archie.core').factory('FacetService', FacetService);

    /**
     * FacetService - Facet Service
     *
     * @param  {Logger} $log Logger
     * @param {UserService} UserService User Service
     */
    /* @ngInject */
    function FacetService(HttpWrapperService, $location, SearchUtil, FacetMocks){
        var facets = [];
        var staticFacets = [];
        var optionParams = {};
        var searchText = '';
        var templates = FacetMocks; // array of facet templates
        var facetWhitelist = []; // array of whitelists for each selected facet option

        presetStaticFacets();

        return {
            getOptionParams: getOptionParams,
            getAvailableFacets: getAvailableFacets,
            setExpandedFacet: setExpandedFacet,
            getFacetCount: getFacetCount,
            setSelectedOptionsByParams: setSelectedOptionsByParams,
            getSelectedOptionApiNameByFacetName: getSelectedOptionApiNameByFacetName,
            getFacetsFromBackend: getFacetsFromBackend,
            resetOption: resetOption,
            getStaticFacets: getStaticFacets,
            getFacetByFacetName: getFacetByFacetName,
            removeOption: removeOptionFromSelectedInFacet,
            areOptionsSelected: areOptionsSelected,
            getFacetsFromSearchText: getFacetsFromSearchText,
            setFacets: setFacets,
            getSearchText: getSearchText,
            setSearchText: setSearchText,
            clearSearchText: clearSearchText,
            getFacetById: getFacetById,
            updateWhitelist: updateWhitelist
        };

        /**
         *  Merge whitelist for a given option with global whitelist for all selected options
         *  Whitelist is only populated when at least one selected option has restrictions
         * @param facet
         * @param option
         */
        function updateWhitelist(facet, option) {
            angular.forEach(templates, function (template) {
                if(template.type === facet) {
                    var filteredWhitelist = [];
                    var optionsTemplate = template.options[option];
                    var allTemplate = template.options['all'];

                    // determine whether we are looking for a specific option or all options
                    if(optionsTemplate) {
                        filteredWhitelist = optionsTemplate.filter(function(item) {
                            return facetWhitelist.indexOf(item) < 0;
                        });
                    } else if(allTemplate) {
                        filteredWhitelist = allTemplate.filter(function(item) {
                            return facetWhitelist.indexOf(item) < 0;
                        });
                    }
                    facetWhitelist = facetWhitelist.concat(filteredWhitelist);
                }
            });
        }

        function areOptionsSelected(){
            return !!(facets.filter(function(facet){
                return facet.selectedOptions && facet.selectedOptions.length;
            })[0]);
        }

        function getStaticFacets(){
            return staticFacets;
        }

        function updateFacetsSelectedOptions(){
            angular.forEach(facets, function (facet){
                updateSelectedOptionsForSpecificFacet(facet.name);
            });
        }

        function updateSelectedOptionsForSpecificFacet(facetName){
            var facet = getFacetByFacetName(facetName);
            facet.selectedOptions = [];

            angular.forEach(facet.availableOptions, function (option){
                if (option.isSelected) {
                    facet.selectedOptions.push(option);
                }
            });
        }

        function getSelectedOptionApiNameByFacetName(facetName){
            var selectedOptions = [];
            var facet = getFacetByFacetName(facetName);
            angular.forEach(facet.availableOptions, function (option, key){
                if (option.isSelected) {
                    selectedOptions.push(option.apiName);
                }
            });
            return selectedOptions;
        }

        /**
         * Mark property as selected for a given option in a given facet
         * @param facet
         * @param option
         */
        function setOption(facet, option){
            angular.forEach(facet.availableOptions, function (updateOption){
                if (option.apiName == updateOption.apiName) {
                    updateOption.isSelected = true;
                }
            });
            updateWhitelist(facet.id, option.optionName);
        }

        function resetOption(facetName, optionName){
            var facet = getFacetByFacetName(facetName);
            angular.forEach(facet.availableOptions, function (updateOption){
                if (updateOption.optionName == optionName) {
                    updateOption.isSelected = true;
                } else {
                    updateOption.isSelected = false;
                }
            });
            updateSelectedOptionsForSpecificFacet(facetName);
        }

        function removeOptionFromSelectedInFacet(facet, option){
            option.isSelected = false;
            updateSelectedOptionsForSpecificFacet(facet.name);
        }

        function setSelectedOptionsByParams(){
            var params = $location.search();
            clearSelectedFacetOptions();
            angular.forEach(params, function (apiNames, facetUrlParam){
                var facet = getFacetByUrlParam(facetUrlParam);
                if (facet) { // param is a valid facet
                    // deselect all options
                    angular.forEach(facet.availableOptions, function (option){
                        option.isSelected = false;
                    });

                    // mark each selected option as selected
                    angular.forEach([].concat(apiNames), function (apiName){
                        var optionToSet = getOptionByFacetAndApiName(facet, apiName);
                        if (optionToSet) {
                            setOption(facet, optionToSet);
                        } else {
                            facet.availableOptions.push({
                                'apiName': apiName,
                                'optionName': apiName,
                                'isSelected': false
                            });
                            setOption(facet, {
                                'apiName': apiName
                            });
                        }
                    });
                }
            });
        }

        function clearSelectedFacetOptions(){
            angular.forEach(facets, function(facet){
                angular.forEach(facet.availableOptions, function (option){
                    option.isSelected = false;
                });
            });
            facetWhitelist = [];
        }

        function getFacetByUrlParam(urlParam){
            var foundFacet;
            angular.forEach(facets.concat(staticFacets), function (facet, key){
                if (facet.urlParam == urlParam) {
                    foundFacet = facet;
                }
            });
            return foundFacet;
        }

        function getFacetByFacetName(facetName){
            var foundFacet;
            angular.forEach(facets.concat(staticFacets), function (facet, key){
                if (facet.name == facetName) {
                    foundFacet = facet;
                }
            });
            return foundFacet;
        }

        /**
         * getOptionParams - Get optional parameters from facet selections
         *
         * @return {Array}  option params
         */
        function getOptionParams(){
            return optionParams;
        }

        /**
         * getFacetCount - Get number of facets
         *
         * @return {number}  # of facets
         */
        function getFacetCount(){
            return facets.length;
        }

        /**
         * getAvailableFacets - Get list of available facets
         *
         * @return {Array}  list of available facets
         */
        function getAvailableFacets(){
            return facets;
        }

        /**
         * setExpandedFacet - description
         *
         * @param  {number} index      description
         * @param  {boolean} isExpanded description
         */
        function setExpandedFacet(index, isExpanded){
            facets[index].isExpanded = isExpanded;
        }

        /**
         * getOptionByFacetAndApiName - description
         *
         * @param  {Array} facet      Facet
         * @param  {string} apiName API name to search for
         * @return {Array}            Option
         */
        function getOptionByFacetAndApiName(facet, apiName){
            var selectedOption;
            angular.forEach(facet.availableOptions, function (value, key){
                if (value.apiName == apiName) {
                    selectedOption = value;
                }
            });
            return selectedOption;
        }

        function getSearchText() {
            return searchText;
        }

        function setSearchText(value) {
            searchText = value;
        }

        function clearSearchText() {
            searchText = '';
        }

        function presetStaticFacets(){

            var sortOptions = [{
                "optionName": "Relevance",
                "apiName": "relevance",
                "isSelected": true
            }, {
                "optionName": "Air Date (Newest to Oldest)",
                "apiName": "airDate",
                "isSelected": false
            }, {
                "optionName": "Air Date (Oldest to Newest)",
                "apiName": "oldestAirDate",
                "isSelected": false
            }];

            staticFacets.push({
                "name": "Sort",
                "urlParam": "sort",
                "checkbox": false,
                "availableOptions": sortOptions
            });

        }

        function addOptions(facetFrom, facetTo){
            facetFrom.selectedOptions = facetFrom.selectedOptions || [];
            facetFrom.options = facetFrom.options || [];
            var selectedOptions = facetFrom.selectedOptions.length;
            var params = angular.copy($location.search());
            delete params['offset'];
            delete params['openAsset'];
            facetTo.availableOptions = facetFrom.options.map(function (option){
                var optionParam = params[facetFrom.id] || [];
                var selectedOptionsIndex = facetFrom.selectedOptions.indexOf(option.optionName);
                optionParam = angular.isArray(optionParam) ? optionParam : [optionParam];
                option.isSelected = selectedOptionsIndex !== -1 || optionParam.indexOf(option.optionName) !== -1;
                option.apiName = option.optionName;
                if(option.isSelected){
                    selectedOptions++;
                    if(selectedOptionsIndex !== -1) facetFrom.selectedOptions.splice(selectedOptionsIndex, 1);
                }

                return option;
            });

            // Handle options that are selected by the user but do not correlate with the search results.
            // This happens after the user selects a facet option then enters non-relevant search text
            if(facetFrom.selectedOptions.length){
                facetFrom.selectedOptions.forEach(function(optionName){
                     facetTo.availableOptions.unshift({
                        'apiName': optionName,
                        'isSelected': true,
                        'optionName': optionName,
                        'optionSize': 0
                    });
                });
            }
            facetTo.selectedOptions = facetTo.availableOptions.filter(function (option){
                return option.isSelected === true;
            });
            return facetTo;
        }

        function getFacetsFromBackend(facetId, availableFacets){
            var currentLimit = SearchUtil.getLimit();
            SearchUtil.setLimit(0); //so no array of 'assets' returned unnecessarily
            availableFacets = availableFacets || getAvailableFacets();
            var staticFacets = getStaticFacets();
            var optionParams = getOptionParams();
            var params = SearchUtil.prepareParamsAndCollectionPath(availableFacets, staticFacets, optionParams, 'segment');
            var url = '/search?' + params;
            SearchUtil.setLimit(currentLimit); // reset limit to previous value

            if(facetId) url += '&facetId=' + encodeURIComponent(facetId);

            return HttpWrapperService.get(url).then(function(response) {
                // if facetId param supplied, return only data for that facet,
                // if no facetId param, return all facet data from response
                return (facetId) ? getFacetById(facetId, response.data.facets) : setFacets(response.data.facets);
            }).catch(function () {
                return [];
            });

        }

        function getFacetById(facetId, facets) {
            var updatedFacet = null;
            angular.forEach(facets, function(facet){
                if(facet.id === facetId){
                    updatedFacet = facet;
                }
            });
            if(updatedFacet){
                var facetBlank = {
                    "assetsQuantity": updatedFacet.assetsQuantity,
                    "name": updatedFacet.name,
                    "urlParam": updatedFacet.id,
                    "checkbox": true,
                    "isEnabled": true,
                    "isExpanded": (updatedFacet.selectedOptions ? updatedFacet.selectedOptions.length > 0 : false),
                    "availableOptions": [],
                    "nameSpace": updatedFacet.id,
                    "id": updatedFacet.id,
                    "sort": updatedFacet.sort || []
                };
                updatedFacet = addOptions(updatedFacet, facetBlank);
            }
            return updatedFacet;
        }

        function getFacetsFromSearchText(availableFacets, searchText) {
            var params = SearchUtil.prepareFacetParams(availableFacets, 'segment', searchText);
            var url = '/facets?';

            url += params;

            return HttpWrapperService.get(url).then(function (response) {
                    return response.data.facets;
                }).catch(function (errorResponse){
                    return [];
                });
        }

        function setFacets(facetsResponse){
            facets = [];
            if (facetsResponse.length != 0) {
                facets = [];
                angular.forEach(facetsResponse, function (facet){
                    // if whitelist is empty, then all facets are valid
                    if(facetWhitelist.length == 0 || facetWhitelist.indexOf(facet.id) >= 0) {
                        var facetBlank = {
                            "assetsQuantity": facet.assetsQuantity,
                            "name": facet.name,
                            "urlParam": facet.id,
                            "checkbox": true,
                            "isEnabled": true,
                            "isExpanded": (facet.selectedOptions ? facet.selectedOptions.length > 0 : false),
                            "availableOptions": [],
                            "nameSpace": facet.id,
                            "id": facet.id,
                            "sort": facet.sort || []
                        };
                        addOptions(facet, facetBlank);
                        facets.push(facetBlank);
                    }
                });
            }
            setSelectedOptionsByParams();
            return facets;
        }

    }

})();