import { REPORT_BUT_EXTERNAL_LINK } from './constants';

// Feedback Service
angular.module('archie.core').factory('FeedbackService', FeedbackService);

/**
 *
 * @returns {{reportBug: reportBug, sendFeedback: sendFeedback, mailTo: mailTo}}
 */
function FeedbackService(emails) {
    return {
        sendFeedback: sendFeedback,
        reportBug: reportBug,
        mailTo: mailTo,
    };

    function sendFeedback() {
        return mailTo(emails.feedback.address, emails.feedback.subject, emails.feedback.body);
    }

    function reportBug() {
        return REPORT_BUT_EXTERNAL_LINK;
    }

    function mailTo(email, subject, message) {
        email = email || '';
        subject = subject || '';
        message = message || '';
        return 'mailto:' + email + '?subject=' + subject + '&body=' + message;
    }
}
