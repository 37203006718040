// Buttons are so common in video.js that they have a special base class that makes implementing them easier.
const ButtonComponent = videojs.getComponent('Button');

/**
 * @typedef {Object} MetadataViewButtonComponentOptions
 */
/**
 * This button will trigger message that can be used alert the ui to show hide metadata view bits.
 */
class MetadataViewButtonComponent extends ButtonComponent {
    /**
     * Creates the button and sets the button's tooltip to 'Restart segment'.
     * @param {Player} player - The video.js Player instance this component is bound to.
     * @param {RestartLoopButtonComponentOptions} options - The options this component will be created with.
     */
    constructor(player, options) {
        super(player, options);
        this.setControlText();
    }
    /**
     * Sets the CSS classes that this button will have.
     * @returns {string}
     */
    buildCSSClass() {
        let css = 'metadata-view-button ';
        css += this.options_.opened ? 'opened ' : '';
        return css + ButtonComponent.prototype.buildCSSClass.apply(this, arguments);
    }
    /**
     * The 'onclick' event handler for this plugin, which sets the video player's current time to the given "start"
     * time.
     */
    handleClick() {
        this.setOpened(!this.options_.opened);
    }

    setOpened(opened) {
        this.options_.opened = opened;
        this.player_.trigger('toggleMetadataView', { opened: this.options_.opened });
        if (opened) {
            this.el_.classList.add('opened');
            if (this.player_.isFullscreen_) {
                this.player_.exitFullscreen();
            }
        } else {
            this.el_.classList.remove('opened');
        }
        this.setControlText();
    }

    setControlText() {
        if (this.el_.disabled) {
            this.controlText(this.options_.showMsg);
            // disabled message for tooltip
            this.el_.setAttribute('title', this.options_.disableMsg);
        } else if (this.options_.opened) {
            this.controlText(this.options_.hideMsg);
        } else {
            this.controlText(this.options_.showMsg);
        }
    }

    disable() {
        this.el_.setAttribute('disabled', '');
        this.setControlText();
    }
}

// Tells videojs about our custom "restart loop button" component.
videojs.registerComponent('metadataViewButton', MetadataViewButtonComponent);
