// Buttons are so common in video.js that they have a special base class that makes implementing them easier.
const ButtonComponent = videojs.getComponent('Button');

// Button for toggling "loop segment" options, clicking toggles css class and bool property
class LoopToggleComponent extends ButtonComponent {
    constructor(player, options) {
        super(player, options);
        this.controlText('Loop Segment');
    }

    checked() {
        return this.el_.classList.contains('checked');
    }

    buildCSSClass() {
        return (
            'loop-segment-btn checked ' +
            ButtonComponent.prototype.buildCSSClass.apply(this, arguments)
        );
    }

    handleClick() {
        this.el_.classList.toggle('checked');
    }
}

// Tells videojs about our custom "loop toggle" component.
videojs.registerComponent('LoopToggleButton', LoopToggleComponent);
