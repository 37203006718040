(function () {
    "use strict";

    /**
     * Some of the description text for series & episodes contains partial
     * html character codes and triple and double quotes instead of just "
     * regex below will find & replace for better readability
     */
    angular.module('archie.core')
        .filter('quoteFormat', function() {
            return function(str) {
                if(str) {
                    return str.replace(/"""|""|#34;|quot;/g, '"');
                }
                return '';
            };
        });
})();