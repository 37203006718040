(function(){
    "use strict";
    var PREFERENCE_KEY = 'releaseDialogVersion';

    angular.module('archie.core')
        .service('ReleaseDialog', [
            'PreferenceUtilService',
            '$mdDialog',
            'appContainerId',
            function(PreferenceUtilService, $mdDialog, appContainerId){
                function showDialog(){
                    $mdDialog.show({
                        'parent': angular.element('#'+appContainerId),
                        'templateUrl': 'release-dialog/release-dialog.html',
                        'clickOutsideToClose': true,
                        'controller': ['$scope', function($scope){
                            $scope.releaseNotes = appConfig.releaseNotes;
                            $scope.dismiss = function(){
                                PreferenceUtilService.set(PREFERENCE_KEY, appConfig.releaseVersion);
                                $mdDialog.hide();
                            }
                        }]
                    });
                }
                
                return {
                    'showDialog': showDialog
                };
            }
        ]);
})();