(function (){
    "use strict";

    // Card Set Directive
    angular.module('archie.core').directive('archieCardSet', CardSetDirective);
    function CardSetDirective(){
        return {
            'restrict': 'E',
            'replace': 'true',
            'templateUrl': 'layout/card-set/card-set.html',
            'controller': 'CardSetController',
            'scope': {
                'assets': '=',
                'availableFacets': '=?',
                'cardLayout': '=?',
                'hideLayout': '=?'
            }
        };
    }

})();